import React from 'react';
import './popup.scss';
import errPopup from '../../assets/images/errorpopup.svg';
import { useSelector } from 'react-redux';

export const Popup = ({ status, className }) => {
    const total = useSelector((state) => state.popup.totalPrem);
    return (
        <div
            className={`popup ${
                status === 'success' ||
                status === 'send' ||
                status === 'complete' ||
                status === 'canceled' ||
                status === 'submit'
                    ? 'success'
                    : 'error'
            } ${className}`}
        >
            {status === 'success' && (
                <div className="popup__wrap">
                    <div className="popup__mark-success">✔</div>
                    <div className="popup__text">
                        Quote has been successfully saved
                    </div>
                </div>
            )}
            {status === 'complete' && (
                <div className="popup__wrap">
                    <div className="popup__mark-success">✔</div>
                    <div className="popup__text">
                        Quote has been successfully completed
                    </div>
                </div>
            )}
            {status === 'submit' && (
                <div className="popup__wrap">
                    <div className="popup__mark-success">✔</div>
                    <div className="popup__text">
                        The quote has been submitted successfully
                    </div>
                </div>
            )}
            {status === 'canceled' && (
                <div className="popup__wrap">
                    <div className="popup__mark-success">✔</div>
                    <div className="popup__text">
                        Quote has been successfully canceled
                    </div>
                </div>
            )}
            {status === 'error' && (
                <div className="popup__wrap">
                    <div className="popup__mark-error">
                        <img src={errPopup} />
                    </div>
                    <div className="popup__text">Quote is not saved!</div>
                </div>
            )}
            {status === 'err' && (
                <div className="popup__wrap">
                    <div className="popup__mark-error">
                        <img src={errPopup} />
                    </div>
                    <div className="popup__text">
                        Something went wrong. Please contact your administrator!
                    </div>
                </div>
            )}
            {status === 'totalError' && (
                <div className="popup__wrap">
                    <div className="popup__mark-error">
                        <img src={errPopup} />
                    </div>
                    <div className="popup__text">
                        Saved total premium will be $
                        {Math.round(total)
                            ?.toString()
                            ?.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                        . Please contact your administrator
                    </div>
                </div>
            )}
            {status === 'send' && (
                <div className="popup__wrap">
                    <div className="popup__mark-success">✔</div>
                    <div className="popup__text">
                        The request has been submitted successfully
                    </div>
                </div>
            )}
        </div>
    );
};
