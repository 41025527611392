import React from 'react';
import { Header } from '../../components/Header/Header';
import { Package } from '../../components/Package/Package';
import rentalImage from '../../assets/images/couple looking at house.png';
import studentsImage from '../../assets/images/students moving in.png';
import './homepage.scss';
import { Loader } from '../../components/Loader/Loader';

const REACT_APP_ENV = process.env.REACT_APP_ENV;
export const HomePage = ({ loading }) => {
    // Determine the title based on the environment variable
    let title = 'Halwell Connect';
    if (REACT_APP_ENV === 'test' || REACT_APP_ENV === 'qa') {
        title = 'Halwell Connect - ' + REACT_APP_ENV;
    }
    console.log('REACT_APP_ENV: ', REACT_APP_ENV);
    return (
        <>
            <div className="homepage">
                <Header title={title} />
                <Loader loading={loading} />
                <div className="homepage__main">
                    <div className="homepage__title">Our products</div>
                    <div className="homepage__packages">
                        <Package
                            title={'Residential Rental'}
                            description={'A comprehensive package for rented dwellings and condos. Quoting tool supports single occupancy, single location.'}
                            src={rentalImage}
                        />
                        <Package 
                            title={'Student Rental'} 
                            description={'Ideal package for students moving into rental properties. 1-10 students, dwellings and condos. Quoting tool supports single occupancy, single location'}
                            src={studentsImage} />
                    </div>
                </div>
            </div>
        </>
    );
};
