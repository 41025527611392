import servise from './service';
import axios from 'axios';
const BASE_URL = process.env.REACT_APP_BASE_URL;

export const login = (data) => {
    return axios.post(`${BASE_URL}auth/login/`, data);
};

export const checkToken = () => {
    return servise.get('auth/token/check/')
        .catch(error => {
            if (error.response && error.response.status === 401) {
                // Delete the token if a 401 Unauthorized error is returned
                document.cookie = 'auth_token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; domain=.halwellmutual.com;';
                // Optionally, redirect to the login page
                window.location.replace('/login');
            }
            // Re-throw the error to be handled elsewhere if necessary
            throw error;
        });
};


export const logout = () => {
    return servise.delete('auth/logout/');
};

export const getUserData = () => {
    return servise.get('auth/user_data/');
};

export function getCookie(name) {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
    return null;
}