import React from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setQuotePackage } from '../../redux/quote/quote';
import './Package.scss';

export const Package = ({ title, src, description }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    return (
        <div className="package-wrap">
            <div className="package-wrap__title">{title}</div>
            <img className="package-wrap__image" src={src} />
            <div className="package-wrap__description">{description}</div>
            <button
                className="package-wrap__btn"
                onClick={() => {
                    if (title === 'Residential Rental') {
                        dispatch(
                            setQuotePackage({ title: 'Residential Rental' })
                        );
                        navigate('/new_quote/?step=1');
                    }
                    if (title === 'Student Rental') {
                        dispatch(setQuotePackage({ title: 'Student Rental' }));
                        navigate('/new_quote/?step=1');
                    }
                }}
            >
                Create Quote
            </button>
        </div>
    );
};
