import React, { useEffect, useState } from 'react';
import './ContactUsModal.scss';
import Image from '../../assets/images/subject.png';
import moment from 'moment';
import { getTicketData } from '../../crud/quote';

export const ContactUsModal = ({
    setOpenHelpModal,
    submitHelp,
    setDescription,
    description,
    descriptionError,
    currentQuote,
    setLoading,
}) => {
    const [subject, setSubject] = useState();

    useEffect(() => {
        setLoading(true);
        getTicketData(currentQuote)
            .then((res) => {
                setSubject(res.data.subject);
            })
            .finally(() => setLoading(false));
    }, []);

    return (
        <>
            <div className="modal-wrapper"></div>
            <div className="help-modal">
                <div className="help-modal__wrap">
                    <div className="help-modal__title">
                        Have a question? Contact us!
                    </div>
                    <div
                        className="help-modal__close"
                        onClick={() => {
                            setOpenHelpModal(false);
                        }}
                    >
                        ✖
                    </div>
                </div>
                <div className="help-modal__subtitle">
                    An underwriter will respond as soon as
                    possible
                </div>
                <div className="help-modal__subject">
                    <img src={Image} style={{ margin: '0 12px 0 0 ' }} />
                    Subject: {subject}
                </div>
                <div className="help-modal__description description">
                    <div className="description__title">
                    Message to the underwriter
                    </div>
                    <textarea
                        className={`description__textarea ${
                            descriptionError ? 'red' : ''
                        }`}
                        onChange={(e) => setDescription(e.target.value)}
                        placeholder="Type your answer"
                        value={description}
                        maxLength={1000}
                    />
                    <div className="error">{descriptionError}</div>
                </div>
                <div className="help-modal__buttons buttons">
                    <div
                        className="buttons__cancel btn"
                        onClick={() => setOpenHelpModal(false)}
                    >
                        Cancel
                    </div>
                    <div className="buttons__submit btn" onClick={submitHelp}>
                        Submit
                    </div>
                </div>
            </div>
        </>
    );
};
