import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    currentQuote: '',
    viewQuoteId: '',
    quotePackage: '',
    isChanged: false,
};

export const quote = createSlice({
    name: 'first-step',
    initialState,
    reducers: {
        setCurrentQuote: (state, action) => {
            state.currentQuote = action.payload;
        },
        setViewQuoteId: (state, action) => {
            state.viewQuoteId = action.payload;
        },
        setQuotePackage: (state, action) => {
            state.quotePackage = action.payload;
        },
        setIsChanged: (state, action) => {
            state.isChanged = action.payload;
        },
    },
});

export const {
    setCurrentQuote,
    setViewQuoteId,
    setQuotePackage,
    setIsChanged,
} = quote.actions;
export default quote.reducer;
