import React from 'react';
import './modal.scss';

export const AreYouSureModal = ({
    cancel,
    submit,
    title,
    subtitle,
    buttonName = 'Yes',
}) => {
    return (
        <>
            <div className="modal-wrapper"></div>
            <div className="confirm-modal">
                <div className="confirm-modal__title-wrap">
                    <div className="confirm-modal__title">{title}</div>
                    <div className="confirm-modal__close" onClick={cancel}>
                        ✖
                    </div>
                </div>
                <div className="confirm-modal__subtitle">{subtitle}</div>

                <div className="confirm-modal__btns">
                    <button className="confirm-modal__cancel" onClick={cancel}>
                        No
                    </button>
                    <button className="confirm-modal__create" onClick={submit}>
                        {buttonName}
                    </button>
                </div>
            </div>
        </>
    );
};
