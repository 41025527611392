export const validateRental = (
    step,
    formData,
    setFormData,
    fillFormAction,
    additionalCoverages
) => {
    let isValid = true;
    if (step === 2) {
        const data = { ...formData };
        if (!formData.rental_type.value.title) {
            if (!formData.rental_type.value.title) {
                isValid = false;
                data.rental_type.hasError = true;
                data.rental_type.error = 'This field is required';
            }
            if (!formData.deductible.value.title) {
                isValid = false;
                data.deductible.hasError = true;
                data.deductible.error = 'This field is required';
            }
            if (!formData.landlord_contents.value) {
                isValid = false;
                data.landlord_contents.hasError = true;
                data.landlord_contents.error = 'This field is required';
            }
            if (!formData.gross_rental_income.value) {
                isValid = false;
                data.gross_rental_income.hasError = true;
                data.gross_rental_income.error = 'This field is required';
            }
            if (!formData.liability_limit.value.title) {
                isValid = false;
                data.liability_limit.hasError = true;
                data.liability_limit.error = 'This field is required';
            }
            if (
                !formData.flood.value.title &&
                additionalCoverages.includes('2')
            ) {
                isValid = false;
                data.flood.hasError = true;
                data.flood.error = 'This field is required';
            }
            if (
                formData.flood.value.title ||
                !additionalCoverages.includes('2')
            ) {
                data.flood.hasError = false;
                data.flood.error = '';
            }
        }

        if (formData.rental_type.value.title) {
            if (formData.rental_type.value.id === 1) {
                if (!formData.deductible.value.title) {
                    isValid = false;
                    data.deductible.hasError = true;
                    data.deductible.error = 'This field is required';
                }
                if (!formData.landlord_contents.value) {
                    isValid = false;
                    data.landlord_contents.hasError = true;
                    data.landlord_contents.error = 'This field is required';
                }
                if (!formData.gross_rental_income.value) {
                    isValid = false;
                    data.gross_rental_income.hasError = true;
                    data.gross_rental_income.error = 'This field is required';
                }
                if (!formData.liability_limit.value.title) {
                    isValid = false;
                    data.liability_limit.hasError = true;
                    data.liability_limit.error = 'This field is required';
                }
                if (!formData.improvements_betterments.value) {
                    isValid = false;
                    data.improvements_betterments.hasError = true;
                    data.improvements_betterments.error =
                        'This field is required';
                }
                if (!formData.loss_assessment.value) {
                    isValid = false;
                    data.loss_assessment.hasError = true;
                    data.loss_assessment.error = 'This field is required';
                }
                if (!formData.unit_owner_protection.value) {
                    isValid = false;
                    data.unit_owner_protection.hasError = true;
                    data.unit_owner_protection.error = 'This field is required';
                }
                if (
                    !formData.flood.value.title &&
                    additionalCoverages.includes('2')
                ) {
                    isValid = false;
                    data.flood.hasError = true;
                    data.flood.error = 'This field is required';
                }
                if (
                    formData.flood.value.title ||
                    !additionalCoverages.includes('2')
                ) {
                    data.flood.hasError = false;
                    data.flood.error = '';
                }
                if (!formData.additional_building.value) {
                    data.additional_building.hasError = false;
                    data.additional_building.error = '';
                }
                if (!formData.building.value) {
                    data.building.hasError = false;
                    data.building.error = '';
                }
            }

            if (formData.rental_type.value.id === 0) {
                if (!formData.deductible.value.title) {
                    isValid = false;
                    data.deductible.hasError = true;
                    data.deductible.error = 'This field is required';
                }
                if (!formData.landlord_contents.value) {
                    data.landlord_contents.hasError = false;
                    data.landlord_contents.error = '';
                }
                if (!formData.gross_rental_income.value) {
                    data.gross_rental_income.hasError = false;
                    data.gross_rental_income.error = '';
                }
                if (!formData.liability_limit.value.title) {
                    isValid = false;
                    data.liability_limit.hasError = true;
                    data.liability_limit.error = 'This field is required';
                }
                if (
                    !formData.flood.value.title &&
                    additionalCoverages.includes('2')
                ) {
                    isValid = false;
                    data.flood.hasError = true;
                    data.flood.error = 'This field is required';
                }
                if (
                    formData.flood.value.title ||
                    !additionalCoverages.includes('2')
                ) {
                    data.flood.hasError = false;
                    data.flood.error = '';
                }

                if (!formData.building.value) {
                    isValid = false;
                    data.building.hasError = true;
                    data.building.error = 'This field is required';
                }
                if (!formData.improvements_betterments.value) {
                    data.improvements_betterments.hasError = false;
                    data.improvements_betterments.error = '';
                }
                if (!formData.loss_assessment.value) {
                    data.loss_assessment.hasError = false;
                    data.loss_assessment.error = '';
                }
                if (!formData.unit_owner_protection.value) {
                    data.unit_owner_protection.hasError = false;
                    data.unit_owner_protection.error = '';
                }
            }
        }
        setFormData(fillFormAction(data));
    }
    return isValid;
};
