import { fillFormAction } from '../../helpers/formUtils';

export const clearDisabledFields = (formData, setFormData) => {
    const data = JSON.parse(JSON.stringify(formData));
    for (let prop in data) {
        if (data[prop].disabled) {
            data[prop].value = '';
        }
    }
    setFormData(fillFormAction(data));
};
