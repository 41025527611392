import React, { useEffect, useRef, useState } from 'react';
import arrow from '../../assets/images/arrow-black.png';
import ReactPaginate from 'react-paginate';
import { setCurrentQuote, setViewQuoteId } from '../../redux/quote/quote';
import moment from 'moment';
import './tables.scss';
import { useDispatch } from 'react-redux';
import { downloadPdf } from '../../crud/quote';
import noresultImage from '../../assets/images/noresult.png';
import { AreYouSureModal } from '../AreYouSureModal/AreYouSureModal';
import { cancelQuote } from '../../crud/quote';
import { setIsPopupCanceld } from '../../redux/popup/popup';

export const TableQutesInWork = ({
    list,
    countOfPages,
    setPage,
    page,
    setFormData,
    formData,
    setLoading,
    setSubmitModal,
    setChoosenId,
    choosenId,
    setReload,
    subject,
    openActions,
    setOpenActions,
}) => {
    const dispatch = useDispatch();
    const [confirmModal, setConfirmModal] = useState(false);

    const openAction = (id) => {
        if (openActions === id) {
            setOpenActions();
            setChoosenId('');
        }
        if (openActions !== id) {
            setOpenActions(id);
            setChoosenId(id);
        }
    };

    const handlePageClick = (event) => {
        setPage(event.selected + 1);
    };

    const downloadPdfFunc = (id) => {
        setLoading(true);
        downloadPdf(id)
            .then((response) => {
                response.blob().then((blob) => {
                    const fileURL = window.URL.createObjectURL(blob);
                    let alink = document.createElement('a');
                    alink.href = fileURL;
                    alink.download = `${subject}.pdf`;
                    alink.click();
                });
            })
            .finally(() => setLoading(false));
    };

    const cancelQuoteFunc = () => {
        setLoading(true);
        cancelQuote(choosenId)
            .then((res) => {
                dispatch(setIsPopupCanceld(true));
                setTimeout(() => {
                    dispatch(setIsPopupCanceld(false));
                }, 3000);
                setConfirmModal(false);
                setReload((prev) => !prev);
            })
            .finally(() => setLoading(false));
    };

    return (
        <>
            <div className="table">
                {confirmModal && (
                    <AreYouSureModal
                        cancel={() => setConfirmModal(false)}
                        title="Are you sure you want to cancel the quote?"
                        submit={cancelQuoteFunc}
                    />
                )}
                <div className="table__wrap">
                    <div className="table__head head">
                        <div className="head__wrap">
                            <div className="head__item">Quote ID</div>
                            <div
                                className="head__item-arrow"
                                onClick={() =>
                                    setFormData({
                                        ...formData,
                                        ordering:
                                            formData.ordering === 'created_at'
                                                ? '-created_at'
                                                : 'created_at',
                                    })
                                }
                            >
                                ›
                            </div>
                        </div>
                        <div className="head__wrap">
                            <div className="head__item">Client</div>
                            <div
                                className="head__item-arrow"
                                onClick={() =>
                                    setFormData({
                                        ...formData,
                                        ordering:
                                            formData.ordering ===
                                            'name_of_insured'
                                                ? '-name_of_insured'
                                                : 'name_of_insured',
                                    })
                                }
                            >
                                ›
                            </div>
                        </div>
                        <div className="head__wrap">
                            <div className="head__item">Package</div>
                            <div
                                className="head__item-arrow"
                                onClick={() =>
                                    setFormData({
                                        ...formData,
                                        ordering:
                                            formData.ordering === 'package'
                                                ? '-package'
                                                : 'package',
                                    })
                                }
                            >
                                ›
                            </div>
                        </div>
                        <div className="head__wrap"  style={{ minWidth: '173px' }}>
                            <div className="head__item">Updated</div>
                            <div
                                className="head__item-arrow"
                                onClick={() =>
                                    setFormData({
                                        ...formData,
                                        ordering:
                                            formData.ordering === 'updated_at'
                                                ? '-updated_at'
                                                : 'updated_at',
                                    })
                                }
                            >
                                ›
                            </div>
                        </div>
                        <div className="head__wrap">
                            <div className="head__item">Status</div>
                            <div
                                className="head__item-arrow"
                                onClick={() =>
                                    setFormData({
                                        ...formData,
                                        ordering:
                                            formData.ordering === 'status'
                                                ? '-status'
                                                : 'status',
                                    })
                                }
                            >
                                ›
                            </div>
                        </div>
                        <div className="head__wrap">
                            <div className="head__item">Premium</div>
                            <div
                                className="head__item-arrow"
                                onClick={() =>
                                    setFormData({
                                        ...formData,
                                        ordering:
                                            formData.ordering ===
                                            'total_premium'
                                                ? '-total_premium'
                                                : 'total_premium',
                                    })
                                }
                            >
                                ›
                            </div>
                        </div>
                        <div
                            className="head__wrap"
                            style={{ justifyContent: 'center' }}
                        >
                            <div className="head__item">Actions</div>
                        </div>
                    </div>
                    {list.length ? (
                        list?.map((elem) => {
                            return (
                                <div className="row" key={elem.id}>
                                    <div className="row__item">
                                        {elem.id}
                                    </div>
                                    <div className="row__item">
                                        {elem.name_of_insured}
                                    </div>
                                    <div className="row__item">
                                        {elem.package === 'Residential Rental'
                                            ? 'Residential Rental'
                                            : 'Student Rental'}
                                    </div>
                                    <div className="row__item"  style={{ minWidth: '173px' }}>
                                        {moment(elem.updated_at).format(
                                            'YYYY/MM/DD, hh:mm A'
                                        )}
                                    </div>
                                    <div className={`row__item `}>
                                        <div
                                            className={`${
                                                elem.status === 'In progress' ||
                                                elem.status === 'Canceled'
                                                    ? 'row__progress'
                                                    : elem.status ===
                                                      'Completed'
                                                    ? 'row__completed'
                                                    : ''
                                            }`}
                                        >
                                            {' '}
                                            {elem.status === 'In progress' &&
                                                'In Progress'}
                                            {elem.status === 'Completed' &&
                                                'Completed'}
                                            {elem.status === 'Canceled' &&
                                                'Canceled'}
                                        </div>
                                    </div>
                                    <div className="row__item">
                                        $
                                        {(+elem.total_premium)
                                            .toFixed()
                                            .toString()
                                            .replace(
                                                /\B(?=(\d{3})+(?!\d))/g,
                                                ','
                                            )}
                                    </div>

                                    {elem.status === 'Canceled' ? (
                                        <div
                                            className="row__item action"
                                            style={{ cursor: 'context-menu' }}
                                        ></div>
                                    ) : (
                                        <div className="row__item action">
                                            {elem.status === 'Completed' && (
                                                <button
                                                    className="submit_quote"
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        setSubmitModal(true);
                                                        setChoosenId(elem.id);
                                                    }}
                                                >
                                                    Submit
                                                </button>
                                            )}
                                            {elem.status === 'In progress' && (
                                                <button
                                                    className="edit_quote"
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        dispatch(setCurrentQuote(elem.id));
                                                        dispatch(setViewQuoteId(elem.id));
                                                    }}
                                                >
                                                    Edit
                                                </button>
                                            )}
                                            <button
                                                    className="more_actions"
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        openAction(elem.id);
                                                    }}
                                                >
                                                    ...
                                            </button>
                                        </div>
                                    )}


                                    {elem.id === openActions && (
                                        <div>
                                            {elem.status === 'In progress' && (
                                                <div className="row__item-popup">
                                                    <div
                                                        className="row__item-popupitem"
                                                        onClick={() => {
                                                            setConfirmModal(
                                                                true
                                                            );
                                                            setChoosenId(
                                                                elem.id
                                                            );
                                                        }}
                                                    >
                                                        Cancel
                                                    </div>
                                                    <div
                                                        className="row__item-popupitem"
                                                        onClick={() => {
                                                            dispatch(
                                                                setCurrentQuote(
                                                                    elem.id
                                                                )
                                                            );
                                                            dispatch(
                                                                setViewQuoteId(
                                                                    elem.id
                                                                )
                                                            );
                                                        }}
                                                    >
                                                        Edit Quote
                                                    </div>
                                                </div>
                                            )}
                                            {elem.status === 'Completed' && (
                                                <div
                                                    className="row__item-popup"
                                                    onClick={(e) =>
                                                        e.stopPropagation()
                                                    }
                                                >
                                                    <div
                                                        className="row__item-popupitem"
                                                        onClick={() => {
                                                            setConfirmModal(
                                                                true
                                                            );
                                                            setChoosenId(
                                                                elem.id
                                                            );
                                                        }}
                                                    >
                                                        Cancel
                                                    </div>
                                                    <div
                                                        className="row__item-popupitem"
                                                        onClick={() =>
                                                            downloadPdfFunc(
                                                                elem.id
                                                            )
                                                        }
                                                    >
                                                        Download PDF
                                                    </div>
                                                    <div
                                                        className="row__item-popupitem"
                                                        onClick={() => {
                                                            setSubmitModal(
                                                                true
                                                            );
                                                            setChoosenId(
                                                                elem.id
                                                            );
                                                        }}
                                                    >
                                                        Submit Quote
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    )}
                                </div>
                            );
                        })
                    ) : (
                        <div className="table__noresult noresult">
                            <img src={noresultImage} />
                            <div className="noresult__text">
                                No results found
                            </div>
                        </div>
                    )}
                </div>
                {countOfPages <= 1 ? (
                    ''
                ) : (
                    <div className="table__paginate">
                        <ReactPaginate
                            breakLabel="..."
                            nextLabel="Next"
                            onPageChange={handlePageClick}
                            pageRangeDisplayed={2}
                            pageCount={countOfPages}
                            previousLabel="Previous"
                            pageClassName="li"
                            containerClassName="paginate"
                            marginPagesDisplayed={1}
                            previousClassName="btn"
                            nextClassName="btn"
                            forcePage={page - 1}
                        />
                    </div>
                )}
            </div>
        </>
    );
};
