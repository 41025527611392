import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    isPopup: false,
    isPopupError: false,
    isPopupSend: false,
    isPopupTotal: false,
    isPopupCanceld: false,
    isPopupErr: false,
    isPopupSubmit: false,
    totalPrem: '',
    completeQuote: false,
};

export const quote = createSlice({
    name: 'popup',
    initialState,
    reducers: {
        setIsPopup: (state, action) => {
            state.isPopup = action.payload;
        },
        setIsPopupError: (state, action) => {
            state.isPopupError = action.payload;
        },
        setIsPopupErr: (state, action) => {
            state.isPopupErr = action.payload;
        },
        setIsPopupSend: (state, action) => {
            state.isPopupSend = action.payload;
        },
        setIsPopupSubmit: (state, action) => {
            state.isPopupSubmit = action.payload;
        },
        setIsPopupTotal: (state, action) => {
            state.isPopupTotal = action.payload;
        },
        setIsPopupCanceld: (state, action) => {
            state.isPopupCanceld = action.payload;
        },
        setIsPopupCompleteQuote: (state, action) => {
            state.completeQuote = action.payload;
        },
        setTotalPrem: (state, action) => {
            state.totalPrem = action.payload;
        },
    },
});

export const {
    setIsPopup,
    setIsPopupError,
    setIsPopupSend,
    setIsPopupTotal,
    setTotalPrem,
    setIsPopupCompleteQuote,
    setIsPopupCanceld,
    setIsPopupErr,
    setIsPopupSubmit,
} = quote.actions;
export default quote.reducer;
