export const validateStudent = (
    step,
    formData2Student,
    setFormData2Student,
    formData3,
    setFormData3,
    fillFormAction,
    additionalCoverages
) => {
    if (step === 2) {
        const data = { ...formData2Student };
        let isValid = true;
        if (
            !formData2Student.number_of_students.value.title ||
            !formData2Student.rental_type.value.title
        ) {
            if (!formData2Student.number_of_students.value.title) {
                isValid = false;
                data.number_of_students.hasError = true;
                data.number_of_students.error = 'This field is required';
            }
            if (!formData2Student.rental_type.value.title) {
                isValid = false;
                data.rental_type.hasError = true;
                data.rental_type.error = 'This field is required';
            }
            if (!formData2Student.deductible.value.title) {
                isValid = false;
                data.deductible.hasError = true;
                data.deductible.error = 'This field is required';
            }
            if (!formData2Student.landlord_contents.value) {
                isValid = false;
                data.landlord_contents.hasError = true;
                data.landlord_contents.error = 'This field is required';
            }
            if (!formData2Student.gross_rental_income.value) {
                isValid = false;
                data.gross_rental_income.hasError = true;
                data.gross_rental_income.error = 'This field is required';
            }
            if (!formData2Student.liability_limit.value.title) {
                isValid = false;
                data.liability_limit.hasError = true;
                data.liability_limit.error = 'This field is required';
            }
            if (
                !formData2Student.flood.value.title &&
                additionalCoverages.includes('3')
            ) {
                isValid = false;
                data.flood.hasError = true;
                data.flood.error = 'This field is required';
            }
            if (
                formData2Student.flood.value.title ||
                !additionalCoverages.includes('3')
            ) {
                data.flood.hasError = false;
                data.flood.error = '';
            }
        }

        if (
            formData2Student.rental_type.value.title &&
            formData2Student.number_of_students.value.title
        ) {
            if (
                formData2Student.number_of_students.value.id === 0 &&
                formData2Student.rental_type.value.id === 1
            ) {
                if (!formData2Student.deductible.value.title) {
                    isValid = false;
                    data.deductible.hasError = true;
                    data.deductible.error = 'This field is required';
                }
                if (!formData2Student.landlord_contents.value) {
                    isValid = false;
                    data.landlord_contents.hasError = true;
                    data.landlord_contents.error = 'This field is required';
                }
                if (!formData2Student.gross_rental_income.value) {
                    isValid = false;
                    data.gross_rental_income.hasError = true;
                    data.gross_rental_income.error = 'This field is required';
                }
                if (!formData2Student.liability_limit.value.title) {
                    isValid = false;
                    data.liability_limit.hasError = true;
                    data.liability_limit.error = 'This field is required';
                }
                if (!formData2Student.improvements_betterments.value) {
                    isValid = false;
                    data.improvements_betterments.hasError = true;
                    data.improvements_betterments.error =
                        'This field is required';
                }
                if (!formData2Student.loss_assessment.value) {
                    isValid = false;
                    data.loss_assessment.hasError = true;
                    data.loss_assessment.error = 'This field is required';
                }
                if (!formData2Student.unit_owner_protection.value) {
                    isValid = false;
                    data.unit_owner_protection.hasError = true;
                    data.unit_owner_protection.error = 'This field is required';
                }
                if (
                    !formData2Student.flood.value.title &&
                    additionalCoverages.includes('3')
                ) {
                    isValid = false;
                    data.flood.hasError = true;
                    data.flood.error = 'This field is required';
                }
                if (
                    formData2Student.flood.value.title ||
                    !additionalCoverages.includes('3')
                ) {
                    data.flood.hasError = false;
                    data.flood.error = '';
                }
                if (!formData2Student.additional_building.value) {
                    data.additional_building.hasError = false;
                    data.additional_building.error = '';
                }
                if (!formData2Student.dwelling.value) {
                    data.dwelling.hasError = false;
                    data.dwelling.error = '';
                }
                if (!formData2Student.sewer_back_up.value.title) {
                    data.sewer_back_up.hasError = false;
                    data.sewer_back_up.error = '';
                }
            }
            if (
                formData2Student.number_of_students.value.id === 1 &&
                formData2Student.rental_type.value.id === 1
            ) {
                if (!formData2Student.sewer_back_up.value.title) {
                    isValid = false;
                    data.sewer_back_up.hasError = true;
                    data.sewer_back_up.error = 'This field is required';
                }
                if (!formData2Student.deductible.value.title) {
                    isValid = false;
                    data.deductible.hasError = true;
                    data.deductible.error = 'This field is required';
                }
                if (!formData2Student.landlord_contents.value) {
                    isValid = false;
                    data.landlord_contents.hasError = true;
                    data.landlord_contents.error = 'This field is required';
                }
                if (!formData2Student.gross_rental_income.value) {
                    isValid = false;
                    data.gross_rental_income.hasError = true;
                    data.gross_rental_income.error = 'This field is required';
                }
                if (!formData2Student.liability_limit.value.title) {
                    isValid = false;
                    data.liability_limit.hasError = true;
                    data.liability_limit.error = 'This field is required';
                }
                if (!formData2Student.improvements_betterments.value) {
                    isValid = false;
                    data.improvements_betterments.hasError = true;
                    data.improvements_betterments.error =
                        'This field is required';
                }
                if (!formData2Student.loss_assessment.value) {
                    isValid = false;
                    data.loss_assessment.hasError = true;
                    data.loss_assessment.error = 'This field is required';
                }
                if (!formData2Student.unit_owner_protection.value) {
                    isValid = false;
                    data.unit_owner_protection.hasError = true;
                    data.unit_owner_protection.error = 'This field is required';
                }
                if (
                    !formData2Student.flood.value.title &&
                    additionalCoverages.includes('3')
                ) {
                    isValid = false;
                    data.flood.hasError = true;
                    data.flood.error = 'This field is required';
                }
                if (
                    formData2Student.flood.value.title ||
                    !additionalCoverages.includes('3')
                ) {
                    data.flood.hasError = false;
                    data.flood.error = '';
                }
                if (!formData2Student.additional_building.value) {
                    data.additional_building.hasError = false;
                    data.additional_building.error = '';
                }
                if (!formData2Student.dwelling.value) {
                    data.dwelling.hasError = false;
                    data.dwelling.error = '';
                }
            }
            if (
                formData2Student.number_of_students.value.id === 0 &&
                formData2Student.rental_type.value.id === 0
            ) {
                if (!formData2Student.deductible.value.title) {
                    isValid = false;
                    data.deductible.hasError = true;
                    data.deductible.error = 'This field is required';
                }
                if (!formData2Student.landlord_contents.value) {
                    isValid = false;
                    data.landlord_contents.hasError = true;
                    data.landlord_contents.error = 'This field is required';
                }
                if (!formData2Student.gross_rental_income.value) {
                    isValid = false;
                    data.gross_rental_income.hasError = true;
                    data.gross_rental_income.error = 'This field is required';
                }
                if (!formData2Student.liability_limit.value.title) {
                    isValid = false;
                    data.liability_limit.hasError = true;
                    data.liability_limit.error = 'This field is required';
                }
                if (
                    !formData2Student.flood.value.title &&
                    additionalCoverages.includes('3')
                ) {
                    isValid = false;
                    data.flood.hasError = true;
                    data.flood.error = 'This field is required';
                }
                if (
                    formData2Student.flood.value.title ||
                    !additionalCoverages.includes('3')
                ) {
                    data.flood.hasError = false;
                    data.flood.error = '';
                }

                if (!formData2Student.dwelling.value) {
                    isValid = false;
                    data.dwelling.hasError = true;
                    data.dwelling.error = 'This field is required';
                }
                if (!formData2Student.additional_building.value) {
                    data.additional_building.hasError = false;
                    data.additional_building.error = '';
                }
                if (!formData2Student.improvements_betterments.value) {
                    data.improvements_betterments.hasError = false;
                    data.improvements_betterments.error = '';
                }
                if (!formData2Student.loss_assessment.value) {
                    data.loss_assessment.hasError = false;
                    data.loss_assessment.error = '';
                }
                if (!formData2Student.unit_owner_protection.value) {
                    data.unit_owner_protection.hasError = false;
                    data.unit_owner_protection.error = '';
                }
            }
            if (
                formData2Student.number_of_students.value.id === 1 &&
                formData2Student.rental_type.value.id === 0
            ) {
                if (!formData2Student.deductible.value.title) {
                    isValid = false;
                    data.deductible.hasError = true;
                    data.deductible.error = 'This field is required';
                }
                if (!formData2Student.sewer_back_up.value.title) {
                    isValid = false;
                    data.sewer_back_up.hasError = true;
                    data.sewer_back_up.error = 'This field is required';
                }
                if (!formData2Student.landlord_contents.value) {
                    isValid = false;
                    data.landlord_contents.hasError = true;
                    data.landlord_contents.error = 'This field is required';
                }
                if (!formData2Student.gross_rental_income.value) {
                    isValid = false;
                    data.gross_rental_income.hasError = true;
                    data.gross_rental_income.error = 'This field is required';
                }
                if (!formData2Student.liability_limit.value.title) {
                    isValid = false;
                    data.liability_limit.hasError = true;
                    data.liability_limit.error = 'This field is required';
                }
                if (
                    !formData2Student.flood.value.title &&
                    additionalCoverages.includes('3')
                ) {
                    isValid = false;
                    data.flood.hasError = true;
                    data.flood.error = 'This field is required';
                }
                if (
                    formData2Student.flood.value.title ||
                    !additionalCoverages.includes('3')
                ) {
                    data.flood.hasError = false;
                    data.flood.error = '';
                }

                if (!formData2Student.dwelling.value) {
                    isValid = false;
                    data.dwelling.hasError = true;
                    data.dwelling.error = 'This field is required';
                }
                if (!formData2Student.additional_building.value) {
                    data.additional_building.hasError = false;
                    data.additional_building.error = '';
                }
                if (!formData2Student.improvements_betterments.value) {
                    data.improvements_betterments.hasError = false;
                    data.improvements_betterments.error = '';
                }
                if (!formData2Student.loss_assessment.value) {
                    data.loss_assessment.hasError = false;
                    data.loss_assessment.error = '';
                }
                if (!formData2Student.unit_owner_protection.value) {
                    data.unit_owner_protection.hasError = false;
                    data.unit_owner_protection.error = '';
                }
            }
        }
        setFormData2Student(fillFormAction(data));

        return isValid;
    }
    if (step === 3) {
        const data = { ...formData3 };
        let isValid = true;

        if (!formData3.name_of_insured.value) {
            isValid = false;
            data.name_of_insured.hasError = true;
            data.name_of_insured.error = 'This field is required';
        }
        if (!formData3.mailing_address.value) {
            isValid = false;
            data.mailing_address.hasError = true;
            data.mailing_address.error = 'This field is required';
        }
        if (!formData3.location_address.value) {
            isValid = false;
            data.location_address.hasError = true;
            data.location_address.error = 'This field is required';
        }
        if (!formData3.mortgagee.value) {
            isValid = false;
            data.mortgagee.hasError = true;
            data.mortgagee.error = 'This field is required';
        }
        if (!formData3.mortgagee_address.value) {
            isValid = false;
            data.mortgagee_address.hasError = true;
            data.mortgagee_address.error = 'This field is required';
        }
        if (!formData3.effective_date.value) {
            isValid = false;
            data.effective_date.hasError = true;
            data.effective_date.error = 'This field is required';
        }
        if (!formData3.policy_term.value) {
            isValid = false;
            data.policy_term.hasError = true;
            data.policy_term.error = 'This field is required';
        }
        if (!formData3.prior_insurance.value) {
            isValid = false;
            data.prior_insurance.hasError = true;
            data.prior_insurance.error = 'This field is required';
        }
        if (!formData3.broker_location.value) {
            isValid = false;
            data.broker_location.hasError = true;
            data.broker_location.error = 'This field is required';
        }
        if (!formData3.contained_units.value) {
            isValid = false;
            data.contained_units.hasError = true;
            data.contained_units.error = 'This field is required';
        }
        if (!formData3.responsible_dwelling.value) {
            isValid = false;
            data.responsible_dwelling.hasError = true;
            data.responsible_dwelling.error = 'This field is required';
        }
        if (!formData3.responsible_for_snow.value) {
            isValid = false;
            data.responsible_for_snow.hasError = true;
            data.responsible_for_snow.error = 'This field is required';
        }
        if (!formData3.how_often_inspected.value) {
            isValid = false;
            data.how_often_inspected.hasError = true;
            data.how_often_inspected.error = 'This field is required';
        }
        setFormData3(fillFormAction(data));

        return isValid;
    }
};
