export const disabledFields = (
    formData2Student,
    setFormData2Student,
    formData2Residental,
    setFormData2Residental,
    fillFormAction,
    quotePackage
) => {
    // debugger;
    if (quotePackage.title === 'Student Rental') {
        const data = { ...formData2Student };

        if (
            !formData2Student.rental_type.value.title ||
            !formData2Student.number_of_students.value.title
        ) {
            data.number_of_students.disabled = false;
            data.rental_type.disabled = false;
            data.sewer_back_up.disabled = true;
            data.sewer_back_up.error = '';
            data.sewer_back_up.hasError = false;

            data.deductible.disabled = false;
            data.landlord_contents.disabled = false;
            data.gross_rental_income.disabled = false;
            data.liability_limit.disabled = false;
            data.improvements_betterments.disabled = true;
            data.improvements_betterments.error = '';
            data.improvements_betterments.hasError = false;

            data.loss_assessment.disabled = true;
            data.loss_assessment.error = '';
            data.loss_assessment.hasError = false;

            data.unit_owner_protection.disabled = true;
            data.unit_owner_protection.error = '';
            data.unit_owner_protection.hasError = false;

            data.additional_building.disabled = true;
            data.additional_building.error = '';
            data.additional_building.hasError = false;

            data.dwelling.disabled = true;
            data.dwelling.error = '';
            data.dwelling.hasError = false;

            data.location_three_or_more_years.disabled = true;
            data.building_past_three_years.disabled = true;
            data.dwelling_age.disabled = true;
            data.certified_rental_building.disabled = true;
            data.customer_more_three_year.disabled = true;
            data.flood.disabled = false;
            data.other_policy_with_halwell.disabled = true;
        }

        if (
            formData2Student.rental_type.value.title === 'Condominium' &&
            formData2Student.number_of_students.value.title === '1-5'
        ) {
            data.number_of_students.disabled = false;
            data.rental_type.disabled = false;
            data.sewer_back_up.disabled = true;
            data.sewer_back_up.error = '';
            data.sewer_back_up.hasError = false;

            data.deductible.disabled = false;
            data.landlord_contents.disabled = false;
            data.gross_rental_income.disabled = false;
            data.liability_limit.disabled = false;
            data.improvements_betterments.disabled = false;
            data.loss_assessment.disabled = false;
            data.unit_owner_protection.disabled = false;
            data.additional_building.disabled = true;
            data.additional_building.error = '';
            data.additional_building.hasError = false;

            data.dwelling.disabled = true;
            data.dwelling.error = '';
            data.dwelling.hasError = false;

            data.location_three_or_more_years.disabled = true;
            data.building_past_three_years.disabled = true;
            data.dwelling_age.disabled = true;
            data.certified_rental_building.disabled = true;
            data.customer_more_three_year.disabled = true;
            data.flood.disabled = false;
            data.other_policy_with_halwell.disabled = true;
            data.other_policy_with_halwell.error = '';
            data.other_policy_with_halwell.hasError = false;
        }
        if (
            formData2Student.rental_type.value.title === 'Condominium' &&
            formData2Student.number_of_students.value.title === '6-10'
        ) {
            data.number_of_students.disabled = false;
            data.rental_type.disabled = false;
            data.sewer_back_up.disabled = false;
            data.deductible.disabled = false;
            data.landlord_contents.disabled = false;
            data.gross_rental_income.disabled = false;
            data.liability_limit.disabled = false;
            data.improvements_betterments.disabled = false;
            data.loss_assessment.disabled = false;
            data.unit_owner_protection.disabled = false;
            data.additional_building.disabled = true;
            data.additional_building.error = '';
            data.additional_building.hasError = false;

            data.dwelling.disabled = true;
            data.dwelling.error = '';
            data.dwelling.hasError = false;

            data.location_three_or_more_years.disabled = true;
            data.building_past_three_years.disabled = true;
            data.dwelling_age.disabled = true;
            data.certified_rental_building.disabled = true;
            data.customer_more_three_year.disabled = true;
            data.flood.disabled = false;
            data.other_policy_with_halwell.disabled = true;
            data.other_policy_with_halwell.error = '';
            data.other_policy_with_halwell.hasError = false;
        }
        if (
            formData2Student.rental_type.value.title === 'Dwelling' &&
            formData2Student.number_of_students.value.title === '1-5'
        ) {
            data.number_of_students.disabled = false;
            data.rental_type.disabled = false;
            data.sewer_back_up.disabled = true;
            data.sewer_back_up.error = '';
            data.sewer_back_up.hasError = false;

            data.deductible.disabled = false;
            data.landlord_contents.disabled = false;
            data.gross_rental_income.disabled = false;
            data.liability_limit.disabled = false;
            data.improvements_betterments.disabled = true;
            data.improvements_betterments.error = '';
            data.improvements_betterments.hasError = false;

            data.loss_assessment.disabled = true;
            data.loss_assessment.error = '';
            data.loss_assessment.hasError = false;

            data.unit_owner_protection.disabled = true;
            data.unit_owner_protection.error = '';
            data.unit_owner_protection.hasError = false;

            data.additional_building.disabled = false;
            data.dwelling.disabled = false;
            data.location_three_or_more_years.disabled = false;
            data.building_past_three_years.disabled = false;
            data.dwelling_age.disabled = false;
            data.certified_rental_building.disabled = false;
            data.customer_more_three_year.disabled = false;
            data.flood.disabled = false;
            data.other_policy_with_halwell.disabled = false;
        }
        if (
            formData2Student.rental_type.value.title === 'Dwelling' &&
            formData2Student.number_of_students.value.title === '6-10'
        ) {
            data.number_of_students.disabled = false;
            data.rental_type.disabled = false;
            data.sewer_back_up.disabled = false;
            data.deductible.disabled = false;
            data.landlord_contents.disabled = false;
            data.gross_rental_income.disabled = false;
            data.liability_limit.disabled = false;
            data.improvements_betterments.disabled = true;
            data.improvements_betterments.error = '';
            data.improvements_betterments.hasError = false;

            data.loss_assessment.disabled = true;
            data.loss_assessment.error = '';
            data.loss_assessment.hasError = false;

            data.unit_owner_protection.disabled = true;
            data.unit_owner_protection.error = '';
            data.unit_owner_protection.hasError = false;

            data.additional_building.disabled = false;
            data.dwelling.disabled = false;
            data.location_three_or_more_years.disabled = false;
            data.building_past_three_years.disabled = false;
            data.dwelling_age.disabled = false;
            data.certified_rental_building.disabled = false;
            data.customer_more_three_year.disabled = false;
            data.flood.disabled = false;
            data.other_policy_with_halwell.disabled = false;
        }
        setFormData2Student(fillFormAction(data));
    }
    if (quotePackage.title === 'Residential Rental') {
        const data = { ...formData2Residental };

        if (!formData2Residental.rental_type.value.title) {
            data.rental_type.disabled = false;
            data.deductible.disabled = false;
            data.landlord_contents.disabled = false;
            data.gross_rental_income.disabled = false;
            data.liability_limit.disabled = false;
            data.improvements_betterments.disabled = true;
            data.improvements_betterments.error = '';
            data.improvements_betterments.hasError = false;

            data.loss_assessment.disabled = true;
            data.loss_assessment.error = '';
            data.loss_assessment.hasError = false;

            data.unit_owner_protection.disabled = true;
            data.unit_owner_protection.error = '';
            data.unit_owner_protection.hasError = false;

            data.additional_building.disabled = true;
            data.additional_building.error = '';
            data.additional_building.hasError = false;

            data.building.disabled = true;
            data.building.error = '';
            data.building.hasError = false;
        }
        if (formData2Residental.rental_type.value.title === 'Condominium') {
            data.rental_type.disabled = false;
            data.deductible.disabled = false;
            data.landlord_contents.disabled = false;
            data.gross_rental_income.disabled = false;
            data.liability_limit.disabled = false;

            data.improvements_betterments.disabled = false;
            data.loss_assessment.disabled = false;
            data.unit_owner_protection.disabled = false;

            data.additional_building.disabled = true;
            data.additional_building.error = '';
            data.additional_building.hasError = false;

            data.building.disabled = true;
            data.building.error = '';
            data.building.hasError = false;
        }
        if (formData2Residental.rental_type.value.title === 'Dwelling') {
            data.rental_type.disabled = false;
            data.deductible.disabled = false;
            data.landlord_contents.disabled = false;
            data.gross_rental_income.disabled = false;
            data.liability_limit.disabled = false;

            data.improvements_betterments.disabled = true;
            data.improvements_betterments.error = '';
            data.improvements_betterments.hasError = false;

            data.loss_assessment.disabled = true;
            data.loss_assessment.error = '';
            data.loss_assessment.hasError = false;

            data.unit_owner_protection.disabled = true;
            data.unit_owner_protection.error = '';
            data.unit_owner_protection.hasError = false;

            data.additional_building.disabled = false;
            data.building.disabled = false;
        }
        setFormData2Residental(fillFormAction(data));
    }
};
