import React from 'react';
import './footer.scss';
import moment from 'moment';

export const Footer = () => {
    return (
        <>
            <footer className="footer">
                <div className="footer__text">
                    © {moment().format('YYYY')} Copyright Halwell Mutual. All
                    Rights Reserved
                </div>
            </footer>
        </>
    );
};
