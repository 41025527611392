import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { CustomSelection } from '../CustomFields/CustomSelection';
import { useDispatch, useSelector } from 'react-redux';
import { setQuotePackage } from '../../redux/quote/quote';
import './CreateQuoteModal.scss';

export const CreateQuoteModal = ({ setOpenModal }) => {
    const navigate = useNavigate();
    const [error, setError] = useState(false);
    const quotePackage = useSelector((state) => state.quote.quotePackage);
    const dispatch = useDispatch();

    useEffect(() => {
        if (quotePackage) {
            setError(false);
        }
    }, [quotePackage]);

    const handleChange = (value) => {
        dispatch(setQuotePackage(value));
    };

    return (
        <>
            <div className="modal-wrapper"></div>
            <div className="modal">
                <div className="modal__title-wrap">
                    <div className="modal__title">Create new Quote</div>
                    <div
                        className="modal__close"
                        onClick={() => {
                            dispatch(setQuotePackage(''));
                            setOpenModal(false);
                        }}
                    >
                        ✖
                    </div>
                </div>
                <div className="modal__subtitle">Select the package</div>

                <CustomSelection
                    className="modal-field"
                    placeholder="Select answer"
                    arrowImage
                    options={[
                        { title: 'Residential Rental' },
                        { title: 'Student Rental' },
                    ]}
                    onChange={(value) => handleChange(value)}
                    value={quotePackage.title}
                />

                <div className="modal__btns">
                    <button
                        className="modal__cancel"
                        onClick={() => {
                            dispatch(setQuotePackage(''));
                            setOpenModal(false);
                        }}
                    >
                        Cancel
                    </button>
                    <button
                        className={`modal__create ${
                            !quotePackage ? 'disabled' : ''
                        }`}
                        onClick={() => {
                            if (quotePackage) {
                                navigate('/new_quote/?step=1');
                            }
                        }}
                    >
                        Create
                    </button>
                </div>
            </div>
        </>
    );
};
