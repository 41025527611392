import React from 'react';
import './TotalPremium.scss';

export const TotalPremium = ({ total, addCovError }) => {
    return (
        <div className="total-premium">
            <div className="total-premium__text">Total Premium:</div>
            <div className="total-premium__value">
                {addCovError
                    ? 'Not completed'
                    : `$${Math.round(total)
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`}
            </div>
        </div>
    );
};
