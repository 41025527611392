export const validateWithConstRental = (
    step,
    formData,
    setFormData,
    fillFormAction,
    validation
) => {
    let isValid = true;
    if (step === 2) {
        const data = { ...formData };
        
        // Validation for gross_rental_income
        if (data.gross_rental_income.value) {
            const grossIncomeValue = parseInt(data.gross_rental_income.value.split(',').join(''), 10);
            const isAboveMiscLimit = grossIncomeValue > validation?.misc_limit_max;
            const isBelowGriBaseLimit = data.rental_type.value.id === 1 && grossIncomeValue < validation?.GRI_base_limit;

            if (isAboveMiscLimit) {
                isValid = false;
                data.gross_rental_income.error = `Gross Rental Income should be less than or equal to $${validation?.misc_limit_max}`;
                data.gross_rental_income.hasError = true;
            } else if (isBelowGriBaseLimit) {
                isValid = false;
                data.gross_rental_income.error = `Gross Rental Income should be greater or equal to $${validation?.GRI_base_limit}`;
                data.gross_rental_income.hasError = true;
            } else if (data.gross_rental_income.error === 'This field is required') {
                data.gross_rental_income.hasError = true;
            } else {
                data.gross_rental_income.error = '';
                data.gross_rental_income.hasError = false;
            }
        }
        // validations for dwellings
        if (data.rental_type.value.id === 0) {
            // Landlord contents below misc limit
            if (
                data.landlord_contents.value &&
                data.landlord_contents.value.split(',').join('') >
                    (validation?.misc_limit_max)
            ) {
                isValid = false;
                data.landlord_contents.error = `Landlord contents should be less than or equal to $${validation?.misc_limit_max}`;
                data.landlord_contents.hasError = true;
            } else if (!data.landlord_contents.hasError) { // Only reset error if there was no previous error
                data.landlord_contents.error =
                    data.landlord_contents.error === 'This field is required'
                        ? 'This field is required'
                        : '';
                data.landlord_contents.hasError =
                    data.landlord_contents.error === 'This field is required'
                        ? true
                        : false;
            }
            // Building value below max limit
            if (
                data.building.value &&
                data.building.value.split(',').join('') >
                    validation?.Dwelling_limit
            ) {
                isValid = false;
                data.building.error = `Building should not exceed $${validation?.Dwelling_limit}`;
                data.building.hasError = true;
            } else {
                data.building.error =
                    data.building.error === 'This field is required'
                        ? 'This field is required'
                        : '';
                data.building.hasError =
                    data.building.error === 'This field is required'
                        ? true
                        : false;
            }
            // Additional building above base limit
            if (
                data.additional_building.value &&
                data.additional_building.value.split(',').join('') >
                    validation?.Additional_building_base_limit
            ) {
                isValid = false;
                data.additional_building.hasError = true;
                data.additional_building.error = `Additional Building should not exceed $${validation?.Additional_building_base_limit}`;
            } else {
                data.additional_building.error =
                    data.additional_building.error === 'This field is required'
                        ? 'This field is required'
                        : '';
                data.additional_building.hasError =
                    data.additional_building.error === 'This field is required'
                        ? true
                        : false;
            }
        }
        // validations for condos
        if (data.rental_type.value.id === 1) {
            // Landlord contents above base limit
            if (
                data.landlord_contents.value &&
                (data.landlord_contents.value.split(',').join('') >
                    validation?.LanConts_base_max ||
                    data.landlord_contents.value.split(',').join('') <
                        validation?.LanConts_base_limit_resid)
            ) {
                isValid = false;
    
                data.landlord_contents.error = `Landlord Contents should be within the range of $${validation?.LanConts_base_limit_resid} and $${validation?.LanConts_base_max}`;
                data.landlord_contents.hasError = true;
            } else {
                data.landlord_contents.error =
                    data.landlord_contents.error === 'This field is required'
                        ? 'This field is required'
                        : '';
                data.landlord_contents.hasError =
                    data.landlord_contents.error === 'This field is required'
                        ? true
                        : false;
            }

            // Improvements and betterments below max limit
            if (
                data.improvements_betterments.value &&
                (data.improvements_betterments.value.split(',').join('') >
                    validation?.IB_base_max ||
                    data.improvements_betterments.value.split(',').join('') <
                        validation?.IB_base_limit)
            ) {
                isValid = false;
                data.improvements_betterments.error = `Improvements & Betterments should be within the range of $${validation.IB_base_limit} and $${validation.IB_base_max}`;
                data.improvements_betterments.hasError = true;
            } else {
                data.improvements_betterments.error =
                    data.improvements_betterments.error ===
                    'This field is required'
                        ? 'This field is required'
                        : '';
                data.improvements_betterments.hasError =
                    data.improvements_betterments.error ===
                    'This field is required'
                        ? true
                        : false;
            }
            // Unit ownder below max limit
            if (
                data.unit_owner_protection.value &&
                data.unit_owner_protection.value.split(',').join('') <
                    validation?.UOAP_base_limit
            ) {
                isValid = false;
                data.unit_owner_protection.error = `Unit Owner Protection should be equal to or greater than $${validation.UOAP_base_limit}`;
                data.unit_owner_protection.hasError = true;
            }
            // unit owner protection below misc limit max
            else if (
                data.unit_owner_protection.value &&
                parseInt(data.unit_owner_protection.value.split(',').join('')) >
                    (validation?.misc_limit_max)
            ) {
                isValid = false;
                data.unit_owner_protection.error = `Loss Assessment should be less than or equal to $${validation?.misc_limit_max}`;
                data.unit_owner_protection.hasError = true;
            }
            else {
                data.unit_owner_protection.error =
                    data.unit_owner_protection.error ===
                    'This field is required'
                        ? 'This field is required'
                        : '';
                data.unit_owner_protection.hasError =
                    data.unit_owner_protection.error ===
                    'This field is required'
                        ? true
                        : false;
            }
            // Loss assessment above base limit
            if (
                data.loss_assessment.value &&
                data.loss_assessment.value.split(',').join('') <
                    validation?.LA_base_limit
            ) {
                isValid = false;
                data.loss_assessment.hasError = true;
                data.loss_assessment.error = `Loss Assessment should be equal to or greater than $${validation.LA_base_limit}`;
            } 
            // loss assessment below misc limit max
            else if (
                data.loss_assessment.value &&
                parseInt(data.loss_assessment.value.split(',').join('')) >
                    (validation?.misc_limit_max)
            ) {
                isValid = false;
                data.loss_assessment.error = `Loss Assessment should be less than or equal to $${validation?.misc_limit_max}`;
                data.loss_assessment.hasError = true;
            } 
            else {
                data.loss_assessment.error =
                    data.loss_assessment.error === 'This field is required'
                        ? 'This field is required'
                        : '';
                data.loss_assessment.hasError =
                    data.loss_assessment.error === 'This field is required'
                        ? true
                        : false;
            }
        }

        setFormData(fillFormAction(data));
    }
    return isValid;
};
