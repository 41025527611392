import moment from 'moment';
import {
    createQuote,
    updateQuote,
    addLoose,
    updateLoose,
    deleteLoose,
    getQuote,
} from '../../crud/quote';
import { setCurrentQuote } from '../../redux/quote/quote';
import { fillFormAction } from '../formUtils';
import { clearDisabledFields } from './clearDisabledFields';
import {
    setIsPopup,
    setIsPopupError,
    setIsPopupTotal,
    setTotalPrem,
} from '../../redux/popup/popup';
import { setIsChanged } from '../../redux/quote/quote';
import { getTableData } from '../../crud/quote';

export const saveQuote = (
    step,
    isFirstTime,
    listOfLooses,
    currentQuote,
    quotePackage,
    setListOfLooses,
    formData3,
    formData2Student,
    setFormData2Student,
    formData2Residental,
    setFormData2Residental,
    formData1,
    setIsFirstTime,
    dispatch,
    viewQuote,
    setLoading,
    additionalCoverages,
    openModalSave,
    setOpenModalSave,
    navigate,
    save,
    pastSteps,
    formData4,
    setTableData,
    totalPremium
) => {
    if (step === 1 && isFirstTime) {
        setLoading(true);
        const data = {
            package: quotePackage.title,
            agree_risk: formData1.agree_risk,
            location_occupied: formData1.location_occupied,
            more_than_2_mortgages: formData1.more_than_2_mortgages,
            mortgages_through_traditional_lenders:
                formData1.mortgages_through_traditional_lenders,
            step: pastSteps,
        };
        setIsFirstTime(false);
        if (viewQuote) {
            updateQuote(viewQuote, data)
                .then(() => {
                    dispatch(setIsChanged(false));
                    dispatch(setIsPopup(true));
                    setTimeout(() => {
                        dispatch(setIsPopup(false));
                    }, 3000);
                })
                .catch((err) => {
                    dispatch(setIsPopupError(true));
                    setTimeout(() => {
                        dispatch(setIsPopupError(false));
                    }, 3000);
                })
                .finally(() => setLoading(false));
        } else {
            createQuote(data)
                .then((res) => {
                    dispatch(setIsChanged(false));
                    dispatch(setIsPopup(true));
                    setTimeout(() => {
                        dispatch(setIsPopup(false));
                    }, 3000);
                    dispatch(setCurrentQuote(res.data.id));
                    if (save) {
                        navigate(`/new_quote/${res.data.id}?step=1`);
                    } else {
                        navigate(`/new_quote/${res.data.id}?step=2`);
                    }
                })
                .catch((err) => {
                    dispatch(setIsPopupError(true));
                    setTimeout(() => {
                        dispatch(setIsPopupError(false));
                    }, 3000);
                })
                .finally(() => setLoading(false));
        }
    }
    if (step === 1 && !isFirstTime) {
        setLoading(true);
        const data = {
            step: pastSteps,
            package: quotePackage.title,
            agree_risk: formData1.agree_risk,
            location_occupied: formData1.location_occupied,
            more_than_2_mortgages: formData1.more_than_2_mortgages,
            mortgages_through_traditional_lenders:
                formData1.mortgages_through_traditional_lenders,
        };
        if (currentQuote) {
            updateQuote(currentQuote, data)
                .then((res) => {
                    dispatch(setIsPopup(true));
                    setTimeout(() => {
                        dispatch(setIsPopup(false));
                    }, 3000);
                    dispatch(setIsChanged(false));
                })
                .catch((err) => {
                    dispatch(setIsPopupError(true));
                    setTimeout(() => {
                        dispatch(setIsPopupError(false));
                    }, 3000);
                })
                .finally(() => setLoading(false));
        }
    }
    if (step === 2) {
        if (quotePackage.title === 'Student Rental') {
            setLoading(true);

            const data = { step: pastSteps };

            if (formData2Student.number_of_students.value) {
                data.number_of_students =
                    formData2Student.number_of_students.value.id;
            }
            if (formData2Student.rental_type.value) {
                data.rental_type = formData2Student.rental_type.value.id;
            }
            if (formData2Student.deductible.value.title) {
                data.deductible = formData2Student.deductible.value.id;
            }
            if (formData2Student.landlord_contents.value) {
                data.landlord_contents =
                    +formData2Student.landlord_contents.value
                        .split(',')
                        .join('');
            } else {
                data.landlord_contents = null;
            }
            if (formData2Student.gross_rental_income.value) {
                data.gross_rental_income =
                    +formData2Student.gross_rental_income.value
                        .split(',')
                        .join('');
            } else {
                data.gross_rental_income = null;
            }
            if (formData2Student.liability_limit.value.title) {
                data.liability_limit =
                    +formData2Student.liability_limit.value.id;
            } else {
                data.liability_limit = null;
            }
            data.additional_coverages = additionalCoverages.map(
                (elem) => +elem
            );
            if (formData2Student.flood.value) {
                if (formData2Student.flood.value.title) {
                    if (formData2Student.flood.value.title === 'Yes') {
                        data.flood = true;
                    }
                    if (formData2Student.flood.value.title === 'No') {
                        data.flood = false;
                    }
                }
            }

            if (
                (formData2Student.number_of_students.value.id === 0 ||
                    formData2Student.number_of_students.value.id === 1) &&
                formData2Student.rental_type.value.id === 1
            ) {
                if (formData2Student.improvements_betterments.value) {
                    data.improvements_betterments =
                        +formData2Student.improvements_betterments.value
                            .split(',')
                            .join('');
                } else {
                    data.improvements_betterments = null;
                }
                if (formData2Student.loss_assessment.value) {
                    data.loss_assessment =
                        +formData2Student.loss_assessment.value
                            .split(',')
                            .join('');
                } else {
                    data.loss_assessment = null;
                }
                if (formData2Student.unit_owner_protection.value) {
                    data.unit_owner_protection =
                        +formData2Student.unit_owner_protection.value
                            .split(',')
                            .join('');
                } else {
                    data.unit_owner_protection = null;
                }
            }
            if (
                formData2Student.number_of_students.value.id === 1 &&
                (formData2Student.rental_type.value.id === 1 ||
                    formData2Student.rental_type.value.id === 0)
            ) {
                if (formData2Student.sewer_back_up.value.title) {
                    data.sewer_back_up =
                        formData2Student.sewer_back_up.value.id;
                }
            }
            if (
                (formData2Student.number_of_students.value.id === 0 ||
                    formData2Student.number_of_students.value.id === 1) &&
                formData2Student.rental_type.value.id === 0
            ) {
                if (formData2Student.additional_building.value) {
                    data.additional_building =
                        +formData2Student.additional_building.value
                            .split(',')
                            .join('');
                } else {
                    data.additional_building = null;
                }
                if (formData2Student.dwelling.value) {
                    data.dwelling = +formData2Student.dwelling.value
                        .split(',')
                        .join('');
                } else {
                    data.dwelling = null;
                }

                data.location_three_or_more_years =
                    formData2Student.location_three_or_more_years.value;

                data.building_past_three_years =
                    formData2Student.building_past_three_years.value;

                data.dwelling_age = formData2Student.dwelling_age.value;

                data.certified_rental_building =
                    formData2Student.certified_rental_building.value;

                data.customer_more_three_year =
                    formData2Student.customer_more_three_year.value;

                data.other_policy_with_halwell =
                    formData2Student.other_policy_with_halwell.value || '';
            }
            if (currentQuote) {
                updateQuote(currentQuote, data)
                    .then(() => {
                        getQuote(currentQuote).then((res) => {
                            if (
                                Math.round(totalPremium) !==
                                Math.round(res.data.total_premium)
                            ) {
                                dispatch(setTotalPrem(res.data.total_premium));
                                dispatch(setIsPopupTotal(true));
                                setTimeout(() => {
                                    dispatch(setIsPopupTotal(false));
                                    dispatch(setTotalPrem(''));
                                }, 5000);
                            }
                        });
                        dispatch(setIsPopup(true));
                        setTimeout(() => {
                            dispatch(setIsPopup(false));
                        }, 3000);
                        clearDisabledFields(
                            formData2Student,
                            setFormData2Student
                        );

                        dispatch(setIsChanged(false));
                        if (openModalSave) {
                            setOpenModalSave(false);
                        }
                    })
                    .catch((err) => {
                        dispatch(setIsPopupError(true));
                        setTimeout(() => {
                            dispatch(setIsPopupError(false));
                        }, 3000);
                    })
                    .finally(() => setLoading(false));
            }
        }
        if (quotePackage.title === 'Residential Rental') {
            setLoading(true);

            const data = { step: pastSteps };

            if (formData2Residental.rental_type.value) {
                data.rental_type = formData2Residental.rental_type.value.id;
            }
            if (formData2Residental.deductible.value.title) {
                data.deductible = formData2Residental.deductible.value.id;
            }
            if (formData2Residental.landlord_contents.value) {
                data.landlord_contents =
                    +formData2Residental.landlord_contents.value
                        .split(',')
                        .join('');
            } else {
                data.landlord_contents = null;
            }
            if (formData2Residental.gross_rental_income.value) {
                data.gross_rental_income =
                    +formData2Residental.gross_rental_income.value
                        .split(',')
                        .join('');
            } else {
                data.gross_rental_income = null;
            }
            if (formData2Residental.liability_limit.value.title) {
                data.liability_limit =
                    +formData2Residental.liability_limit.value.id;
            }
            data.additional_coverages_residential = additionalCoverages.map(
                (elem) => +elem
            );
            if (formData2Residental.flood.value) {
                if (formData2Residental.flood.value.title) {
                    if (formData2Residental.flood.value.title === 'Yes') {
                        data.flood = true;
                    }
                    if (formData2Residental.flood.value.title === 'No') {
                        data.flood = false;
                    }
                }
            }

            if (formData2Residental.rental_type.value.id === 1) {
                if (formData2Residental.improvements_betterments.value) {
                    data.improvements_betterments =
                        +formData2Residental.improvements_betterments.value
                            .split(',')
                            .join('');
                } else {
                    data.improvements_betterments = null;
                }
                if (formData2Residental.loss_assessment.value) {
                    data.loss_assessment =
                        +formData2Residental.loss_assessment.value
                            .split(',')
                            .join('');
                } else {
                    data.loss_assessment = null;
                }
                if (formData2Residental.unit_owner_protection.value) {
                    data.unit_owner_protection =
                        +formData2Residental.unit_owner_protection.value
                            .split(',')
                            .join('');
                } else {
                    data.unit_owner_protection = null;
                }
            }
            if (formData2Residental.rental_type.value.id === 0) {
                if (formData2Residental.additional_building.value) {
                    data.additional_building =
                        +formData2Residental.additional_building.value
                            .split(',')
                            .join('');
                } else {
                    data.additional_building = null;
                }
                if (formData2Residental.building.value) {
                    data.building = +formData2Residental.building.value
                        .split(',')
                        .join('');
                } else {
                    data.building = null;
                }
            }
            if (currentQuote) {
                updateQuote(currentQuote, data)
                    .then((res) => {
                        getQuote(currentQuote).then((res) => {
                            if (
                                Math.round(totalPremium) !==
                                Math.round(res.data.total_premium)
                            ) {
                                dispatch(setTotalPrem(res.data.total_premium));
                                dispatch(setIsPopupTotal(true));
                                setTimeout(() => {
                                    dispatch(setIsPopupTotal(false));
                                    dispatch(setTotalPrem(''));
                                }, 5000);
                            }
                        });

                        dispatch(setIsPopup(true));
                        setTimeout(() => {
                            dispatch(setIsPopup(false));
                        }, 3000);
                        clearDisabledFields(
                            formData2Residental,
                            setFormData2Residental
                        );

                        dispatch(setIsChanged(false));
                        if (openModalSave) {
                            setOpenModalSave(false);
                        }
                    })
                    .catch((err) => {
                        dispatch(setIsPopupError(true));
                        setTimeout(() => {
                            dispatch(setIsPopupError(false));
                        }, 3000);
                    })
                    .finally(() => setLoading(false));
            }
        }
    }
    if (step === 3) {

        setLoading(true);

        const data = {
            step: pastSteps,
            name_of_insured: formData3.name_of_insured.value
                ? formData3.name_of_insured.value
                : '',
            mailing_address: formData3.mailing_address.value
                ? formData3.mailing_address.value
                : '',
            location_address: formData3.location_address.value
                ? formData3.location_address.value
                : '',
            mortgagee: formData3.mortgagee.value
                ? formData3.mortgagee.value
                : '',
            mortgagee_address: formData3.mortgagee_address.value
                ? formData3.mortgagee_address.value
                : '',
            effective_date: formData3.effective_date.value
                ? moment(formData3.effective_date.value).format('YYYY-MM-DD')
                : null,
            policy_term: formData3.policy_term.value.id
                ? formData3.policy_term.value.id
                : '',
            prior_insurance: formData3.prior_insurance.value.id
                ? formData3.prior_insurance.value.id
                : '',
            broker_location: formData3.broker_location.value
                ? formData3.broker_location.value
                : '',

            has_insured_declined: formData3.has_insured_declined.value,
            insured_more_tree_years: formData3.insured_more_tree_years.value,
            within_100_km: formData3.within_100_km.value,
            required_proof: formData3.required_proof.value,

            contained_units: formData3.contained_units.value
                ? formData3.contained_units.value
                : null,
            responsible_dwelling: formData3.responsible_dwelling.value
                ? formData3.responsible_dwelling.value
                : '',
            responsible_for_snow: formData3.responsible_for_snow.value
                ? formData3.responsible_for_snow.value
                : '',
            how_often_inspected: formData3.how_often_inspected.value
                ? formData3.how_often_inspected.value
                : '',

            two_or_more_checks: formData3.two_or_more_checks.value,
            is_separate_agreement: formData3.is_separate_agreement.value,
        };

        let result = [];
        for (let i = 0; i < listOfLooses.length; i++) {
            if (
                listOfLooses[i].date.value ||
                listOfLooses[i].description.value ||
                listOfLooses[i].amount_paid.value ||
                listOfLooses[i].is_open.value.title
            ) {
                let obj = {};

                if (listOfLooses[i].id) {
                    obj = {
                        id: listOfLooses[i].id,
                        date: listOfLooses[i].date.value
                            ? moment(listOfLooses[i].date.value).format(
                                  'YYYY-MM-DD'
                              )
                            : null,
                        description: listOfLooses[i].description.value,
                        amount_paid: listOfLooses[i].amount_paid.value
                            ? +listOfLooses[i].amount_paid.value
                                  .split(',')
                                  .join('')
                            : null,
                        is_open: listOfLooses[i].is_open.value.title
                            ? listOfLooses[i].is_open.value.title === 'Open'
                                ? true
                                : false
                            : '',
                    };
                } else {
                    obj = {
                        date: listOfLooses[i].date.value
                            ? moment(listOfLooses[i].date.value).format(
                                  'YYYY-MM-DD'
                              )
                            : null,
                        description: listOfLooses[i].description.value,
                        amount_paid: listOfLooses[i].amount_paid.value
                            ? +listOfLooses[i].amount_paid.value
                                  .split(',')
                                  .join('')
                            : null,
                        is_open: listOfLooses[i].is_open.value.title
                            ? listOfLooses[i].is_open.value.title === 'Open'
                                ? true
                                : false
                            : '',
                    };
                }
                result.push(obj);
            }
        }

        let filtered = listOfLooses.filter(
            (elem) =>
                elem.date.value ||
                elem.description.value ||
                elem.amount_paid.value ||
                elem.is_open.value.title
        );

        if (filtered.length < 1) {
            filtered = [
                {
                    description: { value: '', error: '', hasError: false },
                    date: { value: '', error: '', hasError: false },
                    amount_paid: { value: '', error: '', hasError: false },
                    is_open: { value: '', error: '', hasError: false },
                    localId: Math.random() + '',
                },
            ];
        }

        setListOfLooses(filtered);

        data.list_looses = result;
        if (currentQuote) {
            updateQuote(currentQuote, data)
                .then(() => {
                    dispatch(setIsPopup(true));
                    setTimeout(() => {
                        dispatch(setIsPopup(false));
                    }, 3000);
                    dispatch(setIsChanged(false));
                })
                .then(() => {
                    if (!save) {
                        setLoading(true);
                        getTableData(currentQuote)
                            .then((res) => setTableData(res.data))
                            .finally(() => setLoading(false));
                    }
                })
                .catch((err) => {
                    dispatch(setIsPopupError(true));
                    setTimeout(() => {
                        dispatch(setIsPopupError(false));
                    }, 3000);
                })
                .finally(() => setLoading(false));
        }
    }
    if (step === 4) {
        setLoading(true);
        const data = {
            additional_notes: formData4.additional_notes,
        };
        if (currentQuote) {
            updateQuote(currentQuote, data)
                .then(() => {
                    if (save) {
                        dispatch(setIsPopup(true));
                        setTimeout(() => {
                            dispatch(setIsPopup(false));
                        }, 3000);
                    }
                    dispatch(setIsChanged(false));
                })
                .catch((err) => {
                    dispatch(setIsPopupError(true));
                    setTimeout(() => {
                        dispatch(setIsPopupError(false));
                    }, 3000);
                })
                .finally(() => setLoading(false));
        }
    }
};
