import React from 'react';
import arrow from '../../assets/images/arrow-black.png';
import ReactPaginate from 'react-paginate';
import noresultImage from '../../assets/images/noresult.png';
import './tables.scss';
import moment from 'moment';

export const TableSubmmitedQuo = ({
    list,
    countOfPages,
    page,
    setPage,
    formData,
    setFormData,
}) => {
    const handlePageClick = (event) => {
        setPage(event.selected + 1);
    };

    return (
        <>
            <div className="table">
                <div className="table__wrap">
                    <div className="table__head head">
                        <div className="head__wrap">
                            <div className="head__item">Quote ID</div>
                            <div
                                className="head__item-arrow"
                                onClick={() =>
                                    setFormData({
                                        ...formData,
                                        ordering:
                                            formData.ordering === 'created_at'
                                                ? '-created_at'
                                                : 'created_at',
                                    })
                                }
                            >
                                ›
                            </div>
                        </div>
                        <div className="head__wrap">
                            <div className="head__item">Client</div>
                            <div
                                className="head__item-arrow"
                                onClick={() =>
                                    setFormData({
                                        ...formData,
                                        ordering:
                                            formData.ordering ===
                                            'name_of_insured'
                                                ? '-name_of_insured'
                                                : 'name_of_insured',
                                    })
                                }
                            >
                                ›
                            </div>
                        </div>
                        <div className="head__wrap ">
                            <div className="head__item ">Package</div>
                            <div
                                className="head__item-arrow"
                                onClick={() =>
                                    setFormData({
                                        ...formData,
                                        ordering:
                                            formData.ordering === 'package'
                                                ? '-package'
                                                : 'package',
                                    })
                                }
                            >
                                ›
                            </div>
                        </div>
                        <div
                            className="head__wrap "
                            style={{ minWidth: '135px' }}
                        >
                            <div className="head__item ">Premium</div>
                            <div
                                className="head__item-arrow"
                                onClick={() =>
                                    setFormData({
                                        ...formData,
                                        ordering:
                                            formData.ordering ===
                                            'total_premium'
                                                ? '-total_premium'
                                                : 'total_premium',
                                    })
                                }
                            >
                                ›
                            </div>
                        </div>
                        <div
                            className="head__wrap"
                            style={{ minWidth: '173px' }}
                        >
                            <div className="head__item" >Created</div>
                            <div
                                className="head__item-arrow"
                                onClick={() =>
                                    setFormData({
                                        ...formData,
                                        ordering:
                                            formData.ordering === 'created_at'
                                                ? '-created_at'
                                                : 'created_at',
                                    })
                                }
                            >
                                ›
                            </div>
                        </div>
                        <div className="head__wrap"  style={{ minWidth: '173px' }}>
                            <div className="head__item">Submitted</div>
                            <div
                                className="head__item-arrow"
                                onClick={() =>
                                    setFormData({
                                        ...formData,
                                        ordering:
                                            formData.ordering === 'submitted_at'
                                                ? '-submitted_at'
                                                : 'submitted_at',
                                    })
                                }
                            >
                                ›
                            </div>
                        </div>
                    </div>
                    {list.length ? (
                        list.map((elem) => {
                            return (
                                <div className="row">
                                    <div className="row__item">
                                        {elem.id}
                                        <button
                                            className="view_in_zendesk"
                                            onClick={() => window.open(elem.zendesk_ticket, '_blank')}
                                            style={{ marginLeft: '10px' }}
                                        >
                                            View
                                        </button>    
                                    </div>
                                    <div className="row__item">
                                        {elem.name_of_insured}
                                    </div>
                                    <div className="row__item ">
                                        {elem.package}
                                    </div>
                                    <div
                                        className="row__item "
                                        style={{ minWidth: '135px' }}
                                    >
                                        $
                                        {(+elem.total_premium)
                                            .toFixed()
                                            .toString()
                                            .replace(
                                                /\B(?=(\d{3})+(?!\d))/g,
                                                ','
                                            )}
                                    </div>
                                    <div
                                        className="row__item "
                                        style={{ minWidth: '173px' }}
                                    >
                                        {elem.created_at
                                            ? moment(elem.created_at).format(
                                                  'YYYY/MM/DD, hh:mm A'
                                              )
                                            : ''}
                                    </div>
                                    <div className="row__item"  style={{ minWidth: '173px' }}>
                                        {elem.submitted_at
                                            ? moment(elem.submitted_at).format(
                                                  'YYYY/MM/DD, hh:mm A'
                                              )
                                            : ''}
                                    </div>
                                </div>
                            );
                        })
                    ) : (
                        <div className="table__noresult noresult">
                            <img src={noresultImage} />
                            <div className="noresult__text">
                                No results found
                            </div>
                        </div>
                    )}
                </div>
                {countOfPages <= 1 ? (
                    ''
                ) : (
                    <div className="table__paginate">
                        <ReactPaginate
                            breakLabel="..."
                            nextLabel="Next"
                            onPageChange={handlePageClick}
                            pageRangeDisplayed={2}
                            pageCount={countOfPages}
                            previousLabel="Previous"
                            pageClassName="li"
                            containerClassName="paginate"
                            marginPagesDisplayed={1}
                            previousClassName="btn"
                            nextClassName="btn"
                            forcePage={page - 1}
                        />
                    </div>
                )}
            </div>
        </>
    );
};
