import { combineReducers } from '@reduxjs/toolkit';
import authReducer from './auth/auth';
import quoteReducer from './quote/quote';
import popupReducer from './popup/popup';

export const rootReducer = combineReducers({
    auth: authReducer,
    quote: quoteReducer,
    popup: popupReducer,
});
