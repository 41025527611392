import React from 'react';
import { Header } from '../../components/Header/Header';
import './PageNotFound.scss';
import PageNotFoundImage from '../../assets/images/PageNotFound.png';
import { useNavigate } from 'react-router-dom';

export const PageNotFound = () => {
    const navigate = useNavigate();
    return (
        <>
            <Header />
            <div className="page_not_found">
                <img
                    src={PageNotFoundImage}
                    className="page_not_found__image"
                />
                <div className="page_not_found__text">Oops, page not found</div>
                <div
                    className="page_not_found__btn"
                    onClick={() => navigate('/homepage')}
                >
                    Back to Home
                </div>
            </div>
        </>
    );
};
