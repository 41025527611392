import React from 'react';
import { Header } from '../../components/Header/Header';
import './AccessDeniedPage.scss';
import AccessDeniedImage from '../../assets/images/access-denied.png';
import { useNavigate } from 'react-router-dom';

export const AccessDeniedPage = () => {
    const navigate = useNavigate();
    return (
        <>
            <Header />
            <div className="access-denied">
                <img src={AccessDeniedImage} className="access-denied__image" />
                <div className="access-denied__text">Access Denied</div>
                <div className="access-denied__subtext">
                    Sorry, you have no permission to view this page
                </div>
                <div
                    className="access-denied__btn"
                    onClick={() => navigate('/homepage')}
                >
                    Back to Home
                </div>
            </div>
        </>
    );
};
