import React, { useState, useEffect } from 'react';
import { Header } from '../../components/Header/Header';
import { TableSubmmitedQuo } from '../../components/Tables/TableSubmmitedQuo';
import { InputField } from '../../components/CustomFields/InputField';
import { CustomSelection } from '../../components/CustomFields/CustomSelection';
import '../quotes.scss';
import { getQuotes } from '../../crud/quote';
import { Loader } from '../../components/Loader/Loader';

export const SubmmitedQuotes = ({ openActions, setOpenActions }) => {
    const [formData, setFormData] = useState({
        package: '',
        search: '',
        status: 'Submitted',
        ordering: '-submitted_at',
    });
    const [reload, setReload] = useState(false);
    const [page, setPage] = useState(1);
    const [loading, setLoading] = useState(false);
    const [countOfPages, setCountOfPages] = useState('');
    const [submittedQuotes, setSubmittedQuotes] = useState([]);
    const [choosenId, setChoosenId] = useState('');
    const [offset, setOffset] = useState(1);

    useEffect(() => {
        setLoading(true);
        let offset = 5 * page - 5;
        const submitted = true
        const inWork = false
        getQuotes(
            offset,
            formData.status.request,
            formData.package.title,
            formData.search,
            formData.ordering,
            inWork,
            submitted
        )
            .then((res) => {
                setSubmittedQuotes(res.data.results);
                let count = Math.ceil(res.data.count / 5);
                setCountOfPages(count);
            })
            .finally(() => setLoading(false));
    }, [page, formData, reload]);

    return (
        <>
            <div className="quotes">
                <Loader loading={loading} />

                <Header title={'Submitted Quotes'} />
                <div className="quotes__wrapper">
                    <div className="quotes__row">
                        <div className="quotes__filters filters">
                            <div className="filters__wrap">
                                <CustomSelection
                                    arrowImage
                                    placeholder="Package"
                                    options={[
                                        { id: 0, title: 'Residential Rental' },
                                        { id: 1, title: 'Student Rental' },
                                    ]}
                                    onChange={(value) => {
                                        setFormData({
                                            ...formData,
                                            package: value,
                                        });
                                    }}
                                    value={
                                        formData.package.title
                                            ? formData.package.title
                                            : ''
                                    }
                                />
                            </div>
                            <div className="filters__wrap-clear clear">
                                <div
                                    className="clear__button"
                                    onClick={() =>
                                        setFormData({
                                            package: '',
                                            status: '',
                                            search: '',
                                        })
                                    }
                                >
                                    Clear
                                </div>
                            </div>
                            <div className="filters__wrap">
                                <InputField
                                    maxLength={100}
                                    arrowImage
                                    placeholder="Search"
                                    name="search"
                                    onKeyPress={formData.search}
                                    onChange={(e) =>
                                        setFormData({
                                            ...formData,
                                            search: e.target.value,
                                        })
                                    }
                                    value={formData.search}
                                    width={'130px'}
                                    padding="12px 35px"
                                />
                                <div
                                    className="customInput__search-clear"
                                    onClick={() =>
                                        setFormData({ ...formData, search: '' })
                                    }
                                >
                                    +
                                </div>
                            </div>
                        </div>
                    </div>
                    <TableSubmmitedQuo
                        countOfPages={countOfPages}
                        setPage={setPage}
                        page={page}
                        setFormData={setFormData}
                        formData={formData}
                        setLoading={setLoading}
                        setReload={setReload}
                        list={submittedQuotes}
                    />
                </div>
            </div>
        </>
    );
};
