import React, { useEffect, useState } from 'react';
import { Header } from '../../components/Header/Header';
import { useSelector } from 'react-redux';
import { TableQutesInWork } from '../../components/Tables/TableQutesInWork';
import { CreateQuoteModal } from '../../components/CreateQuoteModal/CreateQuoteModal';
import { CustomSelection } from '../../components/CustomFields/CustomSelection';
import '../quotes.scss';
import { InputField } from '../../components/CustomFields/InputField';
import { downloadPdf, getQuotes, getTicketData } from '../../crud/quote';
import { Loader } from '../../components/Loader/Loader';
import { useNavigate } from 'react-router-dom';
import { SubmiteQuoteModal } from '../../components/SubmiteQuoteModal/SubmiteQuoteModal';

export const QuotesInWork = ({ openActions, setOpenActions }) => {
    const [formData, setFormData] = useState({
        package: '',
        status: '',
        search: '',
        ordering: '-updated_at',
    });
    const navigate = useNavigate();
    const [openModal, setOpenModal] = useState(false);
    const [offset, setOffset] = useState(1);
    const [quotesInWork, setQuotesInWork] = useState([]);
    const [page, setPage] = useState(1);
    const [countOfPages, setCountOfPages] = useState('');
    const [loading, setLoading] = useState(false);
    const viewQuote = useSelector((state) => state.quote.viewQuoteId);
    const [submitModal, setSubmitModal] = useState(false);
    const [choosenId, setChoosenId] = useState('');
    const [reload, setReload] = useState(false);
    const [subject, setSubject] = useState();

    useEffect(() => {
        if (viewQuote) {
            navigate(`/new_quote/${viewQuote}?step=1`);
        }
    }, [viewQuote]);

    useEffect(() => {
        setLoading(true);
        let offset = 10 * page - 10;
        const inWork = true;
        getQuotes(
            offset,
            formData.status.request,
            formData.package.title,
            formData.search,
            formData.ordering,
            inWork
        )
            .then((res) => {
                setQuotesInWork(res.data.results);
                let count = Math.ceil(res.data.count / 10);
                setCountOfPages(count);
            })
            .finally(() => setLoading(false));
    }, [page, formData, reload]);

    useEffect(() => {
        if (choosenId) {
            //setLoading(true);
            getTicketData(choosenId)
                .then((res) => {
                    setSubject(res.data.subject);
                })
                .finally(() => setLoading(false));
        }
    }, [choosenId]);

    return (
        <>
            <div className="quotes">
                <Loader loading={loading} />
                {submitModal && (
                    <SubmiteQuoteModal
                        setLoading={setLoading}
                        setOpenHelpModal={setSubmitModal}
                        choosenId={choosenId}
                        setReload={setReload}
                        subject={subject}
                    />
                )}
                {openModal && <CreateQuoteModal setOpenModal={setOpenModal} />}
                <Header title={'Quotes in Progress'} />
                <div className="quotes__wrapper">
                    <div className="quotes__row">
                        <div className="quotes__filters filters">
                            <div className="filters__wrap">
                                <CustomSelection
                                    arrowImage
                                    placeholder="Package"
                                    options={[
                                        { id: 0, title: 'Residential Rental' },
                                        { id: 1, title: 'Student Rental' },
                                    ]}
                                    onChange={(value) => {
                                        setFormData({
                                            ...formData,
                                            package: value,
                                        });
                                    }}
                                    value={
                                        formData.package.title
                                            ? formData.package.title
                                            : ''
                                    }
                                />
                            </div>
                            <div className="filters__wrap">
                                <CustomSelection
                                    arrowImage
                                    placeholder="Status"
                                    options={[
                                        {
                                            id: 0,
                                            title: 'In Progress',
                                            request: 'In progress',
                                        },
                                        {
                                            id: 1,
                                            title: 'Completed',
                                            request: 'Completed',
                                        },
                                        {
                                            id: 2,
                                            title: 'Canceled',
                                            request: 'Canceled',
                                        },
                                    ]}
                                    onChange={(value) => {
                                        setFormData({
                                            ...formData,
                                            status: value,
                                        });
                                    }}
                                    value={
                                        formData.status.title
                                            ? formData.status.title
                                            : ''
                                    }
                                />
                            </div>
                            <div className="filters__wrap-clear clear">
                                <div
                                    className="clear__button"
                                    onClick={() =>
                                        setFormData({
                                            package: '',
                                            status: '',
                                            search: '',
                                        })
                                    }
                                >
                                    Clear
                                </div>
                            </div>
                            <div className="filters__wrap">
                                <InputField
                                    maxLength={100}
                                    arrowImage
                                    placeholder="Search"
                                    name="search"
                                    onKeyPress={formData.search}
                                    onChange={(e) =>
                                        setFormData({
                                            ...formData,
                                            search: e.target.value,
                                        })
                                    }
                                    value={formData.search}
                                    width={'130px'}
                                    padding="12px 35px"
                                />
                                <div
                                    className="customInput__search-clear"
                                    onClick={() =>
                                        setFormData({ ...formData, search: '' })
                                    }
                                >
                                    +
                                </div>
                            </div>
                        </div>
                        <div className="quotes__addnew addnew">
                            <button
                                className="addnew__btn"
                                onClick={() => setOpenModal(true)}
                            >
                                Add new
                            </button>
                        </div>
                    </div>
                    <TableQutesInWork
                        list={quotesInWork}
                        countOfPages={countOfPages}
                        setPage={setPage}
                        page={page}
                        setFormData={setFormData}
                        formData={formData}
                        setLoading={setLoading}
                        setSubmitModal={setSubmitModal}
                        setChoosenId={setChoosenId}
                        choosenId={choosenId}
                        setReload={setReload}
                        subject={subject}
                        openActions={openActions}
                        setOpenActions={setOpenActions}
                    />
                </div>
            </div>
        </>
    );
};
