import React from 'react';
import ClipLoader from 'react-spinners/ClipLoader';
import './loader.scss';

const override = {
    display: 'block',
    margin: '0 auto',
    borderColor: '#23b0e6',
    zIndex: '500',
};

export const Loader = ({ loading }) => {
    return (
        <div className={`${loading ? 'sweet-loading' : ''}`}>
            <ClipLoader loading={loading} cssOverride={override} size={150} />
        </div>
    );
};
