import './App.css';
import { Layout } from './components/Layout/Layout';
import { Routes, Route, Navigate, useLocation } from 'react-router-dom';
import { Login } from './pages/Login/Login';
import { HomePage } from './pages/HomePage/HomePage';
import { QuotesInWork } from './pages/QuotesInWork/QuotesInWork';
import { NewQuotPage } from './pages/NewQuotPage/NewQuotPage';
import { SubmmitedQuotes } from './pages/SubmmitedQuotes/SubmmitedQuotes';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { isAuthState, userData } from './redux/auth/auth';
import { checkToken, getUserData } from './crud/auth';
import { ProtectedRoute } from './components/ProtectedRoute/ProtectedRoute';
import {
    setCurrentQuote,
    setQuotePackage,
    setViewQuoteId,
} from './redux/quote/quote';
import { PageNotFound } from './pages/PageNotFound/PageNotFound';
import { AccessDeniedPage } from './pages/AccessDeniedPage/AccessDeniedPage';
import { getCookie } from './crud/auth';

function App() {
    const [isChecked, setIsChecked] = useState(false);
    const isAuth = useSelector((state) => state.auth.isAuth);
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const location = useLocation();
    const [clearData, setClearData] = useState(false);
    const token = localStorage.getItem('token');
    const [openActions, setOpenActions] = useState();

    useEffect(() => {
        if (!location.pathname.includes('/new_quote/')) {
            dispatch(setCurrentQuote(''));
            dispatch(setViewQuoteId(''));
            dispatch(setQuotePackage(''));
            setClearData(true);
        } else {
            setClearData(false);
        }
    }, [location]);

    useEffect(() => {
        if (!location.pathname.includes('/new_quote/')) {
            dispatch(setCurrentQuote(''));
            dispatch(setViewQuoteId(''));
        } else {
            dispatch(setCurrentQuote(location.pathname.split('/')[2]));
            dispatch(setViewQuoteId(location.pathname.split('/')[2]));
        }
    }, [location]);

    useEffect(() => {
        if (isAuth) {
            setLoading(true);
            getUserData()
                .then((res) => dispatch(userData(res.data)))
                .finally(() => setLoading(false));
        }
    }, [isAuth]);

    const closeActions = (e) => {
        if (
            e.target.className !== 'row__item-popup' &&
            e.target.className !== 'row__item action' &&
            e.target.className !== 'row__item-popupitem'
        ) {
            setOpenActions('');
        }
    };

    useEffect(() => {
        const tokenFromCookie = getCookie('auth_token');
        if (tokenFromCookie) {
            checkToken().then((res) => {
                setIsChecked(true);
                dispatch(isAuthState(true));
            }).catch(error => {
                if (error.response && error.response.status === 401) {
                    // Redirect to login page if a 401 Unauthorized error occurs
                    window.location.href = "/login";
                }
            });
        } else {
            dispatch(isAuthState(false));
            setIsChecked(true);
        }
    }, []);
    

    if (!isChecked) return;

    return (
        <div onClick={closeActions}>
            <Routes>
                <Route
                    path="/page_not_found"
                    element={
                        <ProtectedRoute
                            isAuth={isAuth}
                            role="user"
                            roles={['user', 'admin']}
                            needAuth={true}
                            isChecked={isChecked}
                        >
                            <PageNotFound />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path="/access_denied"
                    element={
                        <ProtectedRoute
                            isAuth={isAuth}
                            role="user"
                            roles={['user', 'admin']}
                            needAuth={true}
                            isChecked={isChecked}
                        >
                            <AccessDeniedPage />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path="/homepage"
                    element={
                        <ProtectedRoute
                            isAuth={isAuth}
                            role="user"
                            roles={['user', 'admin']}
                            needAuth={true}
                            isChecked={isChecked}
                        >
                            <Layout activeTab="homepage">
                                <HomePage loading={loading} />
                            </Layout>
                        </ProtectedRoute>
                    }
                />
                <Route
                    path="/quotes_in_work"
                    element={
                        <ProtectedRoute
                            isAuth={isAuth}
                            role="user"
                            roles={['user', 'admin']}
                            needAuth={true}
                            isChecked={isChecked}
                        >
                            <Layout activeTab="quotes_in_work">
                                <QuotesInWork
                                    clearData={clearData}
                                    openActions={openActions}
                                    setOpenActions={setOpenActions}
                                />
                            </Layout>
                        </ProtectedRoute>
                    }
                />
                <Route
                    path="/new_quote/*"
                    element={
                        <ProtectedRoute
                            isAuth={isAuth}
                            role="user"
                            roles={['user', 'admin']}
                            needAuth={true}
                            isChecked={isChecked}
                        >
                            <Layout activeTab="quotes_in_work">
                                <NewQuotPage clearData={clearData} />
                            </Layout>
                        </ProtectedRoute>
                    }
                />

                <Route
                    path="/new_quote/:id/*"
                    element={
                        <ProtectedRoute
                            isAuth={isAuth}
                            role="user"
                            roles={['user', 'admin']}
                            needAuth={true}
                            isChecked={isChecked}
                        >
                            <Layout activeTab="quotes_in_work">
                                <NewQuotPage />
                            </Layout>
                        </ProtectedRoute>
                    }
                />
                <Route
                    path="/submitted_quo"
                    element={
                        <ProtectedRoute
                            isAuth={isAuth}
                            role="user"
                            roles={['user', 'admin']}
                            needAuth={true}
                            isChecked={isChecked}
                        >
                            <Layout activeTab="submitted_quo">
                                <SubmmitedQuotes
                                    openActions={openActions}
                                    setOpenActions={setOpenActions}
                                />
                            </Layout>
                        </ProtectedRoute>
                    }
                />

                <Route
                    path="/login/*"
                    element={
                        <ProtectedRoute
                            isAuth={isAuth}
                            role="user"
                            roles={['user', 'admin']}
                            needAuth={false}
                            isChecked={isChecked}
                        >
                            <Login />
                        </ProtectedRoute>
                    }
                />
                <Route path="/*" element={<Navigate to="/login" />} />
            </Routes>
        </div>
    );
}

export default App;
