export const CalcRental = (formData, validation, additionalCoverages) => {
    let totalPremium = 0;
    const rental_type = formData?.rental_type?.value?.id;
    const landlord_contents =
        formData?.landlord_contents?.value?.split(',')?.join('') || 0;
    const improvements_betterments =
        formData?.improvements_betterments?.value?.split(',')?.join('') || 0;
    const unit_owner_protection =
        formData?.unit_owner_protection?.value?.split(',')?.join('') || 0;
    const loss_assessment =
        formData?.loss_assessment?.value?.split(',')?.join('') || 0;
    const flood = formData?.flood?.value?.id;
    const gross_rental_income =
        formData?.gross_rental_income?.value?.split(',')?.join('') || 0;
    const liability_limit = formData?.liability_limit?.value?.title || 0;
    const deductible = formData?.deductible?.value?.title;
    const equipment_breakdown = additionalCoverages?.includes('2');
    const dwelling = formData?.dwelling?.value?.split(',')?.join('') || 0;
    const building = formData?.building?.value?.split(',')?.join('') || 0;
    const additional_building =
        formData?.additional_building?.value?.split(',')?.join('') || 0;

    if (rental_type === 1) {
        let LC_prem = 0;
        if (landlord_contents) {
            LC_prem =
                validation?.LanConts_base_prem_resid +
                (landlord_contents - validation?.LanConts_base_limit_resid) *
                    validation?.r_resid_condo_LC;
        }

        let IB_prem = 0;
        if (improvements_betterments) {
            IB_prem =
                validation?.IB_base_prem_resid +
                (improvements_betterments - validation?.IB_base_limit) *
                    validation?.r_increased_property_limits;
        }

        let LA_prem = 0;
        if (loss_assessment) {
            LA_prem =
                validation?.LA_base_prem_resid +
                (loss_assessment - validation?.LA_base_limit) *
                    validation?.r_increased_property_limits;
        }

        let UOAP_prem = 0;
        if (unit_owner_protection) {
            UOAP_prem =
                validation?.UOAP_base_prem_resid +
                (unit_owner_protection - validation?.UOAP_base_limit) *
                    validation?.r_increased_property_limits;
        }
        let SB_prem = 0;
        if (additionalCoverages.includes('1')) {
            SB_prem = validation?.SB_Prem_resid_condo;
        }

        let Flood_prem = 0;
        if (additionalCoverages.includes('2')) {
            Flood_prem = validation?.Flood_prem_resid_condo;
        }

        let Earthquake_prem = 0;
        if (additionalCoverages.includes('4')) {
            Earthquake_prem = validation?.EQ_prem;
        }

        let Crime_prem = 0;
        if (additionalCoverages.includes('6')) {
            Crime_prem = validation?.Crime_prem_resid_dwelling10000;
        }
        if (additionalCoverages.includes('7')) {
            Crime_prem = validation?.Crime_prem_resid_dwelling25000;
        }

        let GRI_prem = 0;
        if (gross_rental_income) {
            GRI_prem =
                validation?.GRI_base_prem_resid +
                (gross_rental_income - validation?.GRI_base_limit) *
                    validation?.r_resid_condo_GRI;
        }

        let EB_prem = 0;
        if (additionalCoverages.includes('3')) {
            const Prop_limits =
                +landlord_contents +
                +improvements_betterments +
                +gross_rental_income;

            if (+Prop_limits <= validation?.EB_Prem_limit1) {
                let res = +Prop_limits * validation?.R_prem_limit1;
                if (res < validation?.EB_Prem_limit0) {
                    EB_prem = validation?.EB_Prem_limit0;
                } else {
                    EB_prem = res;
                }
            }
            if (
                validation?.EB_Prem_limit1 < +Prop_limits &&
                Prop_limits <= validation?.EB_Prem_limit2
            ) {
                let res =
                    validation?.EB_Prem_limit1 * validation?.R_prem_limit1 +
                    (+Prop_limits - validation?.EB_Prem_limit1) *
                        validation?.R_prem_limit2;

                if (res < validation?.EB_Prem_limit0) {
                    EB_prem = validation?.EB_Prem_limit0;
                } else {
                    EB_prem = res;
                }
            }
            if (
                validation?.EB_Prem_limit2 < +Prop_limits &&
                Prop_limits <= validation?.EB_Prem_limit3
            ) {
                let res =
                    validation?.EB_Prem_limit1 * validation?.R_prem_limit1 +
                    (validation?.EB_Prem_limit2 - validation?.EB_Prem_limit1) *
                        validation?.R_prem_limit2 +
                    (+Prop_limits - validation?.EB_Prem_limit2) *
                        validation?.R_prem_limit3;
                if (res < validation?.EB_Prem_limit0) {
                    EB_prem = validation?.EB_Prem_limit0;
                } else {
                    EB_prem = res;
                }
            }
            if (
                validation?.EB_Prem_limit3 < +Prop_limits &&
                Prop_limits <= validation?.EB_Prem_limit4
            ) {
                let res =
                    validation?.EB_Prem_limit1 * validation?.R_prem_limit1 +
                    (validation?.EB_Prem_limit2 - validation?.EB_Prem_limit1) *
                        validation?.R_prem_limit2 +
                    (validation?.EB_Prem_limit3 - validation?.EB_Prem_limit2) *
                        validation?.R_prem_limit3 +
                    (+Prop_limits - validation?.EB_Prem_limit3) *
                        validation?.R_prem_limit4;

                if (res < validation?.EB_Prem_limit0) {
                    EB_prem = validation?.EB_Prem_limit0;
                } else {
                    EB_prem = res;
                }
            }
        }

        let Liab_prem = 0;
        if (liability_limit) {
            if (liability_limit === '$2,000,000') {
                Liab_prem = validation?.Liab_base_premium_resid_condo;
            }
            if (liability_limit === '$3,000,000') {
                Liab_prem =
                    validation?.Liab_base_premium +
                    validation?.Liab_ip1_resid_condo;
            }
            if (liability_limit === '$4,000,000') {
                Liab_prem =
                    validation?.Liab_base_premium +
                    validation?.Liab_ip2_resid_condo;
            }
            if (liability_limit === '$5,000,000') {
                Liab_prem =
                    validation?.Liab_base_premium +
                    validation?.Liab_ip3_resid_condo;
            }
        }

        let ded_disc1 = 0;
        if (!deductible) {
            ded_disc1 = 0;
        }
        if (deductible === '$1,000') {
            ded_disc1 = 0;
        }
        if (deductible === '$2,500') {
            ded_disc1 = validation?.Ded_Disc_rate_resid_condo2500;
        }
        if (deductible === '$5,000') {
            ded_disc1 = validation?.Ded_Disc_rate_resid_condo5000;
        }
        let ded_disc2 = 0;
        if (EB_prem === 0) {
            ded_disc2 = 0;
        }
        if (!deductible) {
            ded_disc2 = 0;
        }
        if (deductible === '$1,000') {
            ded_disc2 = EB_prem * validation?.EB_Prem_deductible1000;
        }
        if (deductible === '$2,500') {
            ded_disc2 = EB_prem * validation?.EB_Prem_deductible2500;
        }
        if (deductible === '$5,000') {
            ded_disc2 = EB_prem * validation?.EB_Prem_deductible5000;
        }
        let Ded_disc = ded_disc1 + ded_disc2;

        const total =
            LC_prem +
            IB_prem +
            LA_prem +
            UOAP_prem +
            SB_prem +
            Flood_prem +
            Earthquake_prem +
            Crime_prem +
            GRI_prem +
            EB_prem +
            Liab_prem -
            Ded_disc;

        if (total < validation?.Total_premium_min_resid_condo) {
            return (totalPremium = validation?.Total_premium_min_resid_condo);
        } else {
            return (totalPremium = total);
        }
    }
    if (rental_type === 0) {
        // debugger;
        let Building_prem = 0;
        if (building) {
            Building_prem = building * validation?.r_resid_dwelling_building;
        }

        let AdditionalBuilding_prem = 0;
        if (additional_building) {
            AdditionalBuilding_prem =
                additional_building * validation?.r_resid_dwelling_addbuilding;
        }

        let LC_Prem = 0;
        if (landlord_contents) {
            LC_Prem = landlord_contents * validation?.r_resid_dwelling_LC;
        }
        let SB_Prem = 0;
        if (additionalCoverages.includes('1')) {
            let res =
                (+building + +additional_building + +landlord_contents) *
                validation?.r_resid_dwelling_SB;
            if (res > validation?.SB_prem_resid_dwelling) {
                SB_Prem = res;
            } else {
                SB_Prem = validation?.SB_prem_resid_dwelling;
            }
        }

        let Flood_Prem = 0;
        if (additionalCoverages.includes('2')) {
            let res =
                (+building + +additional_building + +landlord_contents) *
                validation?.r_resid_dwelling_Flood;

            if (res > validation?.Flood_prem_resid_dwelling) {
                Flood_Prem = res;
            } else {
                Flood_Prem = validation?.Flood_prem_resid_dwelling;
            }
        }

        let Earthquake_prem = 0;
        if (additionalCoverages.includes('4')) {
            let res =
                (+building + +additional_building + +landlord_contents) *
                validation?.r_resid_dwelling_EQ;

            if (res > validation?.EQ_prem_resid_dwelling) {
                Earthquake_prem = res;
            } else {
                Earthquake_prem = validation?.EQ_prem_resid_dwelling;
            }
        }

        let Crime_Prem = 0;
        if (additionalCoverages.includes('6')) {
            Crime_Prem = validation?.Crime_prem_resid_dwelling10000;
        }
        if (additionalCoverages.includes('7')) {
            Crime_Prem = validation?.Crime_prem_resid_dwelling25000;
        }

        let GRI_Prem = 0;
        if (gross_rental_income) {
            GRI_Prem =
                validation?.r_resid_dwelling_GRI *
                validation?.r_resid_dwelling_building *
                gross_rental_income;
        }
        let EB_Prem = 0;
        if (additionalCoverages.includes('3')) {
            const Prop_limits =
                +building +
                +additional_building +
                +landlord_contents +
                +gross_rental_income;

            if (Prop_limits <= validation?.EB_Prem_limit1) {
                let res = Prop_limits * validation?.R_prem_limit1;
                if (res > validation?.EB_Prem_limit0) {
                    EB_Prem = res;
                } else {
                    EB_Prem = validation?.EB_Prem_limit0;
                }
            }
            if (
                validation?.EB_Prem_limit1 < Prop_limits &&
                Prop_limits <= validation?.EB_Prem_limit2
            ) {
                let res =
                    validation?.EB_Prem_limit1 * validation?.R_prem_limit1 +
                    (Prop_limits - validation?.EB_Prem_limit1) *
                        validation?.R_prem_limit2;

                if (res > validation?.EB_Prem_limit0) {
                    EB_Prem = res;
                } else {
                    EB_Prem = validation?.EB_Prem_limit0;
                }
            }

            if (
                validation?.EB_Prem_limit2 < Prop_limits &&
                Prop_limits <= validation?.EB_Prem_limit3
            ) {
                let res =
                    validation?.EB_Prem_limit1 * validation?.R_prem_limit1 +
                    (validation?.EB_Prem_limit2 - validation?.EB_Prem_limit1) *
                        validation?.R_prem_limit2 +
                    (Prop_limits - validation?.EB_Prem_limit2) *
                        validation?.R_prem_limit3;

                if (res > validation?.EB_Prem_limit0) {
                    EB_Prem = res;
                } else {
                    EB_Prem = validation?.EB_Prem_limit0;
                }
            }

            if (
                validation?.EB_Prem_limit3 < Prop_limits &&
                Prop_limits <= validation?.EB_Prem_limit4
            ) {
                let res =
                    validation?.EB_Prem_limit1 * validation?.R_prem_limit1 +
                    (validation?.EB_Prem_limit2 - validation?.EB_Prem_limit1) *
                        validation?.R_prem_limit2 +
                    (validation?.EB_Prem_limit3 - validation?.EB_Prem_limit2) *
                        validation?.R_prem_limit3 +
                    (Prop_limits - validation?.EB_Prem_limit3) *
                        validation?.R_prem_limit4;

                if (res > validation?.EB_Prem_limit0) {
                    EB_Prem = res;
                } else {
                    EB_Prem = validation?.EB_Prem_limit0;
                }
            }
        }

        let Liab_Prem = 0;
        if (liability_limit) {
            if (liability_limit === '$2,000,000') {
                Liab_Prem = validation?.Liab_base_premium_resid_dwelling;
            }
            if (liability_limit === '$3,000,000') {
                Liab_Prem =
                    validation?.Liab_base_premium +
                    validation?.Liab_ip1_resid_dwelling;
            }
            if (liability_limit === '$4,000,000') {
                Liab_Prem =
                    validation?.Liab_base_premium +
                    validation?.Liab_ip2_resid_dwelling;
            }
            if (liability_limit === '$5,000,000') {
                Liab_Prem =
                    validation?.Liab_base_premium +
                    validation?.Liab_ip3_resid_dwelling;
            }
        }

        let ded_disc1 = 0;
        if (!deductible) {
            ded_disc1 = 0;
        }
        if (deductible === '$1,000') {
            ded_disc1 = 0;
        }
        if (deductible === '$2,500') {
            ded_disc1 =
                (Building_prem + AdditionalBuilding_prem + LC_Prem + GRI_Prem) *
                validation?.Ded_Disc_rate2500;
        }
        if (deductible === '$5,000') {
            ded_disc1 =
                (Building_prem + AdditionalBuilding_prem + LC_Prem + GRI_Prem) *
                validation?.Ded_Disc_rate5000;
        }
        let ded_disc2 = 0;
        if (EB_Prem === 0) {
            ded_disc2 = 0;
        }
        if (!deductible) {
            ded_disc2 = 0;
        }
        if (deductible === '$1,000') {
            ded_disc2 = EB_Prem * validation?.EB_Prem_deductible1000;
        }
        if (deductible === '$2,500') {
            ded_disc2 = EB_Prem * validation?.EB_Prem_deductible2500;
        }
        if (deductible === '$5,000') {
            ded_disc2 = EB_Prem * validation?.EB_Prem_deductible5000;
        }
        let Ded_disc = ded_disc1 + ded_disc2;

        const total =
            Building_prem +
            AdditionalBuilding_prem +
            LC_Prem +
            SB_Prem +
            Flood_Prem +
            Earthquake_prem +
            Crime_Prem +
            GRI_Prem +
            EB_Prem +
            Liab_Prem -
            Ded_disc;

        if (total < validation?.Total_premium_min_resid_dwelling) {
            return (totalPremium =
                validation?.Total_premium_min_resid_dwelling);
        } else {
            return (totalPremium = total);
        }
    }

    return totalPremium;
};
