import React from 'react';
import './custom-fields.scss';

export const CheckboxContainer = ({ title, checked, onChange, disabled }) => {
    return (
        <>
            <div className="checkbox-container">
                <input
                    disabled={disabled}
                    className="checkbox-container__checkbox"
                    type="checkbox"
                    style={{ cursor: disabled ? 'context-menu' : 'pointer' }}
                    checked={checked}
                    onChange={onChange}
                />
                <div
                    className={`checkbox-container__title ${
                        disabled ? 'disabled' : ''
                    }`}
                >
                    {title}
                </div>
            </div>
        </>
    );
};
