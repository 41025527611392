import React, { useState } from 'react';
import error from '../../assets/images/error-image.png';
import searchImage from '../../assets/images/search.png';
import './custom-fields.scss';

export const InputField = ({
    disabled,
    className,
    name,
    onChange,
    value,
    title,
    placeholder,
    onBlur = () => {},
    maxLength,
    image,
    errorText,
    type,
    height,
    max,
    width,
    padding,
    classForWrap,
    widthForTitle,
    smallTitle,
    dollar = false,
    smalldollar = false,
}) => {
    const [onFocus, setOnFocus] = useState(false);
    return (
        <>
            {title && (
                <div
                    style={{ width: widthForTitle }}
                    className={`custom-field-title ${
                        disabled ? 'disabled' : ''
                    }`}
                >
                    {title}
                </div>
            )}
            {smallTitle && (
                <div className={`custom-field-smalltitle`}>{smallTitle}</div>
            )}

            <div className={`custom-field ${classForWrap}`}>
                {dollar && value && (
                    <div
                        className={`custom-field__dollar ${
                            disabled ? 'disabled' : ''
                        }`}
                    >
                        $
                    </div>
                )}
                {smalldollar && value && (
                    <div
                        className={`custom-field__smalldollar ${
                            disabled ? 'disabled' : ''
                        }`}
                    >
                        $
                    </div>
                )}
                <input
                    disabled={disabled}
                    className={`${className} custom-field__input ${
                        image ? 'inputWithImage' : ''
                    } ${errorText ? 'red' : ''} ${
                        name === 'search' ? 'search' : ''
                    } ${dollar || smalldollar ? 'dollar' : ''}`}
                    style={{
                        background:
                            name === 'search'
                                ? onFocus || value
                                    ? 'white'
                                    : '#EEF5FF'
                                : onFocus
                                ? '#EEF5FF'
                                : 'white',
                        height: height,
                        width: width,
                        padding: padding,
                        color: disabled ? '#8e949a' : '',
                        cursor: disabled ? 'context-menu' : '',
                    }}
                    type={type}
                    name={name}
                    onChange={onChange}
                    value={value}
                    placeholder={onFocus ? '' : placeholder}
                    onBlur={() => {
                        setOnFocus(false);
                        onBlur();
                    }}
                    onFocus={() => setOnFocus(true)}
                    maxLength={maxLength}
                    max={max}
                />
                {image && <img src={image} className="custom-field__image" />}
                {errorText && (
                    <>
                        <div className="errortext">
                            <img className="errortext__img" src={error} />
                            <div className="errortext__text">{errorText}</div>
                        </div>
                    </>
                )}
                {name === 'search' && (
                    <img
                        className="custom-field__searchImage"
                        src={searchImage}
                    />
                )}
            </div>
        </>
    );
};
