import axios from 'axios';
import { getCookie } from './auth';

const BASE_URL = process.env.REACT_APP_BASE_URL;

const service = axios.create({
    baseURL: BASE_URL,
    headers: {
        'Content-Type': 'application/json',
        Authorization: `token ${getCookie('auth_token')}`,
    },
});

service.interceptors.request.use(
    (request) => {
        const tokenFromCookie = getCookie('auth_token');
        if (tokenFromCookie) {
            request.headers.Authorization = `token ${tokenFromCookie}`;
        }
        return request;
    },
    (error) => {
        return error;
    }
);


service.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        if (error.response?.status) {
            const { status } = error.response;

            if (status === 401 && window.location.pathname !== '/login') {
                localStorage.removeItem('token');
            }
        }

        return Promise.reject(error);
    }
);

export default service;
