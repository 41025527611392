import React, { useState } from 'react';
import { CheckboxContainer } from '../CustomFields/CheckboxContainer';
import { CustomSelection } from '../CustomFields/CustomSelection';
import { InputField } from '../CustomFields/InputField';
import { TotalPremium } from '../TotalPremium/TotalPremium';
import { onInputChange, fillFormAction } from '../../helpers/formUtils';
import './steps.scss';
import { MultiSelect } from '../CustomFields/MultiSelect';
import { CalcRental } from '../../helpers/AdditionalFunctions/calcFunctions/CalcRental';
import { useEffect } from 'react';
import { validateWithConstRental } from '../../helpers/AdditionalFunctions/validate/validateWithConstRental';
import { setIsChanged } from '../../redux/quote/quote';
import { useDispatch, useSelector } from 'react-redux';

export const SecondStepRental = ({
    formData,
    setFormData,
    totalPremium,
    setTotalPremium,
    validation,
    additionalCoverages,
    setAdditionalCoverages,
    setAddCovError,
    addCovError,
    step,
    fillFormFinished,
    checkCrime,
    setCheckCrime,
}) => {
    const dispatch = useDispatch();
    const [tooltip, setTooltip] = useState(false);
    const [options, setOptions] = useState([
        { id: '0', title: ' Water damage deductible', type: 'checkbox' },
        { id: '1', title: ' Sewer Back-up', type: 'checkbox' },
        { id: '2', title: ' Flood', type: 'checkbox' },
        { id: '3', title: ' Equipment Breakdown', type: 'checkbox' },
        { id: '4', title: ' Earthquake', type: 'checkbox' },
        {
            id: '5',
            title: ' Stated Amount - Waiver of Coinsurance  Endorsement',
            type: 'checkbox',
        },
        { id: '', title: ' Crime', type: 'checkbox' },
        { id: '6', title: '3D Crime: $10000', type: 'radio' },
        { id: '7', title: '3D Crime: $25000', type: 'radio' },
    ]);

    useEffect(() => {
        if (checkCrime) {
            let data = [...additionalCoverages];
            if (!data.includes('6') && !data.includes('7')) {
                data.push('6');
            }
            setAdditionalCoverages(data);
        }
    }, [checkCrime]);

    useEffect(() => {
        if (validation) {
            const res = CalcRental(formData, validation, additionalCoverages);
            setTotalPremium(res);
        }
    }, [formData, additionalCoverages, validation]);

    const currencyMask = (e) => {
        let value = e.target.value;
        value = value.replace(/\D/g, '');
        // value = value.replace(/(\d)(\d{2})$/, '$1.$2');
        value = value.replace(/(?=(\d{3})+(\D))\B/g, ',');
        e.target.value = value;
        return e.target.value;
    };

    useEffect(() => {
        if (!checkCrime) {
            const data = [...additionalCoverages];
            if (additionalCoverages.find((elem) => elem === '6')) {
                let index = additionalCoverages.findIndex(
                    (elem) => elem === '6'
                );
                data.splice(index, 1);

                setAdditionalCoverages(data);
            }
            if (additionalCoverages.find((elem) => elem === '7')) {
                let index = additionalCoverages.findIndex(
                    (elem) => elem === '7'
                );
                data.splice(index, 1);
                setAdditionalCoverages(data);
            }
        }
    }, [checkCrime]);

    const handalSelect = (value) => {
        if ((value === '6' || value === '7') && checkCrime) {
            if (value === '6') {
                if (additionalCoverages.find((elem) => elem === '7')) {
                    const data = [...additionalCoverages];
                    const res = data.filter((elem) => elem !== '7');
                    res.push('6');
                    setAdditionalCoverages(res);
                } else {
                    let data = [...additionalCoverages];
                    data.push(value);
                    setAdditionalCoverages(data);
                }
            }
            if (value === '7') {
                if (additionalCoverages.find((elem) => elem === '6')) {
                    const data = [...additionalCoverages];
                    const res = data.filter((elem) => elem !== '6');
                    res.push('7');
                    setAdditionalCoverages(res);
                } else {
                    let data = [...additionalCoverages];
                    data.push(value);
                    setAdditionalCoverages(data);
                }
            }
        } else {
            if (additionalCoverages.find((elem) => elem + '' === value)) {
                const data = [...additionalCoverages];
                const res = data.filter((elem) => elem !== value);
                setAdditionalCoverages(res);
            } else {
                let data = [...additionalCoverages];
                data.push(value);
                setAdditionalCoverages(data);
            }
        }
    };

    useEffect(() => {
        if (
            additionalCoverages.includes('2') &&
            formData.flood.value.id === 1
        ) {
            setAddCovError(`Please check flood eligibility`);
        } else if (additionalCoverages.includes('3')) {
            if (formData.rental_type.value.id === 0) {
                const landlord_contents =
                    formData?.landlord_contents?.value?.split(',')?.join('') ||
                    0;
                const additional_building =
                    formData?.additional_building?.value
                        ?.split(',')
                        ?.join('') || 0;
                const gross_rental_income =
                    formData?.gross_rental_income?.value
                        ?.split(',')
                        ?.join('') || 0;
                const building =
                    formData?.building?.value?.split(',')?.join('') || 0;
                const prop_limits =
                    +landlord_contents +
                    +additional_building +
                    +gross_rental_income +
                    +building;
                if (prop_limits > validation?.EB_Prem_limit4) {
                    setAddCovError(
                        `Property limits should not exceed $${validation?.EB_Prem_limit4}`
                    );
                } else {
                    setAddCovError('');
                }
            }

            if (formData.rental_type.value.id === 1) {
                const landlord_contents =
                    formData?.landlord_contents?.value?.split(',')?.join('') ||
                    0;
                const improvements_betterments =
                    formData?.improvements_betterments?.value
                        ?.split(',')
                        ?.join('') || 0;
                const gross_rental_income =
                    formData?.gross_rental_income?.value
                        ?.split(',')
                        ?.join('') || 0;
                const prop_limits =
                    +landlord_contents +
                    +improvements_betterments +
                    +gross_rental_income;
                if (prop_limits > validation?.EB_Prem_limit4) {
                    setAddCovError(
                        `Property limits should not exceed $${validation?.EB_Prem_limit4}`
                    );
                } else {
                    setAddCovError('');
                }
            }
        } else {
            setAddCovError('');
        }
    }, [additionalCoverages, formData]);

    // call validateWithConstRental function when fields are updated
    useEffect(() => {
        if (fillFormFinished) {
            validateWithConstRental(
                step,
                formData,
                setFormData,
                fillFormAction,
                validation
            );
        }
    }, [
        formData.rental_type.value.id,
        formData.dwelling,
        formData.landlord_contents,
        formData.gross_rental_income,
        fillFormFinished,
    ]);

    return (
        <>
            <div className="step-wrapper">
                <div className="step-wrapper__row">
                    <div className="step-wrapper__select-container">
                        <CustomSelection
                            type="text"
                            title={
                                <div>
                                    Rental type:{' '}
                                    <span className="mandatory">*</span>
                                </div>
                            }
                            placeholder={'Select answer'}
                            arrowImage
                            options={[
                                { id: 0, title: 'Dwelling' },
                                { id: 1, title: 'Condominium' },
                            ]}
                            onChange={(value) => {
                                dispatch(setIsChanged(true));
                                onInputChange(
                                    'rental_type',
                                    value,
                                    setFormData,
                                    formData,
                                    validation
                                );
                            }}
                            value={formData.rental_type.value.title}
                            errorText={
                                formData.rental_type.hasError &&
                                'This field is required'
                            }
                        />
                    </div>
                    <div className="step-wrapper__select-container">
                        <CustomSelection
                            name="Deductible"
                            arrowImage
                            placeholder={'Select answer'}
                            background
                            title={
                                <div>
                                    Deductible:{' '}
                                    <span className="mandatory">*</span>
                                </div>
                            }
                            options={[
                                { id: 0, title: '$1,000' },
                                { id: 1, title: '$2,500' },
                                { id: 2, title: '$5,000' },
                            ]}
                            onChange={(value) => {
                                dispatch(setIsChanged(true));
                                onInputChange(
                                    'deductible',
                                    value,
                                    setFormData,
                                    formData,
                                    validation
                                );
                            }}
                            value={formData.deductible.value.title}
                            errorText={
                                formData.deductible.hasError &&
                                'This field is required'
                            }
                        />
                    </div>
                    <div className="step-wrapper__select-container"></div>
                </div>

                <div
                    className={`step-wrapper__subtitle ${formData.improvements_betterments.disabled
                            ? 'disabled'
                            : ''
                        }`}
                    style={
                        formData.improvements_betterments.disabled
                            ? { display: 'none' }
                            : {}
                    }
                >
                    Condominium
                </div>

                <div
                    className="step-wrapper__row"
                    style={
                        formData.improvements_betterments.disabled
                            ? { display: 'none' }
                            : {}
                    }
                >
                    <div className="step-wrapper__select-container">
                        <InputField
                            dollar={true}
                            title={
                                <div>
                                    Improvemets & Betterments:{' '}
                                    {formData.rental_type.value.id === 1 && (
                                        <span className="mandatory">*</span>
                                    )}
                                </div>
                            }
                            disabled={
                                formData.improvements_betterments.disabled
                                    ? true
                                    : false
                            }
                            placeholder="Type your answer"
                            type="text"
                            height="16px"
                            value={formData.improvements_betterments.value
                                ?.toString()
                                .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                            errorText={
                                formData.improvements_betterments.hasError &&
                                formData.improvements_betterments.error
                            }
                            onChange={(e) => {
                                dispatch(setIsChanged(true));
                                onInputChange(
                                    'improvements_betterments',
                                    currencyMask(e),
                                    setFormData,
                                    formData,
                                    validation
                                );
                            }}
                            maxLength={21}
                        />
                    </div>
                    <div className="step-wrapper__select-container">
                        <InputField
                            dollar={true}
                            title={
                                <div>
                                    Loss Assessment:{' '}
                                    {formData.rental_type.value.id === 1 && (
                                        <span className="mandatory">*</span>
                                    )}
                                </div>
                            }
                            disabled={
                                formData.loss_assessment.disabled ? true : false
                            }
                            placeholder="Type your answer"
                            type="text"
                            height="16px"
                            value={formData.loss_assessment.value
                                ?.toString()
                                .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                            errorText={
                                formData.loss_assessment.hasError &&
                                formData.loss_assessment.error
                            }
                            onChange={(e) => {
                                dispatch(setIsChanged(true));
                                onInputChange(
                                    'loss_assessment',
                                    currencyMask(e),
                                    setFormData,
                                    formData,
                                    validation
                                );
                            }}
                            maxLength={21}
                        />
                    </div>
                    <div className="step-wrapper__select-container">
                        <InputField
                            dollar={true}
                            disabled={
                                formData.unit_owner_protection.disabled
                                    ? true
                                    : false
                            }
                            title={
                                <div>
                                    Unit Owner Protection:{' '}
                                    {formData.rental_type.value.id === 1 && (
                                        <span className="mandatory">*</span>
                                    )}
                                </div>
                            }
                            placeholder="Type your answer"
                            type="text"
                            height="16px"
                            value={formData.unit_owner_protection.value
                                ?.toString()
                                .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                            errorText={
                                formData.unit_owner_protection.hasError &&
                                formData.unit_owner_protection.error
                            }
                            onChange={(e) => {
                                dispatch(setIsChanged(true));
                                onInputChange(
                                    'unit_owner_protection',
                                    currencyMask(e),
                                    setFormData,
                                    formData,
                                    validation
                                );
                            }}
                            maxLength={21}
                        />
                    </div>
                </div>
                <div
                    className={`step-wrapper__subtitle ${formData.additional_building.disabled ? 'disabled' : ''
                        }`}
                    style={
                        formData.additional_building.disabled
                            ? { display: 'none' }
                            : {}
                    }
                >
                    Dwelling
                </div>
                <div
                    className="step-wrapper__row"
                    style={
                        formData.additional_building.disabled
                            ? { display: 'none' }
                            : {}
                    }
                >
                    <div className="step-wrapper__select-container">
                        <InputField
                            dollar={true}
                            disabled={formData.building.disabled ? true : false}
                            title={
                                <div>
                                    Building:{' '}
                                    {formData.rental_type.value.id === 0 && (
                                        <span className="mandatory">*</span>
                                    )}
                                </div>
                            }
                            placeholder="Type your answer"
                            type="text"
                            height="16px"
                            value={formData.building.value
                                ?.toString()
                                .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                            errorText={
                                formData.building.hasError &&
                                formData.building.error
                            }
                            onChange={(e) => {
                                dispatch(setIsChanged(true));
                                onInputChange(
                                    'building',
                                    currencyMask(e),
                                    setFormData,
                                    formData,
                                    validation
                                );
                            }}
                            maxLength={21}
                        />
                    </div>
                    <div className="step-wrapper__select-container">
                        <InputField
                            dollar={true}
                            disabled={
                                formData.additional_building.disabled
                                    ? true
                                    : false
                            }
                            title={<div>Additional Building:</div>}
                            placeholder="Type your answer"
                            type="text"
                            height="16px"
                            value={formData.additional_building.value
                                ?.toString()
                                .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                            errorText={
                                formData.additional_building.hasError &&
                                formData.additional_building.error
                            }
                            onChange={(e) => {
                                dispatch(setIsChanged(true));
                                onInputChange(
                                    'additional_building',
                                    currencyMask(e),
                                    setFormData,
                                    formData,
                                    validation
                                );
                            }}
                            maxLength={21}
                        />
                    </div>
                    <div className="step-wrapper__select-container"></div>
                </div>
                <div className="step-wrapper__subtitle">Additional Limits</div>
                <div className="step-wrapper__row">
                    <div className="step-wrapper__select-container">
                        <InputField
                            dollar={true}
                            title={
                                <div>
                                    Landlord Contents:{' '}
                                    {formData.rental_type.value.id === 1 ? (
                                        <span className="mandatory">*</span>
                                    ) : (
                                        ''
                                    )}
                                </div>
                            }
                            placeholder="Type your answer"
                            type="text"
                            height="16px"
                            value={formData.landlord_contents.value
                                ?.toString()
                                .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                            errorText={
                                formData.landlord_contents.hasError &&
                                formData.landlord_contents.error
                            }
                            onChange={(e) => {
                                dispatch(setIsChanged(true));
                                onInputChange(
                                    'landlord_contents',
                                    currencyMask(e),
                                    setFormData,
                                    formData,
                                    validation
                                );
                            }}
                            maxLength={21}
                        />
                    </div>
                    <div className="step-wrapper__select-container">
                        <InputField
                            dollar={true}
                            title={
                                <div>
                                    Gross Rental Income:{' '}
                                    {formData.rental_type.value.id === 1 ? (
                                        <span className="mandatory">*</span>
                                    ) : (
                                        ''
                                    )}
                                </div>
                            }
                            placeholder="Type your answer"
                            type="text"
                            height="16px"
                            value={formData.gross_rental_income.value
                                ?.toString()
                                .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                            errorText={
                                formData.gross_rental_income.hasError &&
                                formData.gross_rental_income.error
                            }
                            onChange={(e) => {
                                dispatch(setIsChanged(true));
                                onInputChange(
                                    'gross_rental_income',
                                    currencyMask(e),
                                    setFormData,
                                    formData,
                                    validation
                                );
                            }}
                            maxLength={21}
                        />
                    </div>
                    <div className="step-wrapper__select-container">
                        <CustomSelection
                            title={
                                <div>
                                    Liability Limit:{' '}
                                    <span className="mandatory">*</span>
                                </div>
                            }
                            arrowImage
                            placeholder={'Select answer'}
                            background
                            options={[
                                { id: 0, title: '$2,000,000' },
                                { id: 1, title: '$3,000,000' },
                                { id: 2, title: '$4,000,000' },
                                { id: 3, title: '$5,000,000' },
                            ]}
                            onChange={(value) => {
                                dispatch(setIsChanged(true));
                                onInputChange(
                                    'liability_limit',
                                    value,
                                    setFormData,
                                    formData,
                                    validation
                                );
                            }}
                            value={formData.liability_limit.value.title}
                            errorText={
                                formData.liability_limit.hasError &&
                                'This field is required'
                            }
                        />
                    </div>
                </div>

                <div
                    className="step-wrapper__subtitle"
                    style={{ padding: '0 0 20px 0' }}
                >
                    Additional Coverages
                </div>

                <div className="step-wrapper__row">
                    <div className="step-wrapper__select-container">
                        <MultiSelect
                            arrowImage
                            placeholder={'Select multiple answers'}
                            background
                            title={
                                <div>
                                    Additional Coverages <br /> (if required):
                                </div>
                            }
                            onChange={(value) => {
                                dispatch(setIsChanged(true));
                                handalSelect(value);
                            }}
                            formData={additionalCoverages}
                            checkCrime={checkCrime}
                            setCheckCrime={setCheckCrime}
                            options={options}
                            value={options
                                .map((elem) => {
                                    if (
                                        additionalCoverages.includes(
                                            elem.id + ''
                                        )
                                    ) {
                                        return `${elem.title},`;
                                    }
                                })
                                .join('')}
                            errorText={addCovError}
                        />
                    </div>
                    <div className="step-wrapper__select-container" style={additionalCoverages.includes('2') ? {} : { display: 'none' }}>
                        <CustomSelection
                            arrowImage
                            placeholder={'Select answer'}
                            background
                            title={
                                <div>
                                    <div>
                                        Flood (Is this risk eligible{' '}
                                        {additionalCoverages.includes('2') && (
                                            <span className="mandatory">*</span>
                                        )}
                                        <br /> for coverage):{' '}
                                        <span
                                            className="question-mark"
                                            onMouseOver={() => setTooltip(true)}
                                            onMouseLeave={() =>
                                                setTooltip(false)
                                            }
                                        >
                                            ?
                                        </span>
                                    </div>{' '}
                                </div>
                            }
                            options={[
                                { id: 0, title: 'Yes' },
                                { id: 1, title: 'No' },
                            ]}
                            onChange={(value) => {
                                dispatch(setIsChanged(true));
                                onInputChange(
                                    'flood',
                                    value,
                                    setFormData,
                                    formData,
                                    validation
                                );
                            }}
                            value={formData.flood.value.title}
                            errorText={
                                formData.flood.hasError && formData.flood.error
                            }
                        />
                        {tooltip && (
                            <div className="tooltip">
                                <ul>
                                    Ineligible if:
                                    <li>Past flood claims at the location</li>
                                    <li>Location is in a known flood plain </li>
                                    <li>
                                        Location is within 500m of any body of
                                        water{' '}
                                    </li>
                                    <li>
                                        Location is located below grade level{' '}
                                    </li>
                                </ul>
                            </div>
                        )}
                    </div>

                    <div className="step-wrapper__select-container"></div>
                </div>
                <div className="step-wrapper">
                    <TotalPremium
                        total={totalPremium}
                        addCovError={addCovError}
                    />
                </div>
            </div>
        </>
    );
};
