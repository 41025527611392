import React, { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import arrow from '../../assets/images/arrowprofile.png';
import { checkToken, logout } from '../../crud/auth';
import { isAuthState } from '../../redux/auth/auth';
import { Loader } from '../Loader/Loader';
import { useClickOutside } from '../../helpers/useClickOutside';
import logoutGrey from '../../assets/images/logout-grey.png';
import logoutBlue from '../../assets/images/logout-blue.png';
import './header.scss';
import { AreYouSureModal } from '../AreYouSureModal/AreYouSureModal';
import { useLocation } from 'react-router-dom';
import { setIsChanged } from '../../redux/quote/quote';
import Logo from '../../assets/images/Logo.png';

export const Header = ({ title }) => {
    const [isOpenProfileInfo, setIsOpenProfileInfo] = useState(false);
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const ref = useRef();
    const [hover, setHover] = useState('');
    const [openModal, setOpenModal] = useState(false);
    const userData = useSelector((state) => state.auth.userData);
    const isChanged = useSelector((state) => state.quote.isChanged);
    const location = useLocation();
    const [isOpenConfirmModal, setIsOpenConfirmModal] = useState(false);

    useClickOutside(ref, () => setIsOpenProfileInfo(false));

    const logoutRequest = () => {
        setLoading(true);
        logout().then((res) => {
            localStorage.removeItem('token');
            dispatch(isAuthState(false));
        });
    };
    return (
        <>
            <div className="header">
                <Loader loading={loading} />
                {openModal && (
                    <AreYouSureModal
                        cancel={() => setOpenModal(false)}
                        submit={() => logoutRequest()}
                        title="Are you sure you want to log out?"
                    />
                )}
                {isOpenConfirmModal && (
                    <AreYouSureModal
                        cancel={() => {
                            setIsOpenConfirmModal(false);
                        }}
                        submit={() => {
                            setIsOpenConfirmModal(false);
                            dispatch(setIsChanged(false));
                            logoutRequest();
                        }}
                        title="You have unsaved changes. Are you sure you want to log out?"
                    />
                )}
                <div className="header__header">
                    {(location.pathname.includes('/page_not_found') ||
                        location.pathname.includes('/access_denied')) && (
                        <img src={Logo} style={{ width: '205px' }} />
                    )}
                    <div className="header__title">{title}</div>
                    <div className="header__profile" ref={ref}>
                        <div className="header__username">{userData.name}</div>
                        <div
                            style={{
                                display: 'flex',
                                cursor: 'pointer',
                                alignItems: 'center',
                            }}
                            onClick={() =>
                                setIsOpenProfileInfo((prev) => !prev)
                            }
                        >
                            <div className="header__circle">
                                {userData.content_url ? (
                                    <img
                                        src={userData.content_url}
                                        className="header__avatar"
                                    />
                                ) : (
                                    ''
                                )}
                            </div>
                            <img src={arrow} className="header__arrow" />
                        </div>
                        {isOpenProfileInfo && (
                            <div
                                className={`header__profile-info ${
                                    hover === 'hover' ? 'hover' : ''
                                }`}
                                onMouseOver={() => setHover('hover')}
                                onMouseLeave={() => setHover('')}
                            >
                                <div className="header__dropdown-option"
                                    onClick={() => window.open('https://halwell.zendesk.com/hc/en-us/requests', '_blank')}
                                >
                                    <div className="header__option-text">
                                        <i className="fas fa-external-link-alt icon-margin" aria-hidden="true"></i>
                                        View Pending Tickets
                                    </div>
                                </div>
                                <div className="header__dropdown-option"
                                    onClick={() => window.open('https://halwell.zendesk.com/hc/en-us', '_blank')}
                                >
                                    <div className="header__option-text">
                                        <i className="fas fa-external-link-alt icon-margin" aria-hidden="true"></i>
                                        Broker Portal
                                    </div>
                                </div>
                                <div className="header__dropdown-option"
                                    onClick={() => {
                                        if (
                                            isChanged &&
                                            location.pathname.includes('new_quote')
                                        ) {
                                            setIsOpenConfirmModal(true);
                                        } else {
                                            setOpenModal(true);
                                        }
                                    }}
                                >

                                    <div className="header__option-text">
                                        <i className="fas fa-door-open icon-margin" aria-hidden="true"></i>
                                        Log Out
                                    </div>
                                </div>
                            </div>
                        )}

                    </div>
                </div>
            </div>
        </>
    );
};
