import { getQuote } from '../../crud/quote';

export const fillForm = (
    viewQuote,
    setLoading,
    dispatch,
    setListOfLooses,
    setQuotePackage,
    fillFormAction,
    setFormData2Student,
    setFormData2Residental,
    setFormData3,
    setFormData1,
    setAdditionalCoverages,
    setPastSteps,
    setFillFormFinished,
    setFormData4,
    setCheckCrime,
    navigate
) => {
    setLoading(true);
    getQuote(viewQuote)
        .then((res) => {
            const data = res.data;
            let list = [];
            if (data.step) {
                setPastSteps(data.step);
            }

            data.list_looses.length < 1
                ? list.push({
                      description: { value: '', error: '', hasError: false },
                      date: { value: '', error: '', hasError: false },
                      amount_paid: { value: '', error: '', hasError: false },
                      is_open: { value: '', error: '', hasError: false },
                      localId: Math.random() + '',
                  })
                : data.list_looses.forEach((elem, index) => {
                      list[index] = {
                          date: {
                              value: elem.date ? new Date(elem.date) : '',
                              error: '',
                              hasError: false,
                          },
                          description: {
                              value: elem.description,
                              error: '',
                              hasError: false,
                          },
                          amount_paid: {
                              value: elem.amount_paid
                                  ?.toString()
                                  ?.replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                              error: '',
                              hasError: false,
                          },
                          is_open: {
                              value:
                                  elem.is_open === null
                                      ? {
                                            id: '',
                                            title: '',
                                        }
                                      : {
                                            id: elem.is_open ? 0 : 1,
                                            title: elem.is_open
                                                ? 'Open'
                                                : 'Closed',
                                        },
                              error: '',
                              hasError: false,
                          },
                          id: elem.id,
                          localId: Math.random() + '',
                      };
                  });
            setListOfLooses(list);
            dispatch(setQuotePackage({ title: data.package }));
            setFormData1(
                fillFormAction({
                    agree_risk: data.agree_risk,
                    location_occupied: data.location_occupied,
                    more_than_2_mortgages: data.more_than_2_mortgages,
                    mortgages_through_traditional_lenders:
                        data.mortgages_through_traditional_lenders,
                })
            );
            if (data.package === 'Student Rental') {
                const NUMBER_OF_STUDENTS_CHOICES = {
                    0: '1-5',
                    1: '6-10',
                };
                const RENTAL_TYPE_CHOICES = {
                    0: 'Dwelling',
                    1: 'Condominium',
                };

                const DEDUCTIBLE_CHOICES = {
                    0: '$1,000',
                    1: '$2,500',
                    2: '$5,000',
                };

                const SEWER_BACKUP_CHOICES = {
                    0: '$10,000',
                    1: '$25,000',
                    2: '$50,000',
                };

                const LIABILITY_LIMIT_CHOICES = {
                    0: '$2,000,000',
                    1: '$3,000,000',
                    2: '$4,000,000',
                    3: '$5,000,000',
                };

                setFormData2Student(
                    fillFormAction({
                        number_of_students: {
                            value: {
                                id: data.number_of_students,
                                title: NUMBER_OF_STUDENTS_CHOICES[
                                    data.number_of_students
                                ],
                            },
                        },
                        sewer_back_up: {
                            value: {
                                id: data.sewer_back_up,
                                title: SEWER_BACKUP_CHOICES[data.sewer_back_up],
                            },
                        },
                        rental_type: {
                            value: {
                                id: data.rental_type,
                                title: RENTAL_TYPE_CHOICES[data.rental_type],
                            },
                        },
                        deductible: {
                            value: {
                                id: data.deductible ? data.deductible : 0,
                                title: DEDUCTIBLE_CHOICES[data.deductible]
                                    ? DEDUCTIBLE_CHOICES[data.deductible]
                                    : '$1,000',
                            },
                        },
                        landlord_contents: {
                            value: data.landlord_contents
                                ? data.landlord_contents
                                      ?.toString()
                                      ?.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                                : '10,000',
                        },
                        gross_rental_income: {
                            value: data.gross_rental_income
                                ? data.gross_rental_income
                                      ?.toString()
                                      ?.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                                : '25000',
                        },
                        liability_limit: {
                            value: {
                                id: data.liability_limit
                                    ? data.liability_limit
                                    : 0,
                                title: LIABILITY_LIMIT_CHOICES[
                                    data.liability_limit
                                ]
                                    ? LIABILITY_LIMIT_CHOICES[
                                          data.liability_limit
                                      ]
                                    : '$2,000,000',
                            },
                        },
                        improvements_betterments: {
                            value: data.improvements_betterments
                                ? data.improvements_betterments
                                      ?.toString()
                                      ?.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                                : '100000',
                        },
                        loss_assessment: {
                            value: data.loss_assessment
                                ? data.loss_assessment
                                      ?.toString()
                                      ?.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                                : '25,000',
                        },
                        unit_owner_protection: {
                            value: data.unit_owner_protection
                                ? data.unit_owner_protection
                                      ?.toString()
                                      ?.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                                : '25000',
                        },
                        additional_building: {
                            value: data.additional_building
                                ?.toString()
                                ?.replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                        },
                        dwelling: {
                            value: data.dwelling
                                ?.toString()
                                ?.replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                        },
                        location_three_or_more_years: {
                            value: data.location_three_or_more_years
                                ? data.location_three_or_more_years
                                : '',
                        },
                        building_past_three_years: {
                            value: data.building_past_three_years
                                ? data.building_past_three_years
                                : '',
                        },
                        dwelling_age: {
                            value: data.dwelling_age ? data.dwelling_age : '',
                        },
                        certified_rental_building: {
                            value: data.certified_rental_building
                                ? data.certified_rental_building
                                : '',
                        },
                        customer_more_three_year: {
                            value: data.customer_more_three_year
                                ? data.customer_more_three_year
                                : '',
                        },
                        other_policy_with_halwell: {
                            value: data.other_policy_with_halwell
                                ? data.other_policy_with_halwell
                                : '',
                        },
                        flood: {
                            value:
                                data.flood === true
                                    ? { id: 0, title: 'Yes' }
                                    : data.flood === false
                                    ? { id: 1, title: 'No' }
                                    : '',
                        },
                    })
                );
                setAdditionalCoverages(
                    data.additional_coverages.map((elem) => elem + '')
                );
            }
            if (data.package === 'Residential Rental') {
                const RENTAL_TYPE_CHOICES = {
                    0: 'Dwelling',
                    1: 'Condominium',
                };

                const DEDUCTIBLE_CHOICES = {
                    0: '$1,000',
                    1: '$2,500',
                    2: '$5,000',
                };
                const LIABILITY_LIMIT_CHOICES = {
                    0: '$2,000,000',
                    1: '$3,000,000',
                    2: '$4,000,000',
                    3: '$5,000,000',
                };
                
                setFormData2Residental(
                    fillFormAction({
                        rental_type: {
                            value: {
                                id: data.rental_type,
                                title: RENTAL_TYPE_CHOICES[data.rental_type],
                            },
                        },
                        deductible: {
                            value: {
                                id: data.deductible ? data.deductible : 0,
                                title: DEDUCTIBLE_CHOICES[data.deductible]
                                    ? DEDUCTIBLE_CHOICES[data.deductible]
                                    : '$1,000',
                            },
                        },
                        landlord_contents: {
                            value: data.landlord_contents
                                ?.toString()
                                ?.replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                        },
                        gross_rental_income: {
                            value: data.gross_rental_income
                                ?.toString()
                                ?.replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                        },
                        liability_limit: {
                            value: {
                                id: data.liability_limit
                                    ? data.liability_limit
                                    : 0,
                                title: LIABILITY_LIMIT_CHOICES[
                                    data.liability_limit
                                ]
                                    ? LIABILITY_LIMIT_CHOICES[
                                          data.liability_limit
                                      ]
                                    : '$2,000,000',
                            },
                        },
                        improvements_betterments: {
                            value: data.improvements_betterments
                                ?.toString()
                                ?.replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                        },
                        loss_assessment: {
                            value: data.loss_assessment
                                ?.toString()
                                ?.replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                        },
                        unit_owner_protection: {
                            value: data.unit_owner_protection
                                ?.toString()
                                ?.replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                        },
                        additional_building: {
                            value: data.additional_building
                                ?.toString()
                                ?.replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                        },
                        building: {
                            value: data.building
                                ?.toString()
                                ?.replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                        },
                        flood: {
                            value:
                                data.flood === true
                                    ? { id: 0, title: 'Yes' }
                                    : data.flood === false
                                    ? { id: 1, title: 'No' }
                                    : '',
                        },
                    })
                );
                setAdditionalCoverages(
                    data.additional_coverages_residential.map(
                        (elem) => elem + ''
                    )
                );
                if (
                    data.additional_coverages_residential.includes('6') ||
                    data.additional_coverages_residential.includes('7')
                ) {
                    setCheckCrime(true);
                }
            }
            const POLICY_TERM_CHOICES = {
                0: '12 Months'
            };
            
            const PRIOR_INSURANCE_CHOICES = {
                0: 'Yes',
                1: 'No'
            };
            setFormData3(
                fillFormAction({
                    name_of_insured: {
                        value: data.name_of_insured ? data.name_of_insured : '',
                    },
                    mailing_address: {
                        value: data.mailing_address ? data.mailing_address : '',
                    },
                    location_address: {
                        value: data.location_address
                            ? data.location_address
                            : '',
                    },
                    mortgagee: {
                        value: data.mortgagee ? data.mortgagee : '',
                    },
                    mortgagee_address: {
                        value: data.mortgagee_address
                            ? data.mortgagee_address
                            : '',
                    },
                    effective_date: {
                        value: data.effective_date
                            ? new Date(data.effective_date)
                            : '',
                    },
                    policy_term: {
                        value: {
                            id: data.policy_term ? data.policy_term : 0,
                            title: POLICY_TERM_CHOICES[data.policy_term]
                                ? POLICY_TERM_CHOICES[data.policy_term]
                                : '12 months',
                        },
                    },
                    prior_insurance: {
                        value: {
                            id: data.prior_insurance,
                            title: PRIOR_INSURANCE_CHOICES[
                                data.prior_insurance
                            ],
                        },
                    },
                    broker_location: {
                        value: data.broker_location ? data.broker_location : '',
                        hasError: '',
                    },
                    has_insured_declined: {
                        value: data.has_insured_declined
                            ? data.has_insured_declined
                            : '',
                    },
                    insured_more_tree_years: {
                        value: data.insured_more_tree_years
                            ? data.insured_more_tree_years
                            : '',
                    },

                    within_100_km: {
                        value: data.within_100_km ? data.within_100_km : '',
                    },
                    required_proof: {
                        value: data.required_proof ? data.required_proof : '',
                    },
                    contained_units: {
                        value: data.contained_units ? data.contained_units : '',
                    },
                    responsible_dwelling: {
                        value: data.responsible_dwelling
                            ? data.responsible_dwelling
                            : '',
                    },
                    responsible_for_snow: {
                        value: data.responsible_for_snow
                            ? data.responsible_for_snow
                            : '',
                    },
                    how_often_inspected: {
                        value: data.how_often_inspected
                            ? data.how_often_inspected
                            : '',
                    },
                    two_or_more_checks: {
                        value: data.two_or_more_checks
                            ? data.two_or_more_checks
                            : '',
                    },
                    is_separate_agreement: {
                        value: data.is_separate_agreement
                            ? data.is_separate_agreement
                            : '',
                    },
                })
            );
            setFormData4(
                fillFormAction({ additional_notes: data.additional_notes })
            );
        })
        .then(() => setFillFormFinished(true))
        .catch((err) => {
            if (err.request.status === 404) {
                navigate('/page_not_found');
            }
            if (err.request.status === 403) {
                navigate('/access_denied');
            }
        })
        .finally(() => setLoading(false));
};
