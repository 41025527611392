import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    isAuth: null,
    user: {},
    email: '',
    userData: '',
};

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        isAuthState: (state, action) => {
            state.isAuth = action.payload;
        },
        logIn: (state, action) => {
            state.isAuth = true;
            state.user = action.payload;
        },
        logOut: (state) => {
            state.isAuth = false;
        },
        userData: (state, action) => {
            state.userData = action.payload;
        },
    },
});

export const { logIn, logOut, isAuthState, userData } = authSlice.actions;
export default authSlice.reducer;
