// import { isPossiblePhoneNumber, isValidPhoneNumber } from 'react-phone-number-input';

const UPDATE_FORM = 'UPDATE_FORM';
const FILL_FORM = 'FILL_FORM';
const TO_NULL = 'TO_NULL';

export const updateFormAction = (data) => {
    return {
        type: UPDATE_FORM,
        data,
    };
};

export const fillFormAction = (payload) => {
    return {
        type: FILL_FORM,
        payload,
    };
};

export const toNull = (payload) => {
    return {
        type: TO_NULL,
        payload,
    };
};

export const validateInput = (name, value, validate, formState) => {
    const emailReg =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const halwellReg = /^[0-9]{5,6}(p|P|a|A|c|C|f|F|L|l|GA|ga)[0-9]{2}$/;
    let hasError = false;
    let error = '';

    switch (name) {
        case 'email':
            if (!emailReg.test(String(value.trim()).toLowerCase())) {
                hasError = true;
                error = 'The email is not valid';
            }
            if (!value) {
                hasError = true;
                error = 'This field is required';
            }
            break;
        case 'password':
            if (!value) {
                hasError = true;
                error = 'This field is required';
            }

        

        
        /**
         * Validates the 'improvements_betterments' field based on the package type.
         * 
         * For both 'Student Rental' and 'Residential Rental' packages:
         *  - The value of 'improvements_betterments' must be within a specified range (IB_base_limit to IB_base_max).
         * 
         * If the value is outside this range, an error is set with an appropriate message indicating the valid range for Improvements & Betterments.
         */
    
        case 'improvements_betterments':
            if (formState.package === 'Student Rental') {
                if (
                    value &&
                    (value.split(',').join('') > validate?.IB_base_max ||
                        value.split(',').join('') < validate?.IB_base_limit)
                ) {
                    hasError = true;
                    error = `Improvements & Betterments should be within the range of $${validate?.IB_base_limit} and $${validate?.IB_base_max}`;
                }
            }
            if (formState.package === 'Residential Rental') {
                if (
                    value &&
                    (value.split(',').join('') > validate?.IB_base_max ||
                        value.split(',').join('') < validate?.IB_base_limit)
                ) {
                    hasError = true;
                    error = `Improvements & Betterments should be within the range of $${validate.IB_base_limit} and $${validate.IB_base_max}`;
                }
            }

            break;

        /**
         * Validates the 'unit_owner_protection' field based on the package type.
         * 
         * For both 'Student Rental' and 'Residential Rental' packages:
         *  - The value of 'unit_owner_protection' must be greater than or equal to a specified limit (UOAP_base_limit).
         *    If it's less, an error is set indicating that the 'Unit Owner Additional Protection' should be greater or equal to the limit.
         *  - The value of 'unit_owner_protection' must also not exceed a certain maximum limit (misc_limit_max).
         *    If it's more, an error is set indicating that the 'Unit Owner Additional Protection' should not exceed the limit.
         * 
         * This validation ensures that the unit owner protection value is not below the minimum limit and not above the maximum limit.
         */
        case 'unit_owner_protection': 
            let unit_owner_protection_numeric_value = value && value.split(',').join('');
            if (formState.package === 'Student Rental') {
                if (
                    unit_owner_protection_numeric_value &&
                    (unit_owner_protection_numeric_value < validate?.UOAP_base_limit ||
                        unit_owner_protection_numeric_value > validate?.misc_limit_max)
                ) {
                    hasError = true;
                    error = `Unit Owner Additional Protection should be within the range of $${validate?.UOAP_base_limit} and $${validate?.misc_limit_max}`;
                }
            }
            if (formState.package === 'Residential Rental') {
                if (
                    unit_owner_protection_numeric_value &&
                    (unit_owner_protection_numeric_value < validate?.UOAP_base_limit ||
                        unit_owner_protection_numeric_value > validate?.misc_limit_max)
                ) {
                    hasError = true;
                    error = `Unit Owner Protection should be within the range of $${validate.UOAP_base_limit} and $${validate.misc_limit_max}`;
                }
            }
            break;
        


        /**
         * Validates the 'loss_assessment' field based on the package type.
         * 
         * For both 'Student Rental' and 'Residential Rental' packages:
         *  - The value of 'loss_assessment' must be greater than or equal to a specified limit (LA_base_limit).
         *    If it's less, an error is set with an appropriate message indicating that the 'Loss Assessment' 
         *    should be greater or equal to the limit.
         *  - The value of 'loss_assessment' must be less than or equal to a specified maximum limit (misc_limit_max).
         *    If it's more, an error is set with an appropriate message indicating that the 'Loss Assessment' 
         *    should be less than or equal to the maximum limit.
         * 
         * This validation ensures that the loss assessment value is not below the minimum limit or above the maximum limit,
         * maintaining consistency and standards in the assessments and ensuring sufficient coverage.
         */    
        case 'loss_assessment':
            // Remove commas from the value for comparison
            let loss_assessment_numeric_value = value && value.split(',').join('');

            // Check if the value is below the minimum limit
            if (loss_assessment_numeric_value < validate?.LA_base_limit) {
                hasError = true;
                error = `Loss Assessment should be greater or equal to $${validate?.LA_base_limit}`;
            }
            // Check if the value exceeds the maximum limit
            else if (loss_assessment_numeric_value > validate?.misc_limit_max) {
                hasError = true;
                error = `Loss Assessment should not exceed $${validate?.misc_limit_max}`;
            }
            break;


        /**
         * - Validates that the 'dwelling' value does not exceed a specified limit (Dwelling_limit).
         * - If the value exceeds the limit, an error message is shown and the error flag is set.
         */

        case 'dwelling':
            if (value && value.split(',').join('') > validate?.Dwelling_limit) {
                hasError = true;
                error = `Dwelling should not exceed $${validate?.Dwelling_limit}`;
            }

            break;

        /**
        * - Validates that the 'building' value does not exceed a specified limit (Dwelling_limit).
        * - If the value exceeds the limit, an error message is shown and the error flag is set.
        */
        case 'building':
            if (value && value.split(',').join('') > validate.Dwelling_limit) {
                hasError = true;
                error = `Building should not exceed $${validate.Dwelling_limit}`;
            }
            break;

        /**
        * - For 'Student Rental' package, checks if the 'additional_building' value is within 
        *   the Additional_building_base_limit. If it exceeds the limit, an error message is displayed.
        * - For 'Residential Rental' package, performs the same validation as for 'Student Rental'.
        */
        case 'additional_building':
            if (formState.package === 'Student Rental') {
                if (
                    value &&
                    value.split(',').join('') >
                        validate?.Additional_building_base_limit
                ) {
                    hasError = true;
                    error = `Additional Building should not exceed $${validate?.Additional_building_base_limit}`;
                }
            }
            if (formState.package === 'Residential Rental') {
                if (
                    value &&
                    value.split(',').join('') >
                        validate.Additional_building_base_limit
                ) {
                    hasError = true;
                    error = `Additional Building should not exceed $${validate.Additional_building_base_limit}`;
                }
            }

            break;

        /**
         * Validates the 'other_policy_with_halwell' field.
         * 
         * - Initially assumes there is no error.
         * - If no value is provided, it does not flag any error.
         * - If a value is provided, it checks whether the value matches the pattern defined 
         *   in 'halwellReg'. 
         * - If the value does not match the pattern, it flags an error and provides 
         *   an error message indicating that a valid policy number must be provided.
         * 
         * This validation ensures that the other policy number with Halwell is in the correct 
         * format, helping prevent the entry of incorrect or invalid policy numbers.
         */
        case 'other_policy_with_halwell':
            if (!value) {
                hasError = false;
                error = ``;
            }
            if (!halwellReg.test(String(value).toLowerCase())) {
                hasError = true;
                error = `Please provide a valid policy number`;
            }

            break;

        default:
            break;
    }

    return { hasError, error };
};

export const onInputChange = (name, value, dispatch, formState, validate) => {
    const { hasError, error } = validateInput(name, value, validate, formState);
    let isFormValid = true;

    for (const key in formState) {
        const item = formState[key];
        // Check if the current field has error
        if (key === name && hasError) {
            isFormValid = false;
            break;
        } else if (key !== name && item.hasError) {
            // Check if any other field has error
            isFormValid = false;
            break;
        }
    }

    dispatch(
        updateFormAction({
            name,
            value,
            hasError,
            error,
            touched: false,
            isFormValid,
        })
    );
};

export const onFocusOut = (name, value, dispatch, formState, validate) => {
    const { hasError, error } = validateInput(name, value, validate, formState);
    let isFormValid = true;
    for (const key in formState) {
        const item = formState[key];
        if (key === name && hasError) {
            isFormValid = false;
            break;
        } else if (key !== name && item.hasError) {
            isFormValid = false;
            break;
        }
    }

    dispatch(
        updateFormAction({
            name,
            value,
            hasError,
            error,
            touched: true,
            isFormValid,
        })
    );
};

export const validateForm = (formData, dispatch, validate) => {
    let formDataArray = Object.keys(formData);
    let isFormValid = true;

    formDataArray.forEach((elem) => {
        if (elem !== 'isFormValid') {
            const { hasError, error } = validateInput(
                elem,
                formData[elem].value
            );
            if (hasError === true) {
                isFormValid = false;
            }
            dispatch({
                type: UPDATE_FORM,
                data: {
                    ...formData[elem],
                    name: elem,
                    hasError,
                    error,
                    touched: true,
                    isFormValid,
                },
            });
        }
    });

    return isFormValid;
};

export const formsReducer = (state, action) => {
    switch (action.type) {
        case UPDATE_FORM:
            const { name, value, hasError, error, touched, isFormValid } =
                action.data;
            return {
                ...state,
                // update the state of the particular field,
                // by retaining the state of other fields
                [name]: { ...state[name], value, hasError, error, touched },
                isFormValid,
            };
        case FILL_FORM:
            return {
                ...state,
                ...action.payload,
            };
        case TO_NULL:
            return null;
        default:
            return state;
    }
};
