export const validateWithConstStudent = (
    step,
    formData,
    setFormData,
    fillFormAction,
    validation
) => {
    const halwellReg = /^[0-9]{5,6}(p|P|a|A|c|C|f|F|L|l|GA|ga)[0-9]{2}$/;

    let isValid = true;
    if (step === 2) {
        const data = { ...formData };
        // Validation for Gross Rental Income being less than misc_limit_max for both rental types
        if (data.gross_rental_income.value) {
            const grossIncomeValue = parseInt(data.gross_rental_income.value.split(',').join(''), 10);
        
            // Define conditions
            const exceedsMiscLimit = grossIncomeValue > validation?.misc_limit_max;
            const isDwellingValueSet = data.dwelling.value && validation?.GRI_Dwelling_validation;
            const belowDwellingLimit = data.rental_type.value.id === 0 && isDwellingValueSet &&
                grossIncomeValue < (parseInt(data.dwelling.value.split(',').join(''), 10) * validation?.GRI_Dwelling_validation);
            const belowBaseLimit = data.rental_type.value.id === 1 &&
                grossIncomeValue < validation?.GRI_base_limit;
        
            // Consolidated Validation Logic
            if (exceedsMiscLimit) {
                isValid = false;
                data.gross_rental_income.error = `Gross Rental Income should be less than or equal to $${validation?.misc_limit_max}`;
                data.gross_rental_income.hasError = true;
            } else if (belowDwellingLimit) {
                isValid = false;
                data.gross_rental_income.error = `Gross Rental Income should be equal to or greater than $${
                    (parseInt(data.dwelling.value.split(',').join(''), 10) * validation?.GRI_Dwelling_validation)
                }`;
                data.gross_rental_income.hasError = true;
            } else if (belowBaseLimit) {
                isValid = false;
                data.gross_rental_income.error = `Gross Rental Income should be greater or equal to $${validation?.GRI_base_limit}`;
                data.gross_rental_income.hasError = true;
            } else if (data.gross_rental_income.error === 'This field is required') {
                data.gross_rental_income.hasError = true;
            } else {
                data.gross_rental_income.error = '';
                data.gross_rental_income.hasError = false;
            }
        }
        
        // Validation for Landlord Contents being less than LanConts_limit_max

        if (
            data.landlord_contents.value &&
            (data.landlord_contents.value.split(',').join('') >
                validation?.LanConts_base_max ||
                data.landlord_contents.value.split(',').join('') <
                    validation?.LanConts_base_limit)
        ) {
            isValid = false;

            data.landlord_contents.error = `Landlord Contents should be within the range of $${validation?.LanConts_base_limit} and $${validation?.LanConts_base_max}`;
            data.landlord_contents.hasError = true;
        } else {
            data.landlord_contents.error =
                data.landlord_contents.error === 'This field is required'
                    ? 'This field is required'
                    : '';
            data.landlord_contents.hasError =
                data.landlord_contents.error === 'This field is required'
                    ? true
                    : false;
        }

        if (data.rental_type.value.id === 0) {           
            if (
                data.dwelling.value &&
                data.dwelling.value.split(',').join('') >
                    validation?.Dwelling_limit
            ) {
                isValid = false;
                data.dwelling.error = `Dwelling should not exceed $${validation?.Dwelling_limit}`;
                data.dwelling.hasError = true;
            } else {
                data.dwelling.error =
                    data.dwelling.error === 'This field is required'
                        ? 'This field is required'
                        : '';
                data.dwelling.hasError =
                    data.dwelling.error === 'This field is required'
                        ? true
                        : false;
            }
            if (
                data.additional_building.value &&
                data.additional_building.value.split(',').join('') >
                    validation?.Additional_building_base_limit
            ) {
                isValid = false;
                data.additional_building.hasError = true;
                data.additional_building.error = `Additional Building should not exceed $${validation?.Additional_building_base_limit}`;
            } else {
                data.additional_building.error =
                    data.additional_building.error === 'This field is required'
                        ? 'This field is required'
                        : '';
                data.additional_building.hasError =
                    data.additional_building.error === 'This field is required'
                        ? true
                        : false;
            }
            if (
                data.other_policy_with_halwell.value &&
                !halwellReg.test(
                    String(data.other_policy_with_halwell.value).toLowerCase()
                )
            ) {
                isValid = false;
                data.other_policy_with_halwell.hasError = true;
                data.other_policy_with_halwell.error = `Please provide a valid policy number`;
            } else {
                data.other_policy_with_halwell.hasError = false;
                data.other_policy_with_halwell.error = ``;
            }
        }
        if (data.rental_type.value.id === 1) {
            if (
                data.improvements_betterments.value &&
                (data.improvements_betterments.value.split(',').join('') >
                    validation?.IB_base_max ||
                    data.improvements_betterments.value.split(',').join('') <
                        validation?.IB_base_limit)
            ) {
                isValid = false;
                data.improvements_betterments.error = `Improvements & Betterments should be within the range of $${validation?.IB_base_limit} and $${validation?.IB_base_max}`;
                data.improvements_betterments.hasError = true;
            } else {
                data.improvements_betterments.error =
                    data.improvements_betterments.error ===
                    'This field is required'
                        ? 'This field is required'
                        : '';
                data.improvements_betterments.hasError =
                    data.improvements_betterments.error ===
                    'This field is required'
                        ? true
                        : false;
            }
            if (
                data.unit_owner_protection.value &&
                data.unit_owner_protection.value.split(',').join('') <
                    validation?.UOAP_base_limit
            ) {
                isValid = false;
                data.unit_owner_protection.error = `Unit Owner Additional Protection should be greater or equal to $${validation?.UOAP_base_limit}`;
                data.unit_owner_protection.hasError = true;
            } 
            // unit owner protection below misc limit max
            else if (
                data.unit_owner_protection.value &&
                parseInt(data.unit_owner_protection.value.split(',').join('')) >
                    (validation?.misc_limit_max)
            ) {
                isValid = false;
                data.unit_owner_protection.error = `Loss Assessment should be less than or equal to $${validation?.misc_limit_max}`;
                data.unit_owner_protection.hasError = true;
            }
            else {
                data.unit_owner_protection.error =
                    data.unit_owner_protection.error ===
                    'This field is required'
                        ? 'This field is required'
                        : '';
                data.unit_owner_protection.hasError =
                    data.unit_owner_protection.error ===
                    'This field is required'
                        ? true
                        : false;
            }
            if (
                data.loss_assessment.value &&
                data.loss_assessment.value.split(',').join('') <
                    validation?.LA_base_limit
            ) {
                isValid = false;
                data.loss_assessment.hasError = true;
                data.loss_assessment.error = `Loss Assessment should be greater or equal to $${validation?.LA_base_limit}`;
            } 
            // loss assessment below misc limit max
            else if (
                data.loss_assessment.value &&
                parseInt(data.loss_assessment.value.split(',').join('')) >
                    (validation?.misc_limit_max)
            ) {
                isValid = false;
                data.loss_assessment.error = `Loss Assessment should be less than or equal to $${validation?.misc_limit_max}`;
                data.loss_assessment.hasError = true;
            } 
            else {
                data.loss_assessment.error =
                    data.loss_assessment.error === 'This field is required'
                        ? 'This field is required'
                        : '';
                data.loss_assessment.hasError =
                    data.loss_assessment.error === 'This field is required'
                        ? true
                        : false;
            }
        }

        setFormData(fillFormAction(data));
    }
    return isValid;
};
