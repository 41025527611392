import React from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import error from '../../assets/images/error-image.png';
import './custom-fields.scss';

export const Datepicker = ({
    smallTitle,
    title,
    startDate,
    setStartDate,
    className,
    height,
    errorText,
    disabled,
}) => {
    return (
        <>
            {title && (
                <div
                    className={`custom-field-title ${
                        disabled ? 'disabled' : ''
                    }`}
                >
                    {title}
                </div>
            )}
            {smallTitle && (
                <div className={`custom-field-smalltitle`}>{smallTitle}</div>
            )}
            <div className="custom-field">
                <DatePicker
                    selected={startDate}
                    onChange={setStartDate}
                    className={`custom-field__input ${className} ${
                        errorText ? 'red' : ''
                    }`}
                    dateFormat="yyyy/MM/dd"
                    placeholderText="YYYY / MM / DD"
                />
                {errorText && (
                    <>
                        <div className="errortext">
                            <img className="errortext__img" src={error} />
                            <div className="errortext__text">{errorText}</div>
                        </div>
                    </>
                )}
            </div>
        </>
    );
};
