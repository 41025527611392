import React, { useEffect, useRef, useState } from 'react';
import { useClickOutside } from '../../helpers/useClickOutside';
import error from '../../assets/images/error-image.png';
import './custom-fields.scss';

export const MultiSelect = ({
    placeholder,
    arrowImage,
    title,
    height,
    onChange,
    value,
    smallTitle,
    errorText,
    formData,
    className,
    checkCrime,
    setCheckCrime,
    disabled,
    options,
}) => {
    const [openSelection, setOpenSelection] = useState(false);
    const [onFocus, setOnFocus] = useState(false);
    const ref = useRef();

    useClickOutside(ref, () => setOpenSelection(false));
    return (
        <>
            {title && (
                <div
                    className={`custom-field-title ${
                        disabled ? 'disabled' : ''
                    }`}
                >
                    {title}
                </div>
            )}

            <div className="custom-field" ref={ref}>
                <div onClick={() => setOpenSelection((prev) => !prev)}>
                    <div
                        className={`custom-field__selection custom-field__input multiSelect ${
                            errorText ? 'red' : ''
                        }`}
                        style={{
                            background: onFocus ? '#EEF5FF' : 'white',
                            height: height,
                        }}
                        placeholder={onFocus ? '' : placeholder}
                        onBlur={() => {
                            setOnFocus(false);
                        }}
                        onFocus={() => setOnFocus(true)}
                        value={value}
                    >
                        {value ? (
                            <div className="value">{value}</div>
                        ) : (
                            <div className="placeholder">{placeholder}</div>
                        )}
                    </div>
                </div>
                {errorText && (
                    <div className="errortext">
                        <img className="errortext__img" src={error} />
                        <div className="errortext__text">{errorText}</div>
                    </div>
                )}

                {arrowImage && (
                    <div
                        className="custom-field__arrow"
                        onClick={() => setOpenSelection((prev) => !prev)}
                    >
                        ›
                    </div>
                )}

                {openSelection && (
                    <div className={`custom-field__list  ${className}`}>
                        <form className="custom-field__list-multi">
                            {options.map((elem) => {
                                if (elem.type === 'checkbox') {
                                    return (
                                        <div
                                            key={elem.title}
                                            className={`custom-field__item ${
                                                elem === 'Completed'
                                                    ? 'completed'
                                                    : ''
                                            } custom-field__multi`}
                                            onClick={(e) => {
                                                if (elem.title === ' Crime') {
                                                    setCheckCrime(
                                                        (prev) => !prev
                                                    );
                                                } else {
                                                    onChange(elem.id);
                                                }
                                            }}
                                        >
                                            <input
                                                type="checkbox"
                                                className="multi-checkbox"
                                                checked={
                                                    elem.title === ' Crime'
                                                        ? checkCrime
                                                        : formData?.includes(
                                                              elem.id + ''
                                                          )
                                                }
                                                // onChange={(e) => {
                                                //     if (
                                                //         elem.title === ' Crime'
                                                //     ) {
                                                //         setCheckCrime(
                                                //             (prev) => !prev
                                                //         );
                                                //     } else {
                                                //         onChange(elem.id);
                                                //     }
                                                // }}
                                            />
                                            <div>{elem.title}</div>
                                        </div>
                                    );
                                }
                                if (elem.type === 'radio' && checkCrime) {
                                    return (
                                        <div
                                            key={elem.title}
                                            className={`custom-field__item ${
                                                elem === 'Completed'
                                                    ? 'completed'
                                                    : ''
                                            } custom-field__multi radio`}
                                            onClick={() => onChange(elem.id)}
                                        >
                                            <input
                                                checked={formData?.includes(
                                                    elem.id + ''
                                                )}
                                                type="radio"
                                                name="radio"
                                                id={`radio${elem}`}
                                                className={`multi-checkbox radio ${
                                                    !checkCrime ? 'none' : ''
                                                }`}
                                                value={formData?.includes(
                                                    elem.id
                                                )}
                                                onChange={() =>
                                                    onChange(elem.id)
                                                }
                                            />
                                            <div>{elem.title}</div>
                                        </div>
                                    );
                                }
                            })}
                        </form>
                    </div>
                )}
            </div>
        </>
    );
};
