import servise from './service';
import axios from 'axios';
import { getCookie } from './auth';


export const createQuote = (data) => {
    return servise.post(`/quote/quotes/`, data);
};

export const updateQuote = (id, data) => {
    return servise.patch(`/quote/quotes/${id ? `${id}/` : ''}`, data);
};

export const getQuotes = (
    offset,
    status,
    packageId,
    search,
    ordering,
    inWork,
    submitted
) => {
    return servise.get(
        `/quote/quotes/?limit=5&offset=${offset}${
            status || status === 0 ? `&status=${status}` : ''
        }${inWork ? '&in_work=true' : ''}${submitted ? '&status=Submitted' : ''}${
            packageId || packageId === 0 ? `&package=${packageId}` : ''
        }${search ? `&search=${search}` : ''}${
            ordering ? `&ordering=${ordering}` : ''
        }`
    );
};

export const getQuote = (id) => {
    return servise.get(`/quote/quotes/${id}/`);
};

export const addLoose = (data) => {
    return servise.post(`/quote/list_looses/`, data);
};

export const updateLoose = (id, data) => {
    return servise.patch(`/quote/list_looses/${id}/`, data);
};

export const deleteLoose = (id, data) => {
    return servise.delete(`/quote/list_looses/${id}/`);
};

export const validationRules = () => {
    return servise.get(`/configuration/configuration/`);
};

export const cancelQuote = (id) => {
    return servise.post(`/quote/cancel_quote/${id}/`);
};

export const completeQuote = (id) => {
    return servise.post(`/quote/complete_quote/${id}/`);
};

export const getTableData = (id) => {
    return servise.get(`/quote/quotes/${id}/table_data/`);
};

export const helpTicket = (data) => {
    return servise.post(`/quote/help_ticket/`, data);
};

export const getTicketData = (id) => {
    return servise.get(`/quote/help_ticket/?quote_id=${id}`);
};

const BASE_URL = process.env.REACT_APP_BASE_URL;

export const downloadPdf = (id) => {
    // Get the auth_token cookie
    const token = getCookie('auth_token');
    
    return fetch(BASE_URL + `pdf/table/${id}/`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/pdf',
            Authorization: `token ${token}`,  // Use the token from the cookie
        },
    });
};

export const submitTicket = (id, data) => {
    return servise.post(`/quote/submit_ticket/?quote_id=${id}`, data);
};
