import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

export const ProtectedRoute = ({
    isAuth,
    roles = [],
    children,
    needAuth,
    role,
    isChecked,
}) => {
    const [content, setContent] = useState(<></>);
    const navigate = useNavigate();

    useEffect(() => {
        if (needAuth && !isAuth) {
            navigate('/login');
            return;
        }

        if (isAuth && !needAuth) {
            navigate('/homepage');
        }

        if (!roles.find((elem) => elem === role)) {
            navigate('/login');
            return;
        }

        setContent(children);
    }, [isAuth, roles, needAuth]);

    return content;
};
