import React, { useState } from 'react';
import { CheckboxContainer } from '../CustomFields/CheckboxContainer';
import { Datepicker } from '../CustomFields/Datepicker';
import { InputField } from '../CustomFields/InputField';
import { onInputChange, fillFormAction } from '../../helpers/formUtils';
import MaskedInput from 'react-text-mask';
import './steps.scss';
import { CustomSelection } from '../CustomFields/CustomSelection';
import { deleteLoose } from '../../crud/quote';
import { useEffect } from 'react';
import moment from 'moment';
import { setIsChanged } from '../../redux/quote/quote';
import { useDispatch } from 'react-redux';

export const ThirdStep = ({
    formData,
    setFormData,
    setListOfLooses,
    listOfLooses,
}) => {
    const [changes, isChanges] = useState(true);
    const dispatch = useDispatch();
    const loosesHandler = (id, name, value) => {
        isChanges((prev) => !prev);
        const res = [...listOfLooses];
        const index = res.findIndex((elem) => elem.localId === id);
        res[index] = {
            ...res[index],
            [name]: { ...listOfLooses[index][name], value: value },
        };
        setListOfLooses(res);
    };

    const deleteListOfLooses = (id) => {
        const res = [...listOfLooses];
        const index = res.findIndex((elem) => elem.localId === id);
        res.splice(index, 1);
        setListOfLooses(res);
    };

    const currencyMask = (e) => {
        let value = e.target.value;
        value = value.replace(/\D/g, '');
        // value = value.replace(/(\d)(\d{2})$/, '$1.$2');
        value = value.replace(/(?=(\d{3})+(\D))\B/g, ',');
        e.target.value = value;
        return e.target.value;
    };

    useEffect(() => {
        const data = [...listOfLooses];
        data.forEach((elem, index) => {
            if (
                !elem.date.value &&
                !elem.description.value &&
                !elem.amount_paid.value &&
                !elem.is_open.value.title
            ) {
                data[index].date.hasError = false;
                data[index].date.error = '';
                data[index].description.hasError = false;
                data[index].description.error = '';
                data[index].amount_paid.hasError = false;
                data[index].amount_paid.error = '';
                data[index].is_open.hasError = false;
                data[index].is_open.error = '';
            }
            if (elem.date.value) {
                data[index].date.hasError = false;
                data[index].date.error = '';
            }
            if (elem.description.value) {
                data[index].description.hasError = false;
                data[index].description.error = '';
            }
            if (elem.amount_paid.value) {
                data[index].amount_paid.hasError = false;
                data[index].amount_paid.error = '';
            }
            if (elem.is_open.value.title) {
                data[index].is_open.hasError = false;
                data[index].is_open.error = '';
            }
        });

        setListOfLooses(data);
    }, [changes]);

    const currencyMaskOnlyNumbers = (e) => {
        let value = e.target.value;
        value = value.replace(/\D/g, '');
        value = value.replace(/(?=(\d{3})+(\D))\B/g, '');
        e.target.value = value;
        return e.target.value;
    };

    return (
        <>
            <div className="step-wrapper">
                <div className="step-wrapper__row">
                    <div className="step-wrapper__select-container">
                        <InputField
                            arrowImage
                            placeholder={'Type your answer'}
                            background
                            title={
                                <div>
                                    <div>
                                        Name of insured{' '}
                                        <span className="mandatory">*</span>
                                    </div>
                                </div>
                            }
                            height="16px"
                            maxLength={240}
                            onChange={(e) => {
                                dispatch(setIsChanged(true));
                                onInputChange(
                                    'name_of_insured',
                                    e.target.value,
                                    setFormData,
                                    formData
                                );
                            }}
                            value={formData.name_of_insured.value}
                            errorText={
                                formData.name_of_insured.hasError &&
                                'This field is required'
                            }
                        />
                    </div>
                    <div className="step-wrapper__select-container">
                        <InputField
                            arrowImage
                            placeholder={'Type your answer'}
                            background
                            title={
                                <div>
                                    <div>
                                        Mailing address{' '}
                                        <span className="mandatory">*</span>
                                    </div>
                                </div>
                            }
                            height="16px"
                            maxLength={1000}
                            onChange={(e) => {
                                dispatch(setIsChanged(true));
                                onInputChange(
                                    'mailing_address',
                                    e.target.value,
                                    setFormData,
                                    formData
                                );
                            }}
                            value={formData.mailing_address.value}
                            errorText={
                                formData.mailing_address.hasError &&
                                'This field is required'
                            }
                        />
                    </div>
                    <div className="step-wrapper__select-container">
                        <InputField
                            arrowImage
                            placeholder={'Type your answer'}
                            background
                            title={
                                <div>
                                    <div>
                                        Location address{' '}
                                        <span className="mandatory">*</span>
                                    </div>
                                </div>
                            }
                            height="16px"
                            maxLength={1000}
                            onChange={(e) => {
                                dispatch(setIsChanged(true));
                                onInputChange(
                                    'location_address',
                                    e.target.value,
                                    setFormData,
                                    formData
                                );
                            }}
                            value={formData.location_address.value}
                            errorText={
                                formData.location_address.hasError &&
                                'This field is required'
                            }
                        />
                    </div>
                </div>
                <div className="step-wrapper__row">
                    <div className="step-wrapper__select-container">
                        <InputField
                            arrowImage
                            placeholder={'Type your answer'}
                            background
                            title={
                                <div>
                                    <div>
                                        Mortgagee{' '}
                                        <span className="mandatory">*</span>
                                    </div>
                                </div>
                            }
                            height="16px"
                            maxLength={500}
                            onChange={(e) => {
                                dispatch(setIsChanged(true));
                                onInputChange(
                                    'mortgagee',
                                    e.target.value,
                                    setFormData,
                                    formData
                                );
                            }}
                            value={formData.mortgagee.value}
                            errorText={
                                formData.mortgagee.hasError &&
                                'This field is required'
                            }
                        />
                    </div>
                    <div className="step-wrapper__select-container">
                        <InputField
                            arrowImage
                            placeholder={'Type your answer'}
                            background
                            title={
                                <div>
                                    <div>
                                        Mortgagee address{' '}
                                        <span className="mandatory">*</span>
                                    </div>
                                </div>
                            }
                            height="16px"
                            maxLength={1000}
                            onChange={(e) => {
                                dispatch(setIsChanged(true));
                                onInputChange(
                                    'mortgagee_address',
                                    e.target.value,
                                    setFormData,
                                    formData
                                );
                            }}
                            value={formData.mortgagee_address.value}
                            errorText={
                                formData.mortgagee_address.hasError &&
                                'This field is required'
                            }
                        />
                    </div>
                    <div className="step-wrapper__select-container">
                        <Datepicker
                            title={
                                <div>
                                    <div>
                                        Effective date{' '}
                                        <span className="mandatory">*</span>
                                    </div>
                                </div>
                            }
                            startDate={formData.effective_date.value}
                            setStartDate={(data) => {
                                dispatch(setIsChanged(true));
                                setFormData(
                                    fillFormAction({
                                        ...formData,
                                        effective_date: {
                                            ...formData.effective_date,
                                            value: data,
                                        },
                                    })
                                );
                            }}
                            errorText={
                                formData.effective_date.hasError &&
                                !formData.effective_date.value &&
                                'This field is required'
                            }
                        />
                    </div>
                </div>
                <div className="step-wrapper__row">
                <div className="step-wrapper__select-container">
                    <CustomSelection
                        title={
                            <div>
                                Prior insurance{' '}
                                <span className="mandatory">*</span>
                            </div>
                        }
                        arrowImage
                        placeholder={'Select answer'}
                        background
                        options={[
                            { id: 0, title: 'Yes' },
                            { id: 1, title: 'No' }
                        ]} // Options for the dropdown
                        onChange={(value) => {
                            dispatch(setIsChanged(true));
                            onInputChange(
                                'prior_insurance',
                                value, // send only the title as the value
                                setFormData,
                                formData
                            );
                        }}
                        value={formData.prior_insurance.value.title}
                        errorText={
                            formData.prior_insurance.hasError &&
                            'This field is required'
                        }
                    />
                </div>

                <div className="step-wrapper__select-container">
                    <CustomSelection
                        title={
                            <div>
                                Policy term{' '}
                                <span className="mandatory">*</span>
                            </div>
                        }
                        arrowImage
                        placeholder={'Select answer'}
                        background
                        options={[{ id: 0, title: '12 Months' }]} // Options for the dropdown
                        onChange={(value) => {
                            dispatch(setIsChanged(true));
                            onInputChange(
                                'policy_term',
                                value, // send only the title as the value
                                setFormData,
                                formData
                            );
                        }}
                        value={formData.policy_term.value.title}
                        errorText={
                            formData.policy_term.hasError &&
                            'This field is required'
                        }
                    />
                    </div>

                    <div className="step-wrapper__select-container">
                        <InputField
                            arrowImage
                            placeholder={'Type your answer'}
                            background
                            title={
                                <div>
                                    <div>
                                        Broker name/location{' '}
                                        <span className="mandatory">*</span>
                                    </div>
                                </div>
                            }
                            height="16px"
                            maxLength={1000}
                            onChange={(e) => {
                                dispatch(setIsChanged(true));
                                onInputChange(
                                    'broker_location',
                                    e.target.value,
                                    setFormData,
                                    formData
                                );
                            }}
                            value={formData.broker_location.value}
                            errorText={
                                formData.broker_location.hasError &&
                                'This field is required'
                            }
                        />
                    </div>
                </div>
                
                <div
                    className="step-wrapper__row"
                    style={{ margin: '15px 0 0 0' }}
                >
                    <div className="step-wrapper__select-container">
                        <InputField
                            widthForTitle={'350px'}
                            arrowImage
                            placeholder={'Type your answer'}
                            background
                            title={
                                <div>
                                    <div>
                                        How many self contained units?{' '}
                                        <span className="mandatory">*</span>
                                    </div>
                                </div>
                            }
                            height="16px"
                            onChange={(e) => {
                                dispatch(setIsChanged(true));
                                onInputChange(
                                    'contained_units',
                                    currencyMaskOnlyNumbers(e),
                                    setFormData,
                                    formData
                                );
                            }}
                            value={formData.contained_units.value}
                            maxLength={9}
                            errorText={
                                formData.contained_units.hasError &&
                                'This field is required'
                            }
                        />
                    </div>
                    <div className="step-wrapper__select-container">
                        <InputField
                            widthForTitle={'358px'}
                            arrowImage
                            placeholder={'Type your answer'}
                            background
                            title={
                                <div>
                                    <div>
                                        Who is responsible for dwelling
                                        maintenance?{' '}
                                        <span className="mandatory">*</span>
                                    </div>
                                </div>
                            }
                            height="16px"
                            onChange={(e) => {
                                dispatch(setIsChanged(true));
                                onInputChange(
                                    'responsible_dwelling',
                                    e.target.value,
                                    setFormData,
                                    formData
                                );
                            }}
                            value={formData.responsible_dwelling.value}
                            maxLength={1000}
                            errorText={
                                formData.responsible_dwelling.hasError &&
                                'This field is required'
                            }
                        />
                    </div>
                    <div className="step-wrapper__select-container"></div>
                </div>
                <div className="step-wrapper__row">
                    <div className="step-wrapper__select-container">
                        <InputField
                            widthForTitle={'358px'}
                            arrowImage
                            placeholder={'Type your answer'}
                            background
                            title={
                                <div>
                                    <div>
                                        Who is responsible for snow removal?{' '}
                                        <span className="mandatory">*</span>
                                    </div>
                                </div>
                            }
                            height="16px"
                            onChange={(e) => {
                                dispatch(setIsChanged(true));
                                onInputChange(
                                    'responsible_for_snow',
                                    e.target.value,
                                    setFormData,
                                    formData
                                );
                            }}
                            value={formData.responsible_for_snow.value}
                            maxLength={1000}
                            errorText={
                                formData.responsible_for_snow.hasError &&
                                'This field is required'
                            }
                        />
                    </div>
                    <div className="step-wrapper__select-container">
                        <InputField
                            widthForTitle={'350px'}
                            arrowImage
                            placeholder={'Type your answer'}
                            background
                            title={
                                <div>
                                    <div>
                                        How often is the property
                                        inspected internally?{' '}
                                        <span className="mandatory">*</span>
                                    </div>
                                </div>
                            }
                            height="16px"
                            onChange={(e) => {
                                dispatch(setIsChanged(true));
                                onInputChange(
                                    'how_often_inspected',
                                    e.target.value,
                                    setFormData,
                                    formData
                                );
                            }}
                            value={formData.how_often_inspected.value}
                            maxLength={1000}
                            errorText={
                                formData.how_often_inspected.hasError &&
                                'This field is required'
                            }
                        />
                    </div>
                    <div className="step-wrapper__select-container"></div>
                </div>
                <div className="step-wrapper__column-container">
                    <div className="step-wrapper__column">
                        <CheckboxContainer
                            title="Has applicant ever had insurance declined/cancelled?"
                            checked={formData.has_insured_declined.value}
                            onChange={() => {
                                dispatch(setIsChanged(true));
                                setFormData(
                                    fillFormAction({
                                        ...formData,
                                        has_insured_declined: {
                                            value: !formData
                                                .has_insured_declined.value,
                                        },
                                    })
                                );
                            }}
                        />
                        <CheckboxContainer
                            title="Has insurance been in force for 3 or more years?"
                            checked={formData.insured_more_tree_years.value}
                            onChange={() => {
                                dispatch(setIsChanged(true));
                                setFormData(
                                    fillFormAction({
                                        ...formData,
                                        insured_more_tree_years: {
                                            value: !formData
                                                .insured_more_tree_years.value,
                                        },
                                    })
                                );
                            }}
                        />
                        <div></div>
                    </div>
                    <div className="step-wrapper__column">
                        <CheckboxContainer
                            title="Does the owner live within 100km?"
                            checked={formData.within_100_km.value}
                            onChange={() => {
                                dispatch(setIsChanged(true));
                                setFormData(
                                    fillFormAction({
                                        ...formData,
                                        within_100_km: {
                                            value: !formData.within_100_km
                                                .value,
                                        },
                                    })
                                );
                            }}
                        />
                        <CheckboxContainer
                            title="Does the landlord require proof of
                            insurance from tenant?"
                            checked={formData.required_proof.value}
                            onChange={() => {
                                dispatch(setIsChanged(true));
                                setFormData(
                                    fillFormAction({
                                        ...formData,
                                        required_proof: {
                                            value: !formData.required_proof
                                                .value,
                                        },
                                    })
                                );
                            }}
                        />
                        <div></div>
                    </div>
                </div>
                
                <div className="step-wrapper__column">
                    <CheckboxContainer
                        title="Will landlord perform monthly checks of the premises?"
                        checked={formData.two_or_more_checks.value}
                        onChange={() => {
                            dispatch(setIsChanged(true));
                            setFormData(
                                fillFormAction({
                                    ...formData,
                                    two_or_more_checks: {
                                        value: !formData.two_or_more_checks
                                            .value,
                                    },
                                })
                            );
                        }}
                    />

                    <CheckboxContainer
                        title="If a tenant is responsible for snow removal, is there a separate agreement in place?"
                        checked={formData.is_separate_agreement.value}
                        onChange={() => {
                            dispatch(setIsChanged(true));
                            setFormData(
                                fillFormAction({
                                    ...formData,
                                    is_separate_agreement: {
                                        value: !formData.is_separate_agreement
                                            .value,
                                    },
                                })
                            );
                        }}
                    />
                    <div></div>
                </div>
                <div
                    className="step-wrapper__subtitle"
                    style={{ margin: '40px 0 0 0' }}
                >
                    List of all losses from past 5 years
                </div>
                {listOfLooses?.map((elem) => {
                    return (
                        <>
                            <div className="step-wrapper__row">
                                <div className="step-wrapper__select-container__small">
                                    <Datepicker
                                        smallTitle={'Date'}
                                        startDate={elem?.date?.value}
                                        setStartDate={(data) => {
                                            dispatch(setIsChanged(true));
                                            loosesHandler(
                                                elem.localId,
                                                'date',
                                                data
                                            );
                                        }}
                                        className="smallDatepicker"
                                        errorText={
                                            elem?.date?.hasError &&
                                            elem?.date?.error
                                        }
                                    />
                                </div>
                                <div className="step-wrapper__select-container__small">
                                    <InputField
                                        arrowImage
                                        placeholder={'Type your answer'}
                                        background
                                        smallTitle={'Description'}
                                        height="10px"
                                        onChange={(e) => {
                                            dispatch(setIsChanged(true));
                                            loosesHandler(
                                                elem.localId,
                                                'description',
                                                e.target.value
                                            );
                                        }}
                                        value={elem?.description.value}
                                        maxLength={1000}
                                        errorText={
                                            elem?.description?.hasError &&
                                            elem?.description?.error
                                        }
                                    />
                                </div>
                                <div className="step-wrapper__select-container__small">
                                    <InputField
                                        smalldollar={true}
                                        placeholder="Type your answer"
                                        value={elem?.amount_paid.value
                                            ?.toString()
                                            .replace(
                                                /\B(?=(\d{3})+(?!\d))/g,
                                                ','
                                            )}
                                        height="10px"
                                        onChange={(e) => {
                                            dispatch(setIsChanged(true));
                                            loosesHandler(
                                                elem.localId,
                                                'amount_paid',
                                                currencyMask(e)
                                            );
                                        }}
                                        smallTitle="Amount paid"
                                        errorText={
                                            elem?.amount_paid?.hasError &&
                                            elem?.amount_paid?.error
                                        }
                                    />
                                </div>
                                <div className="step-wrapper__select-container__small">
                                    <CustomSelection
                                        className="small-selection"
                                        arrowImage
                                        placeholder={'Select your answer'}
                                        background
                                        smallTitle={'Open / Closed'}
                                        height="10px"
                                        options={[
                                            { id: 0, title: 'Open' },
                                            { id: 1, title: 'Closed' },
                                        ]}
                                        onChange={(value) => {
                                            dispatch(setIsChanged(true));
                                            loosesHandler(
                                                elem.localId,
                                                'is_open',
                                                value
                                            );
                                        }}
                                        value={elem?.is_open?.value?.title}
                                        errorText={
                                            elem?.is_open?.hasError &&
                                            elem?.is_open?.error
                                        }
                                    />
                                </div>
                                <div
                                    className={`step-wrapper__delete-looses ${
                                        listOfLooses.length > 1 ? 'active' : ''
                                    }`}
                                    onClick={() => {
                                        if (listOfLooses.length > 1) {
                                            dispatch(setIsChanged(true));

                                            deleteListOfLooses(elem.localId);
                                        }
                                    }}
                                >
                                    ✖
                                </div>
                            </div>
                        </>
                    );
                })}

                <div className="step-wrapper__center">
                    <div
                        className="step-wrapper__plus"
                        onClick={() => {
                            const amount = listOfLooses.length;
                            const item = {
                                description: {
                                    value: '',
                                    error: '',
                                    hasError: false,
                                },
                                date: { value: '', error: '', hasError: false },
                                amount_paid: {
                                    value: '',
                                    error: '',
                                    hasError: false,
                                },
                                is_open: {
                                    value: '',
                                    error: '',
                                    hasError: false,
                                },
                                localId: Math.random() + '',
                            };
                            const res = [...listOfLooses];
                            res.push(item);
                            setListOfLooses(res);
                        }}
                    >
                        +
                    </div>
                </div>
            </div>
        </>
    );
};
