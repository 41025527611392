import React, { useState } from 'react';
import { fillFormAction } from '../../helpers/formUtils';
import { CheckboxContainer } from '../CustomFields/CheckboxContainer';
import error from '../../assets/images/error-image.png';
import './steps.scss';
import { setIsChanged } from '../../redux/quote/quote';
import { useDispatch, useSelector } from 'react-redux';

export const FirstStep = ({ setFormData, formData, errorOnFirstStep }) => {
    const dispatch = useDispatch();
    const [showText, setShowText] = useState(true);
    const quotePackage = useSelector((state) => state.quote.quotePackage);

    return (
        <div className="step-wrapper">
            <div className="step-wrapper__column">
                <div className="step-wrapper__plus-wrap">
                    <CheckboxContainer
                        title={
                            <div>
                                1. I agree that the risk meets all of the below
                                eligibility criteria{' '}
                                <span className="mandatory">*</span>
                            </div>
                        }
                        checked={formData.agree_risk}
                        onChange={() => {
                            dispatch(setIsChanged(true));
                            setFormData(
                                fillFormAction({
                                    ...formData,
                                    agree_risk: !formData.agree_risk,
                                })
                            );
                        }}
                    />
                    <div
                        className="step-wrapper__plus"
                        onClick={() => setShowText((prev) => !prev)}
                    >
                        {showText ? '×' : '+'}
                    </div>
                </div>

                {showText && quotePackage.title === 'Residential Rental' && (
                    <div className="step-wrapper__plus-text">
                        <p>
                            In order to insure a risk with the Residential
                            Rental Package, the risk must: <br />• Have no more
                            than 2 mortgages from traditional lenders <br />• Be
                            occupied as residential rental units only, <br />•
                            dwellings must have a maximum 4 units, and <br />•
                            the building must comply with current building codes
                            and bylaws <br />• be in a building that has been
                            built within the last 30 years or that has new
                            (within 30 years) wiring, plumbing, heating. Roof
                            must be within 20 years (maximum Building age 100
                            years), and <br />• have annual leases in place, and{' '}
                            <br />• have a landlord within 100 kms of the risk,
                            who checks the property monthly, and <br />• be
                            fully occupied, and <br />• be hydrant protected,
                            and <br />• electrical service must be at least 100
                            amps
                        </p>

                        <p>
                            Not Written <br />
                            The following ‘residential rental properties’ will
                            not be insured: <br />• a condominium corporation{' '}
                            <br />• Knob & Tube and/or aluminum wiring <br />•
                            Galvanized and/or cast plumbing <br />• Risks with
                            fuses unless fuse plug adaptors have been installed{' '}
                            <br />• Oil heating, or any risk with a Wood Stove{' '}
                            <br />• Units that include any commercial operations
                            (eg retail or office) <br />• Risks with claims in
                            the past 3 years
                        </p>
                        <p>
                            Not Qualified for the 'Residential Rental Property
                            Package’
                            <br /> The following ‘residential rental properties’
                            will not be insured on a ‘residential rental
                            property package’: <br />• a rooming, boarding or
                            similar type building where individual bed/living
                            rooms are rented out by the day, week or month;{' '}
                            <br />• A residential rental building with more than
                            4 units <br />• A residential rental building with
                            any commercial operations including retail or office
                            tenants
                        </p>
                    </div>
                )}
                {showText && quotePackage.title === 'Student Rental' && (
                    <div className="step-wrapper__plus-text">
                        <p>
                            In order for a risk to qualify for the A+ Student
                            Rental Package, the following must be met: <br />•
                            Have no more than 2 mortgages from traditional
                            lenders <br />• Only occupied as a student rental,
                            and <br />• Maximum 10 students <br />• Each
                            residential unit must have a kitchen and washroom;
                            and <br />• Be a legal student rental that complies
                            with current building codes and bylaws; and
                            <br />• be in a building that has been built within
                            the last 30 years or that has new (within 30 years)
                            wiring, plumbing, heating. Roof must be within 20
                            years (maximum Building age 100 years), and <br />•
                            Be fully occupied (summer vacancy is acceptable){' '}
                            <br />• Be hydrant protected <br />• Electrical
                            service must be at least 100 amps <br />• Have a
                            landlord within 100 km of the risk who checks the
                            property monthly <br />• Have annual leases in place
                        </p>

                        <p>
                            Not Written <br />
                            The following ‘residential rental properties’ will
                            not be insured: <br />• Frat or sorority houses{' '}
                            <br />• Large apartment buildings <br />• A
                            condominium corporation <br />• Knob & Tube and/or
                            aluminum wiring <br />• Galvanized and/or cast
                            plumbing <br />• Risks with fuses unless fuse plug
                            adaptors have been installed <br />• Oil heating, or
                            any risk with a Wood Stove <br />• Risks with claims
                            in the past 3 years
                        </p>
                        <p>
                            Not Qualified for the 'A+ Student Rental Package’
                            <br />
                            The following ‘residential rental properties’ will
                            not be insured on a ‘residential rental property
                            package’: <br />• a rooming, boarding or similar
                            type building where individual bed/living rooms are
                            rented out by the day, week or month; <br />• Large
                            apartment Buildings or condo buildings (only written
                            on a per unit basis under the A+ Student Rental
                            Package <br />• Buildings with any commercial
                            operations (eg retail/office)
                        </p>
                    </div>
                )}
                {errorOnFirstStep && (
                    <div className="step-wrapper__error-text error-text">
                        <img className="error-text__img" src={error} />
                        <div className="error-text__text">
                            Please indicate that the risk meets all of the above
                            eligibility criteria
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};
