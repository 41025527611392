import React, { useEffect, useState } from 'react';
import Logo from '../../assets/images/Logo.png';
import CollapsedLogo from '../../assets/images/login-image.png'
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import HomeSectionImage from '../../assets/images/homeSection.png';
import HomeSectionImageActive from '../../assets/images/homeSectionActive.png';
import quotesSectionImage from '../../assets/images/quotesSectionImage.svg';
import quotesSectionImageActive from '../../assets/images/quotesSectionImageActive.png';
import { Popup } from '../Popup/Popup';
import './layout.scss';
import { useDispatch, useSelector } from 'react-redux';
import { AreYouSureModal } from '../AreYouSureModal/AreYouSureModal';
import { setIsChanged } from '../../redux/quote/quote';

const navigationArray = [
    {
        id: 'homepage',
        title: 'Homepage',
        src: '/homepage',
        image: HomeSectionImage,
        imageActive: HomeSectionImageActive,
    },
    {
        id: 'quotes_in_work',
        title: 'Quotes in Progress',
        src: '/quotes_in_work',
        image: quotesSectionImage,
        imageActive: quotesSectionImageActive,
    },
    {
        id: 'submitted_quo',
        title: 'Submitted Quotes',
        src: '/submitted_quo',
        image: quotesSectionImage,
        imageActive: quotesSectionImageActive,
    },
];

export const Layout = ({ children, activeTab }) => {
    const navigate = useNavigate();
    const isPopup = useSelector((state) => state.popup.isPopup);
    const isPopupError = useSelector((state) => state.popup.isPopupError);
    const isPopupErr = useSelector((state) => state.popup.isPopupErr);
    const isPopupSend = useSelector((state) => state.popup.isPopupSend);
    const isPopupSubmit = useSelector((state) => state.popup.isPopupSubmit);
    const isPopupTotal = useSelector((state) => state.popup.isPopupTotal);
    const isPopupCanceld = useSelector((state) => state.popup.isPopupCanceld);
    const isPopupCompleteQuote = useSelector(
        (state) => state.popup.completeQuote
    );
    const [openModal, setOpenModal] = useState(false);
    const isChanged = useSelector((state) => state.quote.isChanged);
    const [src, setSrc] = useState('');
    const dispatch = useDispatch();
    const location = useLocation();

    // State to manage collapsed sidebar
    const [isCollapsed, setIsCollapsed] = useState(false);

    // useEffect for automatic sidebar toggle based on window size
    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth < 768) {
                setIsCollapsed(true);
            } else {
                setIsCollapsed(false);
            }
        }

        // Initial check
        handleResize();

        // Attach the event listener
        window.addEventListener('resize', handleResize);

        // Clean up the event listener on component unmount
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    // zendesk chat widget
    useEffect(() => {
        const script = document.createElement('script');
        script.id = 'ze-snippet';
        script.src = 'https://static.zdassets.com/ekr/snippet.js?key=4da1b86d-a253-4a2c-9dae-4980cbded378';
        document.head.appendChild(script);
        
        // Optional: Clean up the script element on component unmount
        return () => {
            document.head.removeChild(script);
        };
    }, []);  // Empty dependency array ensures this runs once on mount and cleanup runs on unmount
    
    // Toggle function for the sidebar
    const toggleSidebar = () => {
        setIsCollapsed(!isCollapsed);
    };
    
    return (
        <>
            <div className={`container ${isCollapsed ? 'collapsed' : ''}`}>
                {openModal && (
                    <AreYouSureModal
                        cancel={() => {
                            setOpenModal(false);
                        }}
                        submit={() => {
                            navigate(src);
                            setOpenModal(false);
                            dispatch(setIsChanged(false));
                        }}
                        title="You have unsaved changes. Are you sure you want to leave the page?"
                    />
                )}
                <Popup status="send" className={isPopupSend ? 'active' : ''} />
                <Popup
                    status="submit"
                    className={isPopupSubmit ? 'active' : ''}
                />
                <Popup
                    status="canceled"
                    className={isPopupCanceld ? 'active' : ''}
                />
                <Popup
                    status="complete"
                    className={isPopupCompleteQuote ? 'active' : ''}
                />
                <Popup
                    status="totalError"
                    className={isPopupTotal ? 'active' : ''}
                />
                <Popup status="success" className={isPopup ? 'active' : ''} />
                <Popup
                    status="error"
                    className={isPopupError ? 'active' : ''}
                />
                <Popup status="err" className={isPopupErr ? 'active' : ''} />

                <div className={`nav ${isCollapsed ? 'collapsed' : ''}`}>
                    
                    <div className="nav__top">
                    <div
                        onClick={() => {
                            // Setting the path to navigate to the homepage
                            setSrc('/homepage');
                            // Checking if there are any unsaved changes
                            if (
                                isChanged &&
                                location.pathname.includes('new_quote')
                            ) {
                                // If there are unsaved changes, open the modal
                                setOpenModal(true);
                            } else {
                                // Otherwise, navigate to the homepage
                                navigate('/homepage');
                            }
                        }}
                    >
                        <img src={isCollapsed ? CollapsedLogo : Logo} className="nav__logo" alt="Logo" />
                    </div>
                </div>
                    {navigationArray.map((elem) => {
                        return (
                            <div
                                className="nav__section"
                                // to={elem.src}
                                key={elem.id}
                                title={elem.title}
                            >
                                <div className="nav__navigation">
                                    <div
                                        className={`nav__section-wrap ${
                                            activeTab === elem.id
                                                ? 'active'
                                                : ''
                                        }`}
                                        onClick={() => {
                                            setSrc(elem.src);
                                            if (
                                                isChanged &&
                                                location.pathname.includes(
                                                    'new_quote'
                                                )
                                            ) {
                                                setOpenModal(true);
                                            } else {
                                                navigate(elem.src);
                                            }
                                        }}
                                    >
                                        {activeTab === elem.id && (
                                            <div className="nav__addition"></div>
                                        )}
                                        <img
                                            src={
                                                activeTab === elem.id
                                                    ? elem.imageActive
                                                    : elem.image
                                            }
                                            className="nav__image"
                                        />
                                        {elem.title}
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                    <div className="nav__toggle" onClick={toggleSidebar}>
                        {isCollapsed ? '►' : '◄'}  {/* Sample arrows as icons */}
                    </div>
                </div>
                {children}
                <div className="greyBackground">
                    <div className="greyBackground__head"></div>
                </div>
            </div>
        </>
    );
};
