import React, { useRef, useState } from 'react';
import arrow from '../../assets/images/arrow.png';
import { useClickOutside } from '../../helpers/useClickOutside';
import error from '../../assets/images/error-image.png';
import './custom-fields.scss';

export const CustomSelection = ({
    placeholder,
    arrowImage,
    title,
    height,
    options,
    onChange,
    value,
    smallTitle,
    errorText,
    disabled,
    className,
}) => {
    const [openSelection, setOpenSelection] = useState(false);
    const [highlightedIndex, setHighlightedIndex] = useState(-1);  // Add this line
    const ref = useRef();
    const [onFocus, setOnFocus] = useState(false);

    useClickOutside(ref, () => setOpenSelection(false));

    const handleKeyDown = (event) => {
        switch(event.key) {
            case 'Enter':
                if (highlightedIndex >= 0 && highlightedIndex < options.length) {
                    // Update the value when Enter is pressed
                    onChange(options[highlightedIndex]);
                }
                setOpenSelection(prev => !prev);
                break;
            case 'ArrowDown':
                // Increment highlightedIndex, but don't exceed the number of options
                setHighlightedIndex(prev => Math.min(prev + 1, options.length - 1));
                break;
            case 'ArrowUp':
                // Decrement highlightedIndex, but don't go below 0
                setHighlightedIndex(prev => Math.max(prev - 1, 0));
                break;
            default:
                break;
        }
    };

    return (
        <>
            {title && (
                <div
                    className={`custom-field-title ${
                        disabled ? 'disabled' : ''
                    } `}
                >
                    {title}
                </div>
            )}
            {smallTitle && (
                <div className={`custom-field-smalltitle`}>{smallTitle}</div>
            )}

            <div className={`custom-field ${className}`} ref={ref}>
                <div
                    onClick={() => {
                        if (disabled) {
                        } else {
                            setOpenSelection((prev) => !prev);
                        }
                    }}
                >
                    <div
                        disabled={true}
                        className={`custom-field__selection custom-field__input ${
                            errorText ? 'red' : ''
                        } ${smallTitle ? 'small' : ''}`}
                        style={{
                            background: onFocus ? '#EEF5FF' : 'white',
                            height: height,
                            color: disabled ? '#8e949a' : '',
                            cursor: disabled ? 'context-menu' : 'pointer',
                        }}
                        placeholder={onFocus ? '' : placeholder}
                        onKeyDown={handleKeyDown}
                        tabIndex={0}
                        // onBlur={() => {
                        //     setOnFocus(false);
                        // }}
                        // onFocus={() => setOnFocus(true)}
                        value={value}
                    >
                        {value ? (
                            <div className="value">{value}</div>
                        ) : (
                            <div className="placeholder">{placeholder}</div>
                        )}
                    </div>
                </div>
                {arrowImage && (
                    <div
                        className="custom-field__arrow"
                        style={smallTitle && { top: '1px' }}
                        onClick={() => {
                            if (disabled) {
                            } else {
                                setOpenSelection((prev) => !prev);
                            }
                        }}
                    >
                        ›
                    </div>
                )}
                {errorText && (
                    <div className="errortext">
                        <img className="errortext__img" src={error} />
                        <div className="errortext__text">{errorText}</div>
                    </div>
                )}

             {openSelection && (
                    <div className="custom-field__list">
                        {options.map((elem, index) => {
                            return (
                                <div
                                    key={elem.title}
                                    className={`custom-field__item ${
                                        index === highlightedIndex ? 'highlighted' : ''  // Highlight the item if its index matches highlightedIndex
                                    }`}
                                    onClick={() => {
                                        onChange(elem);
                                        setOpenSelection(false);
                                    }}
                                >
                                    {elem.title}
                                </div>
                            );
                        })}
                    </div>
                )}
            </div>
        </>
    );
};
